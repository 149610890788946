import apiBff from './apiBff'

export default {
  getRecompositionSubjects: (gradeId) => apiBff.get(`recomposition/grade/${gradeId}/subjects`),
  getRecompositionChapters: ({
    subjectId,
    gradeId,
    classroomId,
    contentYear,
  }) => apiBff.get(
    `recomposition/subject/${subjectId}/grade/${gradeId}/classroom/${classroomId}/heading-topics`,
    { params: { contentYear } }
  ),
  getStudentsRecompositionChapterData: (recompositionChapterId, contentYear) => apiBff
    .get(`recomposition/recomposition-chapter/${recompositionChapterId}`, { params: { contentYear } }),
  getProgressionPathDiagnosticData: ({
    recompositionChapterId,
    classroomId,
    contentYear,
  }) => apiBff.get(
    `recomposition/recomposition-chapter/${recompositionChapterId}/classroom/${classroomId}/progress`,
    { params: { contentYear } }
  ),
  getEssentialLearningList: ({ recompositionChapterId, classroomId, contentYear }) => apiBff.get(`recomposition/recomposition-chapter/${recompositionChapterId}/classroom/${classroomId}/essential-learning`, { params: { contentYear } }),
  getStudentEssentialLearningList: ({ recompositionChapterId, studentId }) => apiBff.get(`recomposition/recomposition-chapter/${recompositionChapterId}/student/${studentId}`),
  getStudentsPerformanceData: ({
    recompositionChapterId,
    classroomId,
    contentYear,
  }) => apiBff.get(
    `recomposition/recomposition-chapter/${recompositionChapterId}/classroom/${classroomId}/statistics`,
    { params: { contentYear } }
  ),
  getKnowledgeTestQuestions: ({
    classroomId,
    questionnaireCode,
    contentYear,
  }) => apiBff.get(
    `recomposition/classroom/${classroomId}
    /questionnaire/${questionnaireCode}/question-details`,
    { params: { contentYear } }
  ),
  getRecoveryOptions: ({
    recompositionChapterId,
    types,
    classroomId,
  }) => apiBff.get(
    `recomposition/classroom/${classroomId}/recomposition-chapter/${recompositionChapterId}/questionnaires`,
    { params: { types } }
  ),
  getStudentRecoveryOptions: ({
    recompositionChapterId,
    types,
    studentId,
  }) => apiBff.get(`recomposition/student/${studentId}/recomposition-chapter/${recompositionChapterId}/questionnaires`, { params: { types } }),
  getRecoveryQuestions: ({
    recompositionChapterId,
    classroomId,
    questionnaireCode,
    contentYear,
  }) => apiBff.get(
    `recomposition/recomposition-chapter/${recompositionChapterId}/classroom/${classroomId}
  /question-details?questionnaireCode=${questionnaireCode}&contentYear=${contentYear}`
  ),
  getStudentQuestions: ({ studentId, questionnaireCode }) => apiBff.get(
    `recomposition/student/${studentId}/questionnaire/${questionnaireCode}/questions-details`
  ),
}
