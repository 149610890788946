import visualizeBy from '../enums/visualizeBy'

const largestRetryCount = (arr) => arr.reduce((accumulatedValue, currentValue) => Math
  .max(accumulatedValue, currentValue), 0)

export default function assessmentFilter(assessments, filter) {
  switch (filter) {
    case visualizeBy.SESSION_MORE_RECENT: {
      const largestRetryCountValue = largestRetryCount(
        assessments.map((assessment) => assessment?.retryCount ?? 0)
      )

      return assessments.find((assessment) => assessment?.retryCount === largestRetryCountValue)
    }

    case visualizeBy.GREATER_SCORE:
      return assessments.find((assessment) => assessment?.maxScore ?? 0)

    case visualizeBy.FIRST_SESSION:
      return assessments.find((assessment) => assessment?.retryCount === 1)

    default:
      return assessments
  }
}
