<template>
  <div class="my-activities">
    <wrapper>
      <div class="my-activities__page-top">
        <h3>{{ pageTitle }}</h3>
        <s-button
          variant="primary"
          size="large"
          class="my-activities__button"
          :icon-left="mq_m ? null : 'plus'"
          :icon="mq_m ? 'plus' : null"
          :disabled="!selectedClassroom"
          @click="isRedirecting = true"
        >
          {{ !mq_m && $t('myActivities.buttonLabel') }}
        </s-button>
      </div>
      <div class="my-activities__lecture-picker">
        <LecturePicker
          v-model="selectedLecture"
          :lectures="lectures"
          :loading="!selectedClassroom"
          dropdown-direction="right"
        />
      </div>
      <template v-if="teacherAssignments.ready">
        <AssignmentsList
          v-if="teacherAssignments.data.length"
          :assignments="teacherAssignments.data"
        >
          <template #default="{ assignment }">
            <TeacherAssignmentsActions
              :assignment="assignment"
              :enable-delete="isAssignmentAuthor(assignment)"
              :complaint-badge="assignment.numberOfComplaints > 0"
              @action="handleAssignmentAction"
              @delete="askForDeletionConfirmation(assignment)"
              @select="selectedForSharing = assignment"
            />
          </template>
        </AssignmentsList>
        <EmptyState
          v-else-if="selectedLecture"
          :image="emptyState.image"
          :title="emptyState.title"
          :description="emptyState.description"
        />
      </template>
      <AssignmentsListSkeleton v-else />
    </wrapper>
    <RedirectToCQ
      v-if="isRedirecting"
      :classroom="selectedClassroom"
      :lecture-id="selectedLecture.id"
      :title="$t('myActivities.redirect.title')"
      :description="$t('myActivities.redirect.description')"
      :image-source="require('App/assets/redirect-cq.svg')"
    />
    <DeleteConfirmationModal
      v-if="showDeletionConfirmation"
      :classroom="selectedClassroom.name"
      :activity="selectedForDeletion"
      @cancel="clearSelectedForDeletion"
      @deleted="deletionComplete"
    />
    <ShareModal
      v-if="selectedForSharing"
      :selected-classroom="selectedClassroom"
      :questionnaire-code="selectedForSharing.questionnaireCode"
      @close="selectedForSharing = null"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import mediaQueries from 'App/mixins/mediaQueries'
import updateRoute from 'App/mixins/updateRoute'
import EmptyState from 'App/components/EmptyState'
import RedirectToCQ from 'App/components/RedirectToCQ'
import AssignmentsList from 'App/components/Assignments/AssignmentsList'
import AssignmentsListSkeleton from 'App/components/Assignments/AssignmentsListSkeleton'
import LecturePicker from 'App/components/LecturePicker'
import ShareModal from 'App/components/ShareModal'
import TeacherAssignmentsActions from 'App/components/TeacherAssignmentsActions'
import DeleteConfirmationModal from './partials/DeleteConfirmationModal'

export default {
  name: 'SchoolActivities',
  components: {
    EmptyState,
    LecturePicker,
    RedirectToCQ,
    AssignmentsList,
    AssignmentsListSkeleton,
    DeleteConfirmationModal,
    ShareModal,
    TeacherAssignmentsActions,
  },
  mixins: [ updateRoute, mediaQueries ],
  props: {
    selectedClassroom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedLecture: null,
      isRedirecting: false,
      selectedForDeletion: null,
      showDeletionConfirmation: false,
      selectedForSharing: null,
    }
  },
  computed: {
    ...mapGetters([ 'user', 'yearSelectedContent', 'teacherAssignments' ]),
    lectureQueryParam() {
      return parseInt(this.$route.query.lectureId, 10)
    },
    emptyState() {
      const teacherEmptyState = {
        image: 'page-not-found',
        title: this.$t('myActivities.teacherEmpty.title'),
        description: this.$t('myActivities.teacherEmpty.description', {
          buttonLabel: this.$t('myActivities.buttonLabel'),
          lecture: this.selectedLecture?.name,
        }),
      }

      const managerEmptyState = {
        image: 'teacher-dash',
        title: this.$t('myActivities.managerEmpty.title'),
        description: this.$t('myActivities.managerEmpty.description', {
          lecture: this.selectedLecture?.name,
        }),
      }

      return this.user.isTeacher ? teacherEmptyState : managerEmptyState
    },
    lectures() {
      if (this.selectedClassroom) {
        return this.selectedClassroom.lectures
      }

      return []
    },
    pageTitle() {
      return this.user.isTeacher
        ? this.$t('myActivities.teacherTitle')
        : this.$t('myActivities.managerTitle')
    },
  },
  watch: {
    selectedClassroom: {
      handler() {
        if (this.selectedClassroom && this.lectureQueryParam) {
          this.selectedLecture = this.selectedClassroom.lectures.find(
            ({ id }) => id === this.lectureQueryParam
          ) || null
        }
      },
      immediate: true,
    },
    yearSelectedContent() {
      this.fetchMyAssignments()
    },
    selectedLecture() {
      if (this.selectedLecture?.id) {
        this.updateRouteQuery('lectureId', this.selectedLecture.id)
        this.fetchMyAssignments()
      }
    },
  },
  created() {
    this.fetchMyAssignments()
  },
  methods: {
    ...mapActions([ 'getTeacherAssignments' ]),
    fetchMyAssignments() {
      if (this.selectedClassroom && this.selectedLecture) {
        this.getTeacherAssignments({
          classroomId: this.selectedClassroom.id,
          lectureId: this.selectedLecture.id,
          contentYear: this.yearSelectedContent,
        })
      }
    },
    handleAssignmentAction(assignment) {
      const { status, questionnaireCode } = assignment
      if (status !== 'unreleased') {
        const classroomId = this.selectedClassroom.id
        const lectureId = this.selectedLecture.id ?? this.$route.query.lectureId

        if (!classroomId || !lectureId || !questionnaireCode) {
          this.$toasted.global.error({
            title: 'Não foi possível visualizar o relatório',
          })

          console.error('AssignmentsList missing params:', {
            classroomId,
            lectureId,
            questionnaireCode,
          })

          return
        }

        this.$router.push({
          name: 'teacher-report-general',
          params: {
            questionnaireCode,
            classroomId,
          },
          query: {
            lectureId: this.$route.query.lectureId,
          },
        })
      }
    },
    askForDeletionConfirmation(assignment) {
      this.selectedForDeletion = assignment
      this.showDeletionConfirmation = true
    },
    clearSelectedForDeletion() {
      this.selectedForDeletion = null
      this.showDeletionConfirmation = false
    },
    deletionComplete() {
      this.clearSelectedForDeletion()
      this.$toasted.global.success({
        icon: 'trash',
        title: this.$t('myActivities.deleteConfirmation.success'),
      })
      this.fetchMyAssignments()
    },
    isAssignmentAuthor(assignment) {
      return assignment.author?.id === this.user.id
    },
  },
}
</script>
<style lang="scss" scoped>
.my-activities {
  &__page-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $size-s;
  }

  &__lecture-picker {
    margin-bottom: $size-l;
  }

  &__report-button {
    margin-right: $size-xs;
  }
}
</style>
