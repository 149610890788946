import accountApi from '@/service/account'
import { setCookie } from 'App/utils/cookies'
import cookies from '@/service/cookies'
import {
  PROFILES,
  STAGES,
  containsUserProfile,
  filterExistingGrades
} from '@/service/profiles'
import { GRADES } from 'App/utils/grades'
import * as types from './types'

export default {
  getUser: async (
    { commit, dispatch },
    token = cookies.getItem('token') || null
  ) => {
    commit(types.SET_USER_LOADING, true)
    if (token) {
      try {
        const userResponse = await accountApi.getUser()
        const user = userResponse.data
        const signInResponse = await accountApi.getSignedIn()
        const signIn = signInResponse.data

        const isMiddleSchoolStudent = (
          containsUserProfile(user.profiles, PROFILES.STUDENT)
          && signIn === STAGES.MIDDLE_SCHOOL
        )
        const isHighSchoolStudent = (
          containsUserProfile(user.profiles, PROFILES.STUDENT)
            && signIn === STAGES.HIGH_SCHOOL
        )

        const mappedUserGradesIds = user.grades?.map((grade) => grade.id)

        const checkTeacherTier = filterExistingGrades(mappedUserGradesIds, GRADES)

        const isMiddleSchoolTeacher = (
          checkTeacherTier.some(({ type }) => type === STAGES.MIDDLE_SCHOOL)
        )

        const isHighSchoolTeacher = (
          checkTeacherTier.some(({ type }) => type === STAGES.HIGH_SCHOOL)
        )

        const isTeacher = containsUserProfile(user.profiles, PROFILES.TEACHER)
        const isSchool = containsUserProfile(user.profiles, PROFILES.SCHOOL)
        const isManager = containsUserProfile(user.profiles, PROFILES.MANAGER)

        dispatch('getReallocationDateLimit')

        commit(types.SET_USER, {
          user: {
            ...user,
            isMiddleSchoolStudent,
            isHighSchoolStudent,
            isTeacher,
            isSchool,
            isManager,
            isMiddleSchoolTeacher,
            isHighSchoolTeacher,
          },
        })
        commit(types.SET_USER_LOADING, false)

        await dispatch('getFeatureToggles')

        return user
      } catch (error) {
        console.error(error)
      }
    }
    commit(types.SET_USER_LOADING, false)

    return false
  },
  logout: ({ commit }) => {
    cookies.removeItem('token')
    commit(types.CLEAR_ALL)
  },
  getReallocationDateLimit: async ({ commit }) => {
    commit(types.REALLOCATION_DATE_LIMIT, { loading: true })
    try {
      const response = await accountApi.getReallocationDateLimit()

      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        data: response.data,
      })
    } catch (error) {
      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        error,
      })
    }
  },
  setYearSelectedContent: ({ commit }, year) => {
    const transformedYearValue = parseInt(year, 10)
    setCookie('app_year_assignment_report', transformedYearValue)
    commit(types.SET_YEAR_SELECTED_CONTENT, transformedYearValue)
  },
  getPersonalData: async ({ commit }) => {
    commit(types.SET_PERSONAL_DATA, {})
    commit(types.SET_PERSONAL_DATA_LOADING, true)

    try {
      const { data } = await accountApi.getPersonalData()

      commit(types.SET_PERSONAL_DATA, data)
    } catch (error) {
      console.error(error)
    }

    commit(types.SET_PERSONAL_DATA_LOADING, false)
  },
}
