<template>
  <div class="share-form">
    <div class="share-form__header">
      <h4 class="share-form__title">
        {{ $t('share.title') }}
      </h4>
      <s-button
        icon="x"
        variation="tertiary"
        @click="$emit('close')"
      />
    </div>
    <div class="share-form__body">
      <p class="share-form__description">
        {{ $t('share.description') }}
      </p>
      <ShareUrl
        :loading="loading"
        :url="url"
      />
    </div>
  </div>
</template>

<script>
import ShareUrl from 'App/components/ShareUrl'

export default {
  name: 'ShareForm',
  components: {
    ShareUrl,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.share-form
  &__header
    +flex-space-between

  &__body
    padding-top: $size-xs

  &__title
    font-size: $font-size-heading-5

  &__description
    margin-bottom: $size-s
    color: $color-ink-light
</style>
