<template>
  <STable
    :fields="[ ...Array(3) ]"
    :loading="true"
    :skeleton-lines="10"
  >
    <template slot="0">
      <SkeletonLoader
        width="250px"
        height="25px"
      />
    </template>
    <template slot="1">
      <SkeletonLoader
        width="30px"
        height="20px"
      />
    </template>
  </STable>
</template>

<script>
import { SkeletonLoader } from '@sas-te/alfabeto-vue'

import STable from 'App/components/STable'

export default {
  name: 'SyllabusStudentsTableSkeleton',
  components: {
    SkeletonLoader,
    STable,
  },
}
</script>
