import http from '@/service/http'
import * as types from './types'

export default {
  getLectures: ({ commit }) => {
    commit(types.SET_LECTURES, { loading: true })

    return http.get('lectures')
      .then((response) => {
        commit(types.SET_LECTURES, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_LECTURES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getLecture: async ({ commit }, lectureId) => {
    commit(types.SET_LECTURE, { loading: false })

    try {
      const response = await http.get(`lectures/${lectureId}`)
      commit(types.SET_LECTURE, { data: response.data })
    } catch (error) {
      commit(types.SET_LECTURE, { error: error.response || true })
    } finally {
      commit(types.SET_LECTURE, { loading: false })
    }
  },
  setLecture({ commit }, lecture) {
    commit(types.SET_LECTURE, { data: lecture })
  },
}
