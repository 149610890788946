import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  extraActivities: {
    data: null,
    loading: false,
    error: null,
  },
  extraActivity: {
    data: null,
    loading: false,
    error: null,
  },
  extraActivityProperties: {
    data: [],
    loading: false,
    error: null,
  },
  extraActivityAssignments: {
    data: [],
    loading: false,
    error: null,
  },
  extraActivityQuestionnaires: {
    loading: false,
    data: null,
    error: null,
  },
  extraActivitiesDifficulties: {
    loading: false,
    data: [],
    error: null,
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
