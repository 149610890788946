<template>
  <div class="assignments-container">
    <div>
      <h3
        v-if="title"
        class="assignments-container__title"
      >
        {{ title }}
      </h3>
      <div class="assignments-container__list-item">
        <AssignmentsListItem
          v-for="assignment in assignmentsList"
          :key="assignment.id"
          :assignment="assignment"
        >
          <slot :assignment="assignment" />
        </AssignmentsListItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AssignmentsListItem from './AssignmentsListItem'

export default {
  name: 'AssignmentsList',
  components: {
    AssignmentsListItem,
  },
  props: {
    assignments: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters([ 'user' ]),
    assignmentsList() {
      return this.assignments.map(this.appendAuthorToSubtitle)
    },
  },
  methods: {
    appendAuthorToSubtitle(assignment) {
      let appendixAuthorName = ''

      if (assignment.author?.id === this.user.id) {
        appendixAuthorName = ` - ${this.$t('assignments.createdByMyself')}`
      } else if (assignment.author) {
        appendixAuthorName = ` - ${this.$t(
          'assignments.createdBy',
          { name: assignment.author.user.name }
        )}`
      }

      return {
        ...assignment,
        subtitle: `${assignment.subtitle}${appendixAuthorName}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.assignments-container {
  &__title {
    font-size: $font-size-heading-5-small;
    margin-bottom: $size-s;
    color: $color-ink-light;

    @include mq-m--mf {
      font-size: $font-size-heading-5;
    }
  }

  &__list-item {
    span {
      color: $color-ink-lighter;
      font-size: $font-size-xs;
    }

    h4 {
      font-size: $font-size-heading-6;
      margin-top: $size-xxs;
    }
  }
}
</style>
