import Vue from 'vue'
import Vuex from 'vuex'

import error from 'App/store/error'
import account from 'App/store/account'
import subjects from 'App/store/subjects'
import lectures from 'App/store/lectures'
import extraActivities from 'App/store/extraActivities'
import featureToggles from 'App/store/featureToggles'
import questionnaires from 'App/store/questionnaires'
import classes from 'App/store/classes'
import teacherAssignments from 'App/store/assignments'
import complaints from 'App/store/complaints'
import typeform from './typeform'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    error,
    account,
    subjects,
    lectures,
    extraActivities,
    featureToggles,
    teacherAssignments,
    questionnaires,
    classes,
    complaints,
    typeform,
  },
  strict: process.env.VUE_APP_MODE !== 'production',
})
