<template>
  <modal
    class="reinforcement-modal"
    @close="$emit('close')"
  >
    <h4
      class="reinforcement-modal__title"
    >
      Gerar missão de reforço
    </h4>
    <p
      class="reinforcement-modal__description"
    >
      Você criará uma missão vinculada apenas à turma <strong>{{ classroomName }}</strong>.
      Somente a ordenação e o tempo de cada questão serão editáveis.
    </p>
    <label
      class="reinforcement-modal__label"
      for="mission-title"
    >
      Título da missão*
    </label>
    <FormError
      :has-error="error.status"
      :error-message="error.message"
    >
      <SInput
        id="mission-title"
        v-model="missionTitle"
        class="reinforcement-modal__input"
        size="large"
      />
    </FormError>
    <template #footer>
      <s-button
        size="large"
        variation="tertiary"
        @click="$emit('cancel')"
      >
        Cancelar
      </s-button>
      <s-button
        size="large"
        :disabled="error.status"
        @click="generateMission"
      >
        Gerar missão
      </s-button>
    </template>
  </modal>
</template>

<script>
import { SInput } from '@sas-te/alfabeto-vue'

import FormError from 'App/components/FormError'

export default {
  name: 'ReinforcementModal',
  components: {
    SInput,
    FormError,
  },
  props: {
    reinforcementMissionTitle: {
      type: String,
      default: '',
    },
    hasError: Boolean,
    classroomName: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      missionTitle: this.reinforcementMissionTitle,
      missionTitleSaved: '',
    }
  },
  computed: {
    duplicatedTitleError() {
      return {
        status: this.hasError && this.missionTitleSaved === this.missionTitle,
        message: 'Já existe uma missão magna com esse nome',
      }
    },
    emptyTitleError() {
      return {
        status: this.missionTitle.trim()?.length === 0,
        message: 'Insira um nome para gerar missão',
      }
    },
    error() {
      if (this.emptyTitleError.status) {
        return this.emptyTitleError
      }

      return this.duplicatedTitleError
    },
  },
  methods: {
    generateMission() {
      this.$trackEvent({
        category: this.$track.category.sasActivities,
        action: this.$track.action.teacherGenerateReinforcement,
        label: this.$track.label.generateMission,
      })
      this.missionTitleSaved = this.missionTitle
      this.$emit('confirm', this.missionTitle)
    },
  },
}
</script>

<style lang="scss" scoped>
.reinforcement-modal {
  &__title {
    margin-bottom: $size-l;
    font-weight: $font-weight-medium;
    font-size: $font-size-heading-5;
    line-height: $line-height-heading;
    color: $color-ink;
  }

  &__description {
    margin-bottom: $size-m;
    font-weight: $font-weight-regular;
    line-height: $line-height-text;
    color: $color-ink;
  }

  &__label {
    display: block;
    padding: $size-xs 0;
    font-weight: $font-weight-regular;
    font-size: $font-size-s;
    line-height: $line-height-heading;
    color: $color-ink-light;
  }

  &__input {
    width: 100%;
  }
}
</style>
