<template>
  <drawer
    class="student-drawer"
    :overlay-enabled="false"
    :size="activeQuestion ? '100%' : $tokens.device_l"
    @close="$emit('close')"
  >
    <DrawerHeader
      hide-more-options
      :title="student.name"
      :subtitle="student.classroom"
      :has-previous="hasPrevious"
      :has-next="hasNext"
      :loading="answeredQuestions.loading"
      @previous="$emit('previous')"
      @next="$emit('next')"
      @close="$emit('close')"
    />
    <template v-if="hasReport">
      <AssignmentExtraDetails
        :title="assignmentInfo.name"
        :subtitle="assignmentInfo.subject"
        :finished-time="assignmentInfo.finishedAt"
      />
      <div class="student-drawer__body">
        <HitRateCounter
          :hit-rate="hitRate"
          :correct="correctsCount"
          :total="questionsCount"
          :redo-count="student.redoCount"
          :loading="answeredQuestions.loading"
        />
        <div class="questions-counter">
          <template v-if="answeredQuestions.loading">
            <skeleton-loader
              width="100px"
              height="33px"
            />
            <skeleton-loader
              width="120px"
              height="22px"
            />
          </template>
          <template v-else>
            <h4 class="questions-counter__title">
              Questões
              <p class="questions-counter__count">
                {{ pluralizedQuestionsCount }}
              </p>
            </h4>
          </template>
        </div>
        <template v-if="mq_m">
          <template v-if="answeredQuestions.loading">
            <StudentDrawerQuestionsCardsSkeleton />
          </template>
          <template v-else-if="!answeredQuestions.error">
            <StudentDrawerQuestionsCards
              :questions="questions"
              @selectQuestion="selectQuestion"
            />
          </template>
        </template>
        <template v-else>
          <template v-if="answeredQuestions.loading">
            <StudentDrawerQuestionsTableSkeleton />
          </template>
          <template v-else-if="!answeredQuestions.error">
            <StudentDrawerQuestionsTable
              v-if="questionsCount"
              ref="questionsTable"
              :questions="questions"
              :active-question="activeQuestion"
              @selectQuestion="selectQuestion"
            />
          </template>
        </template>
        <QuestionDrawer
          v-if="activeQuestion"
          :question="activeQuestion"
          :assignment-code="student.assignmentCode"
          :overlay-enabled="false"
          :has-previous="hasPreviousQuestion"
          :has-next="hasNextQuestion"
          :hide-report="hideMoreOptions"
          @update-reports="getQuestions"
          @on-close="clearSelectedQuestion"
          @on-previous="previousQuestion"
          @on-next="nextQuestion"
        />
      </div>
    </template>
    <EmptyState
      v-else
      :title="emptyState.title"
      :description="emptyState.description"
      :image="emptyState.image"
    />
  </drawer>
</template>

<script>
import simplur from 'simplur'

import questions from '@/service/questions'
import mediaQueries from 'App/mixins/mediaQueries'
import keyEvents from 'App/mixins/keyEvents'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import EmptyState from 'App/components/EmptyState'
import StudentDrawerQuestionsCards from 'App/components/StudentDrawer/StudentDrawerQuestionsCards'
import StudentDrawerQuestionsCardsSkeleton from 'App/components/StudentDrawer/StudentDrawerQuestionsCardsSkeleton'
import StudentDrawerQuestionsTable from 'App/components/StudentDrawer/StudentDrawerQuestionsTable'
import StudentDrawerQuestionsTableSkeleton from 'App/components/StudentDrawer/StudentDrawerQuestionsTableSkeleton'
import AssignmentExtraDetails from 'App/components/DrawerPartials/AssignmentExtraDetails'
import DrawerHeader from 'App/components/DrawerPartials/DrawerHeader/DrawerHeader'
import HitRateCounter from '../DrawerPartials/HitRateCounter'

export default {
  name: 'ProposedQuestionsDrawer',
  components: {
    StudentDrawerQuestionsCards,
    StudentDrawerQuestionsCardsSkeleton,
    StudentDrawerQuestionsTable,
    StudentDrawerQuestionsTableSkeleton,
    QuestionDrawer,
    EmptyState,
    DrawerHeader,
    AssignmentExtraDetails,
    HitRateCounter,
  },
  mixins: [ mediaQueries, keyEvents ],
  props: {
    student: {
      type: Object,
      required: true,
    },
    classroomId: {
      type: Number,
      required: true,
    },
    assignmentInfo: {
      type: Object,
      default: () => ({}),
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    hideMoreOptions: Boolean,
    disableControls: Boolean,
  },
  data() {
    return {
      answeredQuestions: {
        data: [],
        loading: false,
        error: null,
      },
      activeQuestionInnerIndex: -1,
    }
  },
  computed: {
    hasReport() {
      return this.student.status === 'finished'
    },
    hasAnswers() {
      return !this.answeredQuestions.error
        && Array.isArray(this.answeredQuestions.data)
        && this.answeredQuestions.data.length > 0
    },
    questions() {
      if (!this.answeredQuestions.loading && this.hasAnswers) {
        return this.answeredQuestions.data.map((question, index) => ({
          ...question,
          number: index + 1,
          content: question.content.name,
        }))
      }

      return []
    },
    questionsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? this.answeredQuestions.data.length
        : 0
    },
    correctsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? this.answeredQuestions.data.filter(
          (question) => question.correct
        ).length
        : 0
    },
    hitRate() {
      return Math.round(this.student.hitRate)
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.questions.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    activeQuestionIndex() {
      if (this.activeQuestion) {
        return this.questions.findIndex((question) => (
          question.id === this.activeQuestion.id
        ))
      }

      return null
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionInnerIndex < this.questions.length - 1
    },
    emptyState() {
      const studentName = this.student.name.split(' ')[0]

      const studentNotStarted = {
        title: `${studentName} ainda não iniciou a atividade`,
        description: 'Incentive {aluno} a iniciar a atividade para ver o seu desempenho e questões respondidas.',
        image: 'empty-student-report',
      }

      const studentNotFinished = {
        title: `${studentName} ainda não finalizou a atividade`,
        description: 'Assim que a atividade estiver finalizada, você terá acesso ao seu desempenho e as questões respondidas.',
        image: 'empty-not-finished',
      }

      return this.student.status === 'start'
        ? studentNotStarted
        : studentNotFinished
    },
    pluralizedQuestionsCount() {
      const { questionsCount } = this

      return simplur`${questionsCount} [questão|questões]`
    },
  },
  watch: {
    student: 'getQuestions',
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    this.getQuestions()
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
    this.clearAnsweredQuestions()
  },
  methods: {
    async getQuestions() {
      const beforeRequestStudentId = this.student.id

      this.answeredQuestions.loading = true

      try {
        const answeredQuestions = await questions.fetchAnsweredQuestions(
          this.student.assignmentCode, this.classroomId
        )
        if (this.student.id === beforeRequestStudentId) {
          this.answeredQuestions.data = answeredQuestions
        }
      } catch (error) {
        this.answeredQuestions.error = error.response
      } finally {
        this.answeredQuestions.loading = false
      }
    },
    clearAnsweredQuestions() {
      this.answeredQuestions = {
        data: {},
        loading: false,
        error: null,
      }
    },
    selectQuestion(event) {
      const question = event.data
      const { activeQuestion } = this.$route.query

      if (question.id !== activeQuestion) {
        this.activeQuestionInnerIndex = event.index
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }

      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex - 1],
            index: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previousQuestion()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.filteredQuestions.length - 1) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex + 1],
            index: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.nextQuestion()
      }
    },
    pressEsc(event) {
      if (this.isEscKeyPressed(event) && !this.$route.query.activeQuestion) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.student-drawer {
  &__body {
    padding-bottom: $end-space;
  }
}

.questions-counter {
  padding: $size-xs $size-s $size-s;

  @include mq-s--mf {
    padding: $size-xs $size-l $size-s;
  }

  &__title {
    margin-bottom: $size-xxs;
  }

  &__count {
    font-size: 1rem;
    color: $color-ink-light;
  }
}
</style>
