<template>
  <div class="questions-cards-skeleton">
    <box
      v-for="index in 5"
      :key="index"
      class="questions-cards-skeleton__item"
      elevation="2"
      padding="0"
    >
      <skeleton-loader
        class="questions-cards-skeleton__item__number"
        :width="$tokens.size_m"
        :height="$tokens.size_m"
      />

      <div class="questions-cards-skeleton__item__question">
        <skeleton-loader
          class="questions-cards-skeleton__item__question__content"
          width="20%"
          height="19px"
        />
        <skeleton-loader
          class="questions-cards-skeleton__item__question__statement"
          widht="100%"
          height="25px"
        />
      </div>
      <div class="questions-cards-skeleton__item__hit-rate">
        <skeleton-loader
          class="questions-cards-skeleton__item__hit-rate__value"
          width="30px"
          height="19px"
        />
        <skeleton-loader
          class="questions-cards-skeleton__item__hit-rate__label"
          width="46px"
          height="19px"
        />
      </div>
    </box>
  </div>
</template>

<script>
export default {
  name: 'QuestionsCardsSkeleton',
}
</script>

<style lang="sass" scoped>
.questions-cards-skeleton
  &__item
    +space-stack($size-s)
    +flex-center-start

    &__number
      margin-left: $size-s

    &__question
      flex-grow: 1
      border-right: 1px solid transparentize($color-ink-lightest, 0.5)
      padding: $size-s
        left: 12px

      &__content
        margin-bottom: $size-xs

    &__hit-rate
      min-width: $size-xxl
      padding: $size-s

      &__value
        margin-bottom: $size-xs
</style>
