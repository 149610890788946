<template>
  <div class="general-view">
    <wrapper
      v-if="noStudentStarted"
      class="general-view__empty"
    >
      <EmptyState
        :title="emptyState.title"
        :description="emptyState.description"
        :image="emptyState.image"
      />
      <ShareUrl
        v-if="enableToShare"
        :loading="shareUrlCache.loading"
        :url="shareUrlCache.data[questionnaireCode] || ''"
      />
    </wrapper>
    <template v-else>
      <section class="general-view__section">
        <wrapper :full-width="mq_m">
          <div class="general-view__cards">
            <HitRateReport
              class="general-view__cards__item"
              :no-hit-rate-label="hitRateLabel"
            />
            <EngagementReport
              class="general-view__cards__item"
              @no-students-started="showEmptyState"
              @no-students-finished="setNoStudentsFinishedHitRateLabel"
            />
          </div>
        </wrapper>
      </section>
      <section class="general-view__section">
        <wrapper>
          <ClassDifficulties
            :questionnaire="questionnaire"
            :is-arena-mission="isArenaMission"
            :is-eureka-grade-selected-classroom="isEurekaGradeSelectedClassroom"
            @view-all="$router.push(routes.questions)"
          />
        </wrapper>
      </section>
      <section class="general-view__section">
        <wrapper>
          <StudentsFinished
            v-if="studentsFinished.length"
            :questionnaire="questionnaire"
            :students="studentsFinished"
            :loading="loading"
            :section-title="studentsFinishedSectionTitle"
            @viewAll="$router.push(routes.students)"
          />
        </wrapper>
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { studentAssignmentStatus } from 'App/utils/status'
import mediaQueries from 'App/mixins/mediaQueries'
import EmptyState from 'App/components/EmptyState'
import ShareUrl from 'App/components/ShareUrl'
import orderBy from 'lodash/orderBy'
import HitRateReport from './HitRateReport'
import EngagementReport from './EngagementReport'
import ClassDifficulties from '../ClassDifficulties/ClassDifficulties'
import StudentsFinished from './StudentsFinished'
import assessmentStatus from '../StudentsView/utils/assessmentStatus'
import assessmentFilter from '../StudentsView/utils/assessmentFilter'
import visualizeByEnum from '../StudentsView/enums/visualizeBy'

export default {
  name: 'GeneralView',
  components: {
    EmptyState,
    ShareUrl,
    HitRateReport,
    EngagementReport,
    ClassDifficulties,
    StudentsFinished,
  },
  mixins: [ mediaQueries ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    selectedClassroom: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    isChallengeActivity: Boolean,
    isTeacherChallenge: Boolean,
    isChallengeReinforcement: Boolean,
    studentsStatistics: {
      type: Object,
      default: () => ({}),
    },
    isEurekaGradeSelectedClassroom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noStudentStarted: false,
      enableToShare: true,
      hitRateLabel: '',
    }
  },
  computed: {
    ...mapGetters([ 'shareUrlCache' ]),
    isArenaMission() {
      return this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement
    },
    gradeId() {
      return this.selectedClassroom?.grade.id
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    studentsFinishedSectionTitle() {
      if (this.isChallengeActivity) {
        return 'Alunos que jogaram'
      }

      return 'Alunos que finalizaram a tarefa'
    },
    studentsFinished() {
      if (this.studentsStatistics.loading) {
        return []
      }

      return this.studentsStatistics.data?.map((statistic) => {
        const sortedAssignments = orderBy(statistic.assignments, [ 'retryCount' ], [ 'asc' ])
        const moreRecentAssignment = assessmentFilter(
          sortedAssignments, visualizeByEnum.SESSION_MORE_RECENT
        )

        const status = assessmentStatus(moreRecentAssignment)
        const startedAt = moreRecentAssignment?.startedAt
          ? new Date(moreRecentAssignment.startedAt)
          : null
        const finishedAt = moreRecentAssignment?.finishedAt
          ? new Date(moreRecentAssignment.finishedAt)
          : null

        return {
          ...statistic.student,
          ...statistic,
          ...moreRecentAssignment,
          status,
          startedAt,
          finishedAt,
        }
      }).filter(
        (student) => student.status === studentAssignmentStatus.FINISHED
      ) ?? []
    },
    classroomId() {
      return this.$route.params.classroomId
    },
    emptyState() {
      const image = 'assignments-not-found'

      if (this.isChallengeActivity) {
        return {
          image,
          title: 'Nenhum aluno participou da missão',
          description: 'O relatório só é gerado quando pelo menos um aluno entra na partida',
        }
      }

      return {
        image,
        title: 'Nenhum aluno desta turma iniciou a atividade',
        description: 'Compartilhe o link abaixo com os alunos para começarem a responder!',
      }
    },
  },
  watch: {
    noStudentStarted(value) {
      if (value) {
        if (this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement) {
          this.enableToShare = false
        } else {
          const { questionnaireCode, gradeId } = this
          this.getShareUrl({ questionnaireCode, gradeId })
        }
      }
    },
    classroomId() {
      this.noStudentStarted = false
    },
  },
  methods: {
    ...mapActions([ 'getShareUrl' ]),
    showEmptyState() {
      this.noStudentStarted = true
    },
    setNoStudentsFinishedHitRateLabel() {
      this.hitRateLabel = 'no-student-finished'
    },
  },
}
</script>

<style lang="scss" scoped>
.general-view{
  &__section {
    @include space-stack($size-xl);
  }

  &__cards {
    display: grid;
    grid-gap: $size-s;

    @include mq-m--mf {
      grid-gap: $size-m;
      grid-template-columns: 3fr 5fr;
    }

    @include mq-l--mf {
      grid-template-columns: 5fr 7fr;
    }

    &__item {
      padding: $size-s !important;
      @include flex-center-start;
      flex-wrap: wrap;

      @include mq-l--mf {
        padding: $size-m $size-l !important;
      }
    }
  }

  &__empty {
    @include flex-column-center;
  }
}

.empty-state {
  padding: 0;
}

.share-url {
  max-width: 450px;
}
</style>
