<template>
  <modal
    class="delete-confirmation-modal"
    :close-on-click-outside="false"
    :close-button-enabled="false"
    :footer-divider-enabled="false"
    :padding="{
      header: 0,
      body: `${$tokens.size_m} ${$tokens.size_l} ${$tokens.size_xs}`,
      footer: `0 ${$tokens.size_l} ${$tokens.size_m}`,
    }"
  >
    <h4
      class="delete-confirmation-modal__title"
      v-html="$t('myActivities.deleteConfirmation.title', {
        activity: activity.title,
      })"
    />
    <p
      class="delete-confirmation-modal__description"
      v-html="$t('myActivities.deleteConfirmation.description', {
        classroom,
      })"
    />
    <div class="delete-confirmation-modal__warning">
      <icon
        class="delete-confirmation-modal__warning__icon"
        type="alert-triangle"
      />
      <p class="delete-confirmation-modal__warning__text">
        {{ $t('myActivities.deleteConfirmation.warning') }}
      </p>
    </div>
    <template #footer>
      <s-button
        size="large"
        variation="tertiary"
        @click="$emit('cancel')"
      >
        {{ $t('myActivities.deleteConfirmation.cancel') }}
      </s-button>
      <s-button
        size="large"
        variation="danger"
        :loading="loading"
        @click="confirm"
      >
        {{ $t('myActivities.deleteConfirmation.confirm') }}
      </s-button>
    </template>
  </modal>
</template>
<script>
import questionnaireService from '@/service/questionnaires'

export default {
  name: 'DeleteConfirmationModal',
  props: {
    activity: {
      type: Object,
      required: true,
    },
    classroom: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async confirm() {
      this.loading = true
      await this.deleteAssignment()
      this.loading = false
      this.$emit('deleted')
    },
    async deleteAssignment() {
      await questionnaireService.deleteQuestionnaire(
        this.activity.questionnaireCode
      )
    },
  },
}
</script>
<style lang="sass" scoped>
.delete-confirmation-modal
  &__title
    margin-bottom: $size-s
  &__description
    color: $color-ink-light
    margin-bottom: $size-m
  &__warning
    color: $color-warning-darker
    background-color: $color-warning-lightest
    border-radius: $size-xs
    display: flex
    align-items: center
    padding: $size-xs $size-s
    &__icon
      flex-shrink: 0
      margin-right: $size-s
    &__text
      flex-grow: 1
</style>
