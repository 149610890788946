<template>
  <div class="drawer-header-skeleton">
    <header class="drawer-header-skeleton__main">
      <div class="drawer-header-skeleton__main__title">
        <skeleton-loader
          width="280px"
          height="38px"
        />
      </div>
      <div class="drawer-header-skeleton__main__subtitle">
        <skeleton-loader
          width="220px"
          height="22px"
        />
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'DrawerHeaderSkeleton',
}
</script>

<style lang="scss" scoped>
.drawer-header-skeleton {
  background: $color-white;
  box-shadow: $shadow-s rgba($color-ink, .15);
  @include flex-space-between;

  &__main {
    z-index: 1;
    @include flex-column-start;
    justify-content: space-between;
    padding: $size-s;

    @include mq-s--mf {
      padding: $size-l;
    }

    &__title {
      margin-bottom: $size-xxs;
    }

    &__subtitle {
      color: $color-ink-light;
    }
  }
}
</style>
