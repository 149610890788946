import http from '@/service/http'
import * as types from './types'

export default {
  getComplaintTypes: ({ commit }) => {
    commit(types.SET_COMPLAINTS, { loading: true })

    return http.get('complaint-types')
      .then((response) => {
        commit(types.SET_COMPLAINTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_COMPLAINTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  sendIssueComplaint: ({ commit }, {
    questionnaireCode,
    questionId,
    classroomId,
    complaint,
  }) => {
    const { description, complaintTypes } = complaint
    commit(types.SET_COMPLAINT, { loading: true })

    return http({
      method: 'post',
      url: `questionnaires/${questionnaireCode}/questions/${questionId}/complaints`,
      data: { description, complaintTypes },
      params: classroomId ? { classroomId } : {},
    })
      .then(() => {
        commit(types.SET_COMPLAINT, {
          data: { success: true },
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_COMPLAINT, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  clearComplaints: ({ commit }) => {
    commit(types.CLEAR_COMPLAINTS, {
      data: {},
      error: null,
    })
  },
  getQuestionComplaints: ({ commit }, {
    questionId,
    classroomId,
  }) => {
    commit(types.GET_QUESTION_COMPLAINTS, { loading: true })

    return http.get(`questions/${questionId}/complaints-details`, {
      params: {
        classroomId,
      },
    })
      .then((response) => {
        commit(types.GET_QUESTION_COMPLAINTS, {
          loading: false,
          data: response.data,
        })
      })
      .catch((error) => {
        commit(types.GET_QUESTION_COMPLAINTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  clearQuestionComplaints: ({ commit }) => {
    commit(types.GET_QUESTION_COMPLAINTS, {
      data: {},
      error: null,
    })
  },
}
