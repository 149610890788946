import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import { urls } from 'shared/utils/urls'

const apiBff = axios.create({
  baseURL: urls.ASSIGNMENT_REPORT_BFF,
  timeout: process.env.VUE_APP_TIMEOUT,
})

export const apiMock = axios.create({
  baseURL: urls.ASSIGNMENT_REPORT_BFF_MOCK,
  timeout: process.env.VUE_APP_TIMEOUT,
})

apiBff.interceptors.request.use(addAuthorizationHeader)
apiBff.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default apiBff
