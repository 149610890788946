<template>
  <DrawerHeaderSkeleton v-if="loading" />
  <div
    v-else
    :class="[ 'drawer-header', {'--title-only': !subtitle}]"
  >
    <header class="drawer-header__main">
      <div class="drawer-header__main__title">
        <h3>
          {{ title }}
        </h3>
      </div>
      <div class="drawer-header__main__subtitle">
        <p>
          {{ subtitle }}
        </p>
      </div>
    </header>
    <drawer-navigator
      :hide-navigation="hideNavigation"
      :hide-more-options="hideMoreOptions"
      :has-next="hasNext"
      :has-previous="hasPrevious"
      @previous="$emit('previous')"
      @next="$emit('next')"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import DrawerNavigator from 'App/components/QuestionDrawer/DrawerNavigator'
import DrawerHeaderSkeleton from 'App/components/DrawerPartials/DrawerHeader/DrawerHeaderSkeleton'

export default {
  name: 'DrawerHeader',
  components: {
    DrawerHeaderSkeleton,
    DrawerNavigator,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    loading: Boolean,
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    hideNavigation: Boolean,
    hideMoreOptions: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.drawer-header {
  background: $color-white;
  box-shadow: $shadow-s rgba($color-ink, .15);
  @include flex-space-between;

  &__main {
    z-index: 1;
    @include flex-column-start;
    justify-content: space-between;
    padding: $size-s;

    @include mq-s--mf {
      padding: $size-l;
    }

    &__title {
      margin-bottom: $size-xxs;
    }

    &__subtitle {
      color: $color-ink-light;
    }
  }

  &.--title-only {
    padding: 12px;
    align-items: center;

    .drawer-header__main {
      padding: 0;
      padding-left: $size-l;
      align-items: center;

      &__title {
        margin-bottom: 0;
      }
    }

    ::v-deep .drawer-navigator {
      padding: 0;
    }
  }
}

::v-deep .drawer-navigator {
  padding: $size-s $size-l;

  &__header {
    box-shadow: none;
    padding: 0;
  }
}
</style>
