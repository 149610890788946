import * as types from './types'

export default {
  [types.SET_SHARE_URL](state, shareUrlCache) {
    state.shareUrlCache = {
      ...state.shareUrlCache,
      ...shareUrlCache,
    }
  },
}
