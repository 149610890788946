<template>
  <section>
    <h3 class="ancestral-arena-list__title">
      {{ title }}
    </h3>

    <ul>
      <li
        v-for="matchHistory in list"
        :key="matchHistory.code"
        class="ancestral-arena-list__item"
      >
        <AncestralArenaListItem :match-history="matchHistory">
          <div>
            <s-button
              class="ancestral-arena-list__button"
              variation="secondary"
              icon-left="external-link"
              @click="redirectToArenaMissionView(matchHistory.modelCode)"
            >
              Ver missão
            </s-button>
            <s-button
              class="ancestral-arena-list__button"
              icon-left="bar-chart-2"
              @click="viewReport(matchHistory.code)"
            >
              Relatório
            </s-button>
          </div>
        </AncestralArenaListItem>
      </li>
    </ul>
  </section>
</template>

<script>
import { urls } from 'shared/utils/urls'

import AncestralArenaListItem from './AncestralArenaListItem'

export default {
  name: 'AssignmentsList',
  components: {
    AncestralArenaListItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Partidas ativas',
    },
  },
  methods: {
    redirectToArenaMissionView(questionnaireCode) {
      const url = `${urls.EUREKA_URI}/ancestral-arena/teacher/questionnaires/${questionnaireCode}/detail`
      window.location.assign(url)
    },
    viewReport(questionnaireCode) {
      const { classroomId, lectureId } = this.$route.query

      if (!classroomId || !lectureId || !questionnaireCode) {
        console.error('AssignmentsList missing params:', { classroomId, lectureId, questionnaireCode })

        this.$toasted.global.error({
          title: 'Não foi possível visualizar o relatório',
        })

        return
      }

      this.$router.push({
        name: 'teacher-report-general',
        params: { classroomId, questionnaireCode },
        query: {
          lectureId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-list {
  &__title {
    display: flex;
    align-items: center;
    font-family: Metropolis;
    font-weight: $font-weight-medium;
    font-size: $font-size-heading-6;
    line-height: $line-height-heading;
    color: $color-ink;
    margin-bottom: $size-s;
  }

  &__item {
    &:not(:first-child) {
      margin-top: $size-s;
    }
  }

  &__button {
    &:not(:first-child) {
      margin-left: $size-xs;
    }
  }
}
</style>
