import Vue from 'vue'
import mainMenuVue from '@sas-te/main-menu-vue'
import alfabeto from '@sas-te/alfabeto-vue'
import '@sas-te/alfabeto-vue/dist/alfabeto.css'
import VueTruncate from 'vue-truncate-filter'

import i18n from '@/i18n'
import router from 'App/router'
import store from 'App/store'
import App from './App'
import './plugins'

Vue.use(alfabeto)
Vue.use(mainMenuVue, { store, env: process.env.VUE_APP_MODE })
Vue.use(VueTruncate)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
