<template>
  <div
    :class="[
      'labelled-switch',
      `--${theme}`,
      `--${alignment}`,
    ]"
  >
    <label
      v-if="label"
      class="labelled-switch__label"
    >{{ label }}</label>
    <div
      v-if="options.length"
      class="labelled-switch__options-container"
    >
      <SButton
        v-for="(option, index) in options"
        :key="index"
        :class="[
          'labelled-switch__options-container__button',
          {
            '--active-option': option.value === value,
          },
        ]"
        :disabled="option.disabled"
        @click="$emit('select', option.value)"
      >
        {{ option.text }}
      </SButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelledSwitch',
  props: {
    theme: {
      type: String,
      default: 'light',
      validator: (value) => value.match(/(light|dark)/),
    },
    alignment: {
      type: String,
      default: 'row',
      validator: (value) => value.match(/(row|column)/),
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [ String, Number ],
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
$border-radius: 68px
$color-primary-darker: #02346e
$color-primary-darkest: #011f42
$font-weight-regular: 400
$input-inset-shadow: inset 0 2px 2px rgba(30, 33, 36, .2) !default
$color-black: #000

.labelled-switch
  display: flex
  flex-direction: row
  align-items: center

  &.--column
    flex-direction: column
    align-items: flex-start

  &.--dark

    ::v-deep .labelled-switch__label
      color: $color-white

    ::v-deep .labelled-switch__options-container
      background-color: $color-primary-darker

    ::v-deep .labelled-switch__options-container__button
      color: $color-white

      &:hover
        background: rgba($color-white, .15)

  &__label
    font-weight: $font-weight-regular
    margin-right: $size-xs
    color: $color-ink

  &__options-container
    display: flex
    align-items: center
    height: 36px
    min-width: 180px
    background-color: $color-ink-lightest
    border-radius: $border-radius
    box-shadow: $input-inset-shadow
    padding: $size-xxs

    &__button
      height: 28px
      min-width: 87px
      border-radius: $border-radius
      background: transparent
      box-shadow: none
      border: none
      color: $color-ink
      margin-right: $size-xxs

      &:hover
        background: rgba($color-black, .15)

      &:last-child
        margin-right: 0

    .--active-option
      background-color: $color-white
      box-shadow: 0 3px 6px rgba($color-black, .2)
      color: $color-primary-darkest !important

      &:hover
        background-color: $color-white !important
</style>
