import { STAGES } from '@/service/profiles'

const GRADES = [
  {
    id: 2,
    name: '5º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 3,
    name: '6º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 4,
    name: 'Pré-Universitário',
    type: STAGES.HIGH_SCHOOL,
  },
  {
    id: 5,
    name: '2ª série',
    type: STAGES.HIGH_SCHOOL,
  },
  {
    id: 6,
    name: '1o ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 7,
    name: '2º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 8,
    name: '3º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 9,
    name: 'Infantil II',
    type: STAGES.KINDERGARDEN,
  },
  {
    id: 10,
    name: 'Infantil III',
    type: STAGES.KINDERGARDEN,
  },
  {
    id: 11,
    name: 'Infantil IV',
    type: STAGES.KINDERGARDEN,
  },
  {
    id: 12,
    name: 'Infantil V',
    type: STAGES.KINDERGARDEN,
  },
  {
    id: 13,
    name: '4º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 14,
    name: '7º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 15,
    name: '8º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 16,
    name: '9º ano',
    type: STAGES.MIDDLE_SCHOOL,
  },
  {
    id: 17,
    name: '1ª série',
    type: STAGES.HIGH_SCHOOL,
  },

  {
    id: 18,
    name: 'Pré-Vestibular',
    type: STAGES.HIGH_SCHOOL,
  },
  {
    id: 19,
    name: 'SEMI',
    type: STAGES.HIGH_SCHOOL,
  },
  {
    id: 20,
    name: 'Semiextensivo 1º Semestre',
    type: STAGES.HIGH_SCHOOL,
  },
  {
    id: 21,
    name: '3ª série',
    type: STAGES.HIGH_SCHOOL,
  },

  {
    id: 22,
    name: 'Semiextensivo 2º Semestre',
    type: STAGES.HIGH_SCHOOL,
  },
]

export {
  GRADES,
  STAGES,
}
