import * as types from './types'

export default {
  [types.SET_EXTRA_ACTIVITIES](state, extraActivities) {
    state.extraActivities = {
      ...state.extraActivities,
      ...extraActivities,
    }
  },
  [types.SET_EXTRA_ACTIVITY](state, extraActivity) {
    state.extraActivity = {
      ...state.extraActivity,
      ...extraActivity,
    }
  },
  [types.SET_EXTRA_ACTIVITY_PROPERTIES](state, extraActivityProperties) {
    state.extraActivityProperties = {
      ...state.extraActivityProperties,
      ...extraActivityProperties,
    }
  },
  [types.SET_EXTRA_ACTIVITY_ASSIGNMENTS](state, extraActivityAssignments) {
    state.extraActivityAssignments = {
      ...state.extraActivityAssignments,
      ...extraActivityAssignments,
    }
  },
  [types.SET_EXTRA_ACTIVITIES_DIFFICULTIES](
    state,
    extraActivitiesDifficulties
  ) {
    state.extraActivitiesDifficulties = {
      ...state.extraActivitiesDifficulties,
      ...extraActivitiesDifficulties,
    }
  },
}
