<template>
  <div>
    <template v-if="hasError">
      <div
        :class="[
          'form-error__content',
          'form-error__warning',
          className,
        ]"
      >
        <slot />
      </div>

      <div class="form-error__text">
        <Icon
          type="alert-triangle"
          class="form-error__icon"
          size="16"
        />

        {{ errorMessage }}
      </div>
    </template>

    <div
      v-else
      :class="[
        'form-error__content',
        className,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    hasError: Boolean,
    className: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  &____content {
    padding: $size-xxs;
  }

  &__warning {
    border: 2px solid $color-danger;
    border-radius: $size-xs;
  }

  &__text {
    display: flex;
    color: $color-danger;
    align-items: center;
    font-size: $font-size-s;
    padding-top: $size-xxs;
  }

  ::v-deep &__icon {
    margin: 0 $size-xs 0 $size-xxs;
    color: $color-danger;
  }
}
</style>
