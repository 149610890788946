import http from '@/service/http'
import * as types from './types'

export default {
  getSubjects: ({ commit }) => {
    commit(types.SET_SUBJECTS, { loading: true })

    return http.get('subjects')
      .then((response) => {
        commit(types.SET_SUBJECTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_SUBJECTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getSubject: ({ commit }, subjectId) => {
    commit(types.SET_SUBJECT, { loading: true })

    return http.get(`subjects/${subjectId}`)
      .then((response) => {
        commit(types.SET_SUBJECT, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_SUBJECT, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setSubject: ({ commit }, subject) => {
    commit(types.SET_SUBJECT, { data: subject })
  },

  getSubjectLectures: ({ commit }, subjectId) => {
    commit(types.SET_SUBJECT_LECTURES, { loading: true })

    return http.get(`subjects/${subjectId}/lectures`)
      .then((response) => {
        commit(types.SET_SUBJECT_LECTURES, {
          data: response.data,
          loading: false,
        })

        return response.data
      })
      .catch((error) => {
        commit(types.SET_SUBJECT_LECTURES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
}
