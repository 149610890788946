<template>
  <BoxCardsContainer :max-visible="3">
    <BoxCardHorizontal
      key="sylabus-card"
      :classes="{ box: 'boxCard', description: 'noMaskDescription' }"
      title="Exercícios Personalizados"
      :description="syllabusCardDescription"
      theme="light"
      @click="goToSyllabus()"
    >
      <template #footer>
        <img :src="syllabusImage">
      </template>
    </BoxCardHorizontal>
    <BoxCardHorizontal
      v-for="extraActivity in extraActivities.data"
      :key="extraActivity.id"
      :title="extraActivity.name"
      :description="extraActivity.description"
      theme="dark"
      :classes="{ image: 'enemImage', box: 'boxCard' }"
      :image="enemLogo"
      @click="selectExtraActivity(extraActivity)"
    />
  </BoxCardsContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import querystring from 'querystring'

import { urls } from 'shared/utils/urls'

import enemLogo from 'App/assets/extraActivities/enem.png'
import syllabusImage from 'App/assets/extraActivities/syllabus.png'

import BoxCardsContainer from './BoxCardsContainer'
import BoxCardHorizontal from './BoxCardHorizontal'

export default {
  name: 'ExtraActivities',
  components: {
    BoxCardsContainer,
    BoxCardHorizontal,
  },
  data() {
    return {
      syllabusCardDescription: 'Confira a evolução das turmas nos simulados SAS Plataforma de Educação e revise os assuntos de dificuldade com as questões que receberam para praticar',
      enemLogo,
      syllabusImage,
    }
  },
  computed: {
    ...mapGetters([ 'extraActivities', 'personalData' ]),
    classroomId() {
      return this.$route.query.classroomId
    },
    lectureId() {
      return this.$route.query.lectureId
    },
  },
  methods: {
    ...mapActions([ 'setExtraActivity' ]),
    selectExtraActivity(extraActivity) {
      this.setExtraActivity(extraActivity)
      this.$router.push({
        name: 'teacher-extra-activity',
        params: {
          extraActivityId: extraActivity.id,
        },
        query: { ...this.$route.query },
      })
    },
    goToSyllabus() {
      this.$trackEvent({
        category: this.$track.category.syllabus,
        action: this.$track.action.syllabus.bannerClick,
        label: this.personalData.profileName,
      })

      const { classroomId, lectureId } = this
      const url = `${urls.CUSTOM_ASSIGNMENTS_REPORT_URL}?`
        .concat(querystring.stringify({ classroomId, lectureId }))

      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .boxCard {
    height: auto;
  }

  .enemImage {
    height: $size-xl;
  }

  .noMaskDescription {
    display: block;
  }

  .marginLeft {
    margin-left: $size-s;
  }
}
</style>
