<template>
  <div class="students-view-cards">
    <box
      v-for="(item, index) in students"
      :key="index"
      class="students-view-cards__item"
      elevation="2"
      elevation-hover="3"
      padding="0"
      @click.native="selectStudent(item, index)"
    >
      <div class="students-view-cards__item__student">
        <h6 class="students-view-cards__item__student__name">
          {{ item.name }}
        </h6>
        <div class="students-view-cards__item__student__amountReinforcement">
          {{ $tc(
            'report.students.amountReinforcement',
            item.finalizedProposedAssignmentsCount,
            { amount: item.finalizedProposedAssignmentsCount },
          ) }}
        </div>
      </div>
    </box>
  </div>
</template>

<script>
export default {
  name: 'StudentsPerformanceViewCards',
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectStudent(data, index) {
      this.$emit('selectStudent', {
        data,
        index,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-cards {
  &__item {
    @include space-stack($size-s);
    @include flex-center-start;

    &__student {
      @include flex-center-start;
      border-right: 1px solid transparentize($color-ink-lightest, 0.5);
      flex-grow: 1;
      padding: $size-s;
      flex-wrap: wrap;

      &__name {
        flex: 1 0 100%;
        margin-bottom: $size-xxs;
      }

      &__amountReinforcement {
        margin-right: $size-s;
      }
    }
  }
}
</style>
