<template>
  <div class="students-view-table">
    <s-table
      :fields="[ ...Array(4) ]"
      :loading="true"
      :skeleton-lines="10"
    >
      <template slot="0">
        <div class="students-view-table__name">
          <skeleton-loader
            width="250px"
            height="25px"
          />
        </div>
      </template>
      <template slot="1">
        <div class="students-view-table__status">
          <skeleton-loader
            width="100px"
            height="22px"
            border-radius="22px"
          />
        </div>
      </template>
      <template slot="2">
        <div class="students-view-table__hit-rate">
          <skeleton-loader
            width="34px"
            height="24px"
          />
        </div>
      </template>
      <template slot="3">
        <div class="students-view-table__finished-at">
          <skeleton-loader
            width="75px"
            height="20px"
          />
        </div>
      </template>
    </s-table>
  </div>
</template>

<script>
import STable from 'App/components/STable'

export default {
  name: 'StudentsViewTableSkeleton',
  components: {
    STable,
  },
}
</script>
