const sortStudents = {
  name: (a, b) => a.localeCompare(b),
  hitRate: (a, b, modifier = 1) => {
    if (a === null && b !== null) return 1
    if (a !== null && b === null) return -1
    if (a > b) return modifier
    if (a < b) return -1 * modifier

    return 0
  },
  finishedAt: (a, b, modifier = 1) => {
    if ((a ? a.getTime() : 0) > (b ? b.getTime() : 0)) return modifier
    if ((a ? a.getTime() : 0) < (b ? b.getTime() : 0)) {
      return -1 * modifier
    }

    return 0
  },
}

export default sortStudents
