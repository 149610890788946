export const mountByObject = (query) => {
  const terms = Object.entries(query)
  const concatTerms = terms.map((pair) => pair.join('='))

  if (terms.length > 1) {
    return concatTerms.join('&')
  }

  return concatTerms.join('')
}

export const mountByArray = (query) => {
  if (!Array.isArray(query[0]) && query.length === 2) {
    return query.join('=')
  }

  return query.map((pairs) => pairs.join('=')).join('&')
}

export default function mountQueryParams(query, format = true) {
  if (Array.isArray(query) && query.length !== 0) {
    return format ? '?'.concat(mountByArray(query)) : mountByArray(query)
  }
  if (typeof query === 'object' && Object.keys(query).length !== 0) {
    return format ? '?'.concat(mountByObject(query)) : mountByArray(query)
  }

  throw new Error(`mountQueryParams expects an Object or Array but got a '${typeof query}'`)
}
