<template>
  <div
    v-if="canShowSelector"
    class="school-year-selector"
  >
    <LabelledSwitch
      v-if="!loading"
      theme="dark"
      alignment="row"
      :label="$t('commons.schoolYear')"
      :value="selectedYear"
      :options="schoolYearOptions"
      @select="selectYearContent"
    />
    <skeleton-loader
      v-else
      width="245px"
      height="36px"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isAfter } from 'date-fns'

import LabelledSwitch from './LabelledSwitch'

const currentYear = new Date().getFullYear()
const lastYear = currentYear - 1

export default {
  name: 'SchoolYearSelector',
  components: {
    LabelledSwitch,
  },
  data() {
    return {
      schoolYearOptions: [
        { text: lastYear.toString(), value: lastYear },
        { text: currentYear.toString(), value: currentYear },
      ],
      selectedYear: null,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'reallocationDateLimit',
      'yearSelectedContent',
    ]),
    loading() {
      return this.reallocationDateLimit.loading
    },
    canShowSelector() {
      if (this.reallocationDateLimit.data) {
        const { data } = this.reallocationDateLimit
        const fullLimitDate = new Date(`${data}T23:59`)
        const now = new Date()

        return isAfter(fullLimitDate, now)
      }

      return false
    },
  },
  async created() {
    await this.getReallocationDateLimit()

    this.selectYearContent(this.yearSelectedContent)
  },
  methods: {
    ...mapActions([ 'getReallocationDateLimit' ]),
    selectYearContent(payload) {
      const transformedYearValue = parseInt(payload, 10)
      this.selectedYear = transformedYearValue

      this.$emit('select-year', transformedYearValue)
    },
  },
}
</script>

<style lang="sass" scoped>
.school-year-selector
  margin-bottom: $size-s

::v-deep .labelled-switch.--dark .labelled-switch__label
  color: #BEC9DD
  font:
    weight: $font-weight-bold
    size: 14px
</style>
