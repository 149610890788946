<template>
  <box
    :elevation="elevation"
    :elevation-hover="boxHoverElevation"
    :background="background"
    :border-radius="borderRadius"
    :padding="padding"
    :class="['box-list-item', `--${variation}`]"
  >
    <div v-if="loading">
      <skeleton-loader width="200px" />
      <skeleton-loader
        class="title-skeleton"
        width="400px"
      />
    </div>

    <template v-else>
      <div class="box-list-item__info">
        <span class="box-list-item__info__subtitle">{{ subtitle }}</span>
        <h6 class="box-list-item__info__title">
          {{ title }}
        </h6>
      </div>
    </template>
    <slot />
  </box>
</template>

<script>
export default {
  name: 'BoxListItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    variation: {
      type: String,
      default: 'column',
      validator: (value) => [ 'column', 'row' ].includes(value),
    },
    loading: Boolean,
    background: {
      type: String,
      default: '#fff',
    },
    borderRadius: {
      type: String,
      default: '8px',
    },
    elevation: {
      type: String,
      default: '1',
    },
    elevationHover: {
      type: String,
      default: '3',
    },
    padding: {
      type: String,
      default: '16px',
    },
  },
  computed: {
    boxHoverElevation() {
      return this.loading ? 1 : this.elevationHover
    },
  },
}
</script>

<style lang="scss" scoped>
.box-list-item.--column {
  &__info {
    &__subtitle {
      font-size: $font-size-s;
      color: $color-ink-light;
      color: red;
    }

    &__title {
      font-size: $font-size-m;
      color: $color-ink;
    }
  }
}

.box-list-item.--row {
  @include flex-space-between;
}

.sas-skeleton-loader.title-skeleton {
  margin-top: $size-xs;
}
</style>
