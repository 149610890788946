<template>
  <SButton
    variation="tertiary"
    icon-left="star"
    @click="openSurvey"
  >
    Avalie sua experiência
  </SButton>
</template>

<script>
import { mapGetters } from 'vuex'

import { urls } from 'shared/utils/urls'
import typeform from '@/service/typeform'

import { SButton } from '@sas-te/alfabeto-vue'

export default {
  name: 'SyllabusExperienceSurveyButton',
  components: {
    SButton,
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  methods: {
    openSurvey() {
      const appBaseUrl = urls.BASE_URL

      this.$trackEvent({
        category: this.$track.category.syllabusHome,
        action: this.$track.action.syllabus.rateExperience,
      })

      const typeformPopup = typeform.createPopup({
        formId: 'y5oPXEr6',
        hiddenFields: {
          account_id: this.user.id,
          account_name: this.user.name,
          school_id: this.user.school.id,
          school_name: this.user.school.name,
          profile: this.user.profiles[0].name,
          platform: 'web',
          url: appBaseUrl.concat(this.$route.path),
        },
      })

      typeformPopup.open()
    },
  },
}
</script>
