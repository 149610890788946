<template>
  <div class="slide-bullets">
    <div
      v-for="index in total"
      :key="index"
      class="slide-bullets__bullet"
      :class="current === index - 1 ? '--active' : ''"
    />
  </div>
</template>

<script>
export default {
  name: 'SlideBullet',
  props: {
    total: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="sass" scoped>
.slide-bullets
  +flex-center

  &__bullet
    background: #B8BED5
    width: 10px
    height: 10px
    border-radius: 10px
    +space-inline(12px)

    &.--active
      background: $color-primary
</style>
