import * as types from './types'

export default {
  [types.SET_TOGGLES](state, payload) {
    state.toggles = {
      ...state.toggles,
      ...payload,
    }
  },
}
