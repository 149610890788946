/*
TEACHER_CHALLENGE           ("Desafio do Eureka criado pelo professor"),
CHALLENGE_ACTIVITY          ("Desafios do Eureka"),
CHALLENGE_REINFORCEMENT     ("Desafio de reforço do Eureka criado pelo professor"),
---------
MIDDLE_SCHOOL               ("Atividade do livro - Fundamental"),
*/

import { toString } from 'lodash'

const questionnaireTypeEnum = {
  TEACHER_CHALLENGE: 'TEACHER_CHALLENGE',
  CHALLENGE_ACTIVITY: 'CHALLENGE_ACTIVITY',
  CHALLENGE_REINFORCEMENT: 'CHALLENGE_REINFORCEMENT',
  MIDDLE_SCHOOL: 'MIDDLE_SCHOOL',
}

export default Object.freeze(questionnaireTypeEnum)

export function isBookActivity(questionnaireType) {
  return toString(questionnaireType).toUpperCase() === questionnaireTypeEnum.MIDDLE_SCHOOL
}
