export const studentAssignmentStatus = {
  NOT_STARTED: 'Não iniciado',
  IN_PROGRESS: 'Em andamento',
  FINISHED: 'Finalizou',
  INCOMPLETE: 'Incompleto',
}

export const engagementClassroomStatus = {
  FINISHED: 'Finalizaram',
  IN_PROGRESS: 'Em andamento',
  STARTED: 'Iniciaram',
  NOT_STARTED: 'Não iniciaram',
  INCOMPLETE: 'Incompleto',
}

export function getStatusVariation(status) {
  switch (status) {
    case 'Não iniciado': return 'neutral'
    case 'Não iniciaram': return 'neutral'
    case 'Em andamento': return 'warning'
    case 'Finalizou': return 'success'
    case 'Finalizaram': return 'success'
    case 'Incompleto': return 'neutral'
    default: return ''
  }
}

export const colorsByStatus = ({ tokens, status }) => {
  switch (status) {
    case 'finalizaram': return tokens.color_primary
    case 'finalizou': return tokens.color_primary
    case 'em_andamento': return tokens.color_primary_lightest
    case 'nao_iniciaram': return tokens.color_ink_lightest
    case 'nao_iniciado': return tokens.color_ink_lightest
    case 'incompleto': return tokens.color_ink_light
    default: return ''
  }
}
