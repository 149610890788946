import { ready } from 'App/store/utils'

export default {
  teacherAssignments: (state) => ({
    ...state.teacherAssignments,
    ready: ready(state.teacherAssignments),
  }),
  bookAssignments: (state) => ({
    ...state.bookAssignments,
    ready: ready(state.bookAssignments),
  }),
}
