import http from '@/service/http'
import { getContentYear } from '../utils'
import * as types from './types'

export default {
  getTeacherAssignments: async ({ commit }, {
    classroomId,
    lectureId,
    contentYear,
  }) => {
    commit(types.SET_TEACHER_ASSIGNMENTS, {
      loading: true,
    })

    const params = { lectureId, classroomId, contentYear }

    return http.get('questionnaires', { params })
      .then((response) => {
        commit(types.SET_TEACHER_ASSIGNMENTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_TEACHER_ASSIGNMENTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getBookAssignments: async ({ commit, rootState }, {
    gradeId,
    lectureId,
    classroomId,
    contentYear,
  }
  ) => {
    commit(types.SET_BOOK_ASSIGNMENTS, {
      loading: true,
    })

    const gradeContractType = rootState.account.user?.grades?.find((grade) => grade.id === gradeId)?.contractType || ''
    const adaptContentYear = rootState.account.user?.grades?.find(
      (grade) => grade.id === gradeId
    ).eurekaContentYear
    const contentYearParam = getContentYear(gradeContractType, adaptContentYear, contentYear)

    const params = { gradeId, classroomId, contentYear: contentYearParam }

    return http.get(`lectures/${lectureId}/assignments`, { params })
      .then((response) => {
        commit(types.SET_BOOK_ASSIGNMENTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_BOOK_ASSIGNMENTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
}
