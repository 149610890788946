<template>
  <div>
    <div :class="$style.header">
      <div :class="[$style.headerTitleContainer, $style.headerTitleStudentContainer]">
        <span :class="$style.headerTitle">
          Alunos
        </span>
      </div>
      <div :class="[$style.headerTitleContainer, $style.headerTitleProgressContainer]">
        <span :class="[$style.headerTitle, $style.headerTitleProgress]">
          Progresso
        </span>
      </div>
    </div>
    <Box
      v-for="(student, studentIndex) in students"
      :key="student.id"
      :class="$style.card"
      :elevation="getElevation(student)"
      :elevation-hover="getElevationOnHover(student)"
      @click.native="selectStudent(student, studentIndex)"
    >
      <div>
        <Avatar
          :class="$style.avatar"
          :src="student.profilePicture"
          :title="student.student.name"
        />
      </div>
      <div :class="$style.infoContainer">
        <h6>
          {{ student.student.name }}
        </h6>
        <span :class="$style.progress">
          {{ getProgress(student) }}
        </span>
      </div>
      <div :class="$style.progressContainer">
        <span :class="$style.progress">
          {{ getProgress(student) }}
        </span>
      </div>
      <div>
        <div :class="$style.buttonContainer">
          <template v-if="student.status !== status.notAvailable">
            <SButton
              :class="$style.button"
              :loading="student.loading"
              variation="secondary"
            >
              Histórico
            </SButton>
            <SButton
              :class="$style.iconButton"
              :loading="student.loading"
              icon="arrow-right"
              variation="secondary"
            />
          </template>
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import { Box, SButton } from '@sas-te/alfabeto-vue'

import Avatar from 'App/components/Avatar'

export default {
  name: 'SyllabusStudentsCards',
  components: {
    Avatar,
    Box,
    SButton,
  },
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      status: {
        notAvailable: 'Não disponível',
      },
    }
  },
  methods: {
    selectStudent(student, studentIndex) {
      if (student.status === this.status.notAvailable) {
        return
      }

      this.$emit('select-student', { student, studentIndex })
    },
    getElevation(student) {
      return student.status === this.status.notAvailable ? 0 : 1
    },
    getElevationOnHover(student) {
      return student.status === this.status.notAvailable ? 0 : 2
    },
    getProgress(student) {
      return student.status !== this.status.notAvailable
        ? `${student.numberOfAnsweredQuestions}/${student.numberOfQuestions} questões`
        : this.status.notAvailable
    },
  },
}
</script>

<style lang="scss" module>
.header {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  padding-left: $size-s;
}

.headerTitleContainer {
  line-height: $line-height-reset;
}

.headerTitleStudentContainer {
  flex-grow: 1;
}

.headerTitle {
  font-size: $font-size-xs;
  text-transform: uppercase;
  color: $color-ink-light;
}

.headerTitleProgressContainer {
  display: none;

  @include mq-xs--mf {
    display: block;
  }
}

.headerTitleProgress {
  display: none;
  width: 213px;

  @include mq-s--mf {
    display: inline-block;
  }
}

.card {
  display: flex;
  align-items: center;
  padding: 12px $size-s !important;
  margin-bottom: $size-xs;
}

.avatar {
  height: $element-size-m !important;
  width: $element-size-m !important;
  min-height: $element-size-m !important;
  min-width: $element-size-m !important;
  margin-right: $size-s;
}

.infoContainer {
  flex-grow: 1;
  padding-right: $size-s;

  @include mq-s--mf {
    .progress {
      display: none;
    }
  }
}

.progressContainer {
  display: none;
  padding-right: $size-s;

  @include mq-s--mf {
    display: block;
  }
}

.progress {
  display: inline-block;
  font-size: $font-size-s;
  white-space: nowrap;
  min-width: 97px;
  color: $color-ink-light;
}

.buttonContainer {
  width: $element-size-m;

  .button {
    display: none;
  }

  @include mq-s--mf {
    width: 84px;

    .iconButton {
      display: none;
    }

    .button {
      display: inline-flex;
    }
  }
}
</style>
