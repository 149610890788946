<template>
  <s-button
    class="satisfaction-survey-button"
    variation="tertiary"
    icon-left="star"
    @click="openFeedbackSurvey"
  >
    {{ $t('survey.rateYourExperience') }}
  </s-button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import typeform from '@/service/typeform'
import storage from '@/service/storage'
import isEmpty from 'App/utils/isEmpty'
import { urls } from 'shared/utils/urls'

const SURVEY_ID = 'X79eUFoU'
const REPORT_VISITORS_KEY = 'report_visitors_count'

export default {
  name: 'SatisfactionSurvey',
  props: {
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
    questionnaire: {
      type: Object,
      default: () => {},
    },
    accessNumberToShowSurveyAuto: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visitCountByUser: {},
    }
  },
  computed: {
    ...mapState('typeform', [ 'isTypeformReady' ]),
    ...mapGetters([ 'user' ]),
    countVisitsReportByUserId() {
      const { visitCountByUser, user: { id } } = this
      const visitCountByUserFromLocalStorage = storage.getItem(REPORT_VISITORS_KEY)

      return (!isEmpty(visitCountByUser) && visitCountByUser[id])
       || (!isEmpty(visitCountByUserFromLocalStorage) && visitCountByUserFromLocalStorage[id])
       || 0
    },
    isFastSurvey() {
      return this.accessNumberToShowSurveyAuto > 0
    },
  },
  watch: {
    countVisitsReportByUserId(value) {
      if (this.isFastSurvey && value === this.accessNumberToShowSurveyAuto) {
        this.openFeedbackSurvey()
        this.resetVisitCountByUser()
      }
    },
  },
  created() {
    if (this.isFastSurvey) {
      this.visitCountByUser = this.getVisitCountByUser()
      this.incrementVisitCountByUser()
    }
  },
  methods: {
    getVisitCountByUser() {
      return storage.getItem(REPORT_VISITORS_KEY) || this.visitCountByUser
    },
    incrementVisitCountByUser() {
      this.visitCountByUser[this.user.id] = this.countVisitsReportByUserId + 1

      storage.setItem(REPORT_VISITORS_KEY, this.visitCountByUser)
    },
    resetVisitCountByUser() {
      this.visitCountByUser[this.user.id] = 0
      storage.setItem(REPORT_VISITORS_KEY, this.visitCountByUser)
    },
    openFeedbackSurvey() {
      const appBaseUrl = urls.BASE_URL
      const accountId = this.user.id
      const chapterTitle = this.questionnaire.title
      const chapterNumber = this.questionnaire.chapterNumber || null
      const lecture = this.questionnaire.lectureName || null
      const grade = this.selectedClassroom?.name
      const platform = 'web'
      const url = appBaseUrl.concat(this.$route.path)
      const type = this.questionnaire.questionnaireType

      const typeformPopup = typeform.createPopup({
        formId: SURVEY_ID,
        hiddenFields: {
          account_id: accountId,
          chapter_title: chapterTitle,
          chapter_number: chapterNumber,
          grade,
          lecture,
          platform,
          url,
          type,
        },
      })
      if (typeformPopup) {
        typeformPopup.open()
        this.$trackEvent({
          category: this.$track.category.feedback,
          action: this.$track.action.rateExperience,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.satisfaction-survey-button
  margin-right: 20px
</style>
