import { render, staticRenderFns } from "./AssignmentSectionLayout.vue?vue&type=template&id=13acfef9&scoped=true"
import script from "./AssignmentSectionLayout.vue?vue&type=script&lang=js"
export * from "./AssignmentSectionLayout.vue?vue&type=script&lang=js"
import style0 from "./AssignmentSectionLayout.vue?vue&type=style&index=0&id=13acfef9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13acfef9",
  null
  
)

export default component.exports