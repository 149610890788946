<template>
  <box
    class="recomposition-progression-path__performance-card"
    :border-radius="mq_m ? '0' : $tokens.border_radius_m"
  >
    <div class="recomposition-progression-path__performance-card__header">
      <div class="recomposition-progression-path__performance-card__title">
        <skeleton-loader
          v-if="loading"
          width="150px"
          height="33px"
        />
        <h4 v-else>
          Desempenho no diagnóstico da missão
        </h4>
      </div>
      <div class="recomposition-progression-path__performance-card__students-count">
        <skeleton-loader
          v-if="loading"
          width="120px"
          height="22px"
        />
        <p v-else-if="studentsAmountDescription">
          {{ studentsAmountDescription }}
        </p>
      </div>
    </div>
    <div class="recomposition-progression-path__performance-card__chart">
      <skeleton-loader
        v-if="loading"
        width="100%"
        height="100%"
      />
      <template v-else>
        <div
          v-for="(item, index) in barChartList"
          :key="index"
          class="recomposition-progression-path__performance-card__chart__bar"
          :style="barStyle(item)"
        />
      </template>
    </div>
    <div class="recomposition-progression-path__performance-card__legend">
      <template v-if="loading">
        <skeleton-loader
          width="360px"
          height="22px"
          class="recomposition-progression-path__performance-card__legend__item"
        />
        <skeleton-loader
          width="320px"
          height="22px"
          class="recomposition-progression-path__performance-card__legend__item"
        />
        <skeleton-loader
          width="160px"
          height="22px"
          class="recomposition-progression-path__performance-card__legend__item"
        />
        <skeleton-loader
          width="140px"
          height="22px"
          class="recomposition-progression-path__performance-card__legend__item"
        />
      </template>
      <template v-else>
        <div
          v-for="(item, index) in performanceList"
          :key="index"
          class="recomposition-progression-path__performance-card__legend__item"
        >
          <div
            class="recomposition-progression-path__performance-card__legend__item__bullet"
            :style="`background: ${item.color}`"
          />
          <div class="recomposition-progression-path__performance-card__legend__item__label">
            <span>{{ item.text }}</span>
          </div>
          <div class="recomposition-progression-path__performance-card__legend__item__value">
            <span><b> {{ studentsText[index].text }} </b></span>
          </div>
        </div>
      </template>
    </div>
  </box>
</template>

<script>
import simplur from 'simplur'

import mediaQueries from 'App/mixins/mediaQueries'
import accentFold from 'App/mixins/accentFold'

import { round } from 'lodash'

const colors = {
  FINISHED_ABLE: '#0DC18B',
  FINISHED_UNABLE: '#EB0000',
  IN_PROGRESS: '#C9CCCF',
  NOT_STARTED: '#F7F7F7',
}

export default {
  name: 'RecompositionProgressionPathTestPerformance',
  mixins: [ mediaQueries, accentFold ],
  props: {
    studentsTotalAmount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    performanceRange: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    studentsAmountDescription() {
      if (this.studentsTotalAmount <= 0 || !this.loading) {
        return ''
      }

      return simplur`Total de ${this.studentsTotalAmount} [aluno|alunos]`
    },
    studentsText() {
      return this.performanceList.map((item) => ({
        ...item,
        text: simplur`${item.amount} [aluno|alunos]`,
      }))
    },
    barChartList() {
      const performanceRangeWithColor = this.performanceRange.map((item) => ({
        ...item,
        amount: this.roundValue(item.amount, this.studentsTotalAmount),
        color: this.getLegendColor(item.name),
      }))

      if (performanceRangeWithColor.some((item) => item.amount === 0)) {
        return performanceRangeWithColor.filter((item) => item.amount !== 0)
      }

      return performanceRangeWithColor ?? []
    },
    performanceList() {
      const performanceRangeWithColor = this.performanceRange.map((item) => ({
        ...item,
        color: this.getLegendColor(item.name),
      }))

      return performanceRangeWithColor ?? []
    },
  },
  methods: {
    roundValue(amount, totalAmount) {
      if (!totalAmount > 0) return 0

      return round((amount / totalAmount) * 100)
    },
    barStyle(item) {
      return {
        width: `${item.amount}vw`,
        background: item.color,
      }
    },
    getLegendColor(label) {
      return colors[label]
    },
    mapEngagementRange(data) {
      return data.map((item) => ({
        ...item,
        color: this.getLegendColor(item.name),
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-progression-path__performance-card {
  align-content: space-between;
  min-width: 728px;
  min-height: 287px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
  }

  &__title {
    flex: 1 0 auto;
  }

  &__students-count {
    color: $color-ink-light;
  }

  &__chart {
    min-height: $size-m;
    margin: $size-m 0;
    border-radius: $size-xxs;
    @include flex-center;
    max-width: 100%;

    &__bar {
      height: 100%;
      border-right: 3px solid $color-white;

      &:first-child {
        border-top-left-radius: $size-xxs;
        border-bottom-left-radius: $size-xxs;
      }

      &:last-child {
        border: none;
        border-top-right-radius: $size-xxs;
        border-bottom-right-radius: $size-xxs;
      }
    }
  }

  &__legend {
    @include flex-column-start;
    justify-content: space-around;
    gap: $size-s;

    &__item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      &__bullet {
        width: $size-s;
        height: $size-s;
        display: inline-block;
        border-radius: 50%;
        margin-right: $size-s;
      }

      &__label {
        margin-right: $size-xs;
      }

      &__label, &__value {
        font-size: $font-size-s;
        color: $color-ink;
      }
    }
  }
}
</style>
