import * as types from './types'

export default {
  [types.SET_CLASSROOMS](state, classrooms) {
    state.classrooms = {
      ...state.classrooms,
      ...classrooms,
    }
  },
  [types.SET_ACTIVE_CLASSROOM](state, activeClassroom) {
    state.activeClassroom = {
      ...state.activeClassroom,
      ...activeClassroom,
    }
  },
}
