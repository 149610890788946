import * as types from './types'
import { featureTogglesInitialState } from './utils/featureToggles'
import { getToggles } from './utils/getToggles'

export default {
  getFeatureToggles: async ({ commit, rootState }) => {
    const { id: userId, school: { id: schoolId } } = rootState.account.user

    commit(types.SET_TOGGLES, {
      loading: true,
    })

    try {
      const userData = { userId, schoolId }
      const toggles = await getToggles(userData)

      commit(types.SET_TOGGLES, {
        ...toggles,
        loading: false,
        error: null,
      })
    } catch (error) {
      commit(types.SET_TOGGLES, {
        ...featureTogglesInitialState,
        loading: false,
        error,
      })
    }
  },
}
