var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'labelled-switch',
    `--${_vm.theme}`,
    `--${_vm.alignment}`,
  ]},[(_vm.label)?_c('label',{staticClass:"labelled-switch__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.options.length)?_c('div',{staticClass:"labelled-switch__options-container"},_vm._l((_vm.options),function(option,index){return _c('SButton',{key:index,class:[
        'labelled-switch__options-container__button',
        {
          '--active-option': option.value === _vm.value,
        },
      ],attrs:{"disabled":option.disabled},on:{"click":function($event){return _vm.$emit('select', option.value)}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }