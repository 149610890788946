<template>
  <box class="recomposition-progression-path__card">
    <skeleton-loader
      v-if="loading"
      width="116px"
      height="96px"
    />
    <radial-progress-bar
      v-else
      inner-stroke-color="#EFF0F0"
      :start-color="startColor"
      :stop-color="stopColor"
      :stroke-width="10"
      :diameter="donutSize"
      :total-steps="100"
      :completed-steps="number || 0"
    >
      <h4>
        <b>
          {{ Math.round(number) || 0 }}%
        </b>
      </h4>
    </radial-progress-bar>
    <skeleton-loader
      v-if="loading"
      height="60px"
      width="240px"
      class="recomposition-progression-path__card-title"
    />
    <h4
      v-else
      class="recomposition-progression-path__card-title"
    >
      {{ title }}
    </h4>
    <skeleton-loader
      v-if="loading"
      width="197px"
      height="24px"
      class="recomposition-progression-path__card-description"
    />
    <p
      v-else
      class="recomposition-progression-path__card-description"
    >
      {{ subtitle }}
    </p>
  </box>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  name: 'RecompositionProgressionPathCard',
  components: {
    RadialProgressBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      default: 0,
    },
    donutSize: {
      type: Number,
      default: 100,
    },
    startColor: {
      type: String,
      default: '#438DE4',
    },
    stopColor: {
      type: String,
      default: '#438DE4',
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-progression-path__card {
  min-width: 288px;
  min-height: 288px;

  @include flex-center-start;
  flex-direction: column;

  &-title {
    margin-top: $size-s;
  }

  &-description {
    color: $color-ink-light;
    align-self: flex-start;
    margin-top: $size-xs;
  }
}

</style>
