<template>
  <div class="knowledge-test-table">
    <h3 class="knowledge-test-table__title">
      Resultados da Missão Lógos
      <span
        v-tooltip.top="tooltip"
      >
        <icon
          class="knowledge-test-table__info-icon"
          type="info"
        />
      </span>
    </h3>
    <span class="knowledge-test-table__subtitle">
      Após o diagnóstico, os alunos que estão aptos podem seguir para revisar os próximos
      capítulos. Os não aptos precisarão resgatar os conhecimentos que tiveram dificuldade para
      estarem aptos para os novos capítulos.
    </span>
    <skeleton-loader
      v-if="loading"
      width="1032px"
      height="500px"
    />
    <s-table
      v-else
      ref="knowledgeTestTable"
      :selected-row="activeStudent"
      :content="flatStudents"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      @sorted="tableSorted"
      @click-row="clickRow"
    >
      <template
        slot="name"
        slot-scope="{ row }"
      >
        <div class="knowledge-test-table__name">
          <h6>
            {{ row.name }}
          </h6>
        </div>
      </template>

      <template
        slot="diagnosticStatus"
        slot-scope="{ row }"
      >
        <p class="knowledge-test-table__hit-rate">
          <s-badge
            :variation="getVariation(row)"
          >
            <b>{{ handleDiagnosticStatusText(row.diagnosticStatus) }}</b>
          </s-badge>
        </p>
      </template>

      <template
        slot="available"
        slot-scope="{ row }"
      >
        <div class="knowledge-test-table__status">
          <s-badge
            :variation="getFinishedStatusVariation(row.status)"
          >
            {{ getStatusText(row.status) }}
          </s-badge>
        </div>
      </template>

      <template
        slot="finishedAmount"
        slot-scope="{ row }"
      >
        <div class="knowledge-test-table__retry-count">
          {{ recoveryAbleRecommendedText(row) }}
        </div>
      </template>
      <template slot="details">
        <template v-if="!loading">
          <s-button variation="secondary">
            Detalhes
          </s-button>
        </template>
        <template v-else>
          <skeleton-loader
            width="90px"
            height="36px"
          />
        </template>
      </template>

      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.pagination',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
  </div>
</template>

<script>
import formatDate from 'App/mixins/formatDate'
import STable from 'App/components/STable'
import { isNaN, isNil } from 'lodash'

const statusTextEnum = {
  FINISHED: 'Concluído',
  NOT_FINISHED: 'Não concluída',
  NOT_REQUIRED: 'Sem recomendação',
  UNRELEASED: '-',
}

const diagnosticStatusText = {
  ABLE: 'Apto',
  NOT_ABLE: 'Não apto',
  ONGOING: 'Em andamento',
  NOT_STARTED: 'Não iniciou',
}

export default {
  name: 'RecompositionKnowledgeTestTable',
  components: {
    STable,
  },
  filters: {
    handleHitRate: (hitRate) => {
      if (isNaN(hitRate) || isNil(hitRate)) return '-'

      return `${hitRate}%`
    },
  },
  mixins: [
    formatDate,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
    activeStudent: {
      type: Object,
      default: null,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSort: {
        field: 'name',
        direction: 'asc',
      },
      fields: [
        {
          value: 'name',
          text: 'Aluno',
          sortable: true,
        },
        {
          value: 'diagnosticStatus',
          text: 'Diagnóstico',
          sortable: true,
        },
        {
          value: 'available',
          text: 'Resgate de Conhecimento',
        },
        {
          value: 'finishedAmount',
          text: 'Resgates recomendados',
          sortable: true,
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ],
    }
  },
  computed: {
    tooltip() {
      return `A missão logos se divide em 2 partes: <br />
      Diagnóstico: lista de questões que identificam lacunas nas <br /> aprendizagens essenciais. <br />
      Resgate de conhecimento: lista de questões que recompõem as <br /> aprendizagens essenciais que o estudante apresentou dificuldade <br /> na Missão Lógos. Pode haver mais de um resgate por missão.`
    },
    flatStudents() {
      return this.students.map((student) => {
        const { id, name } = student.student
        const { recovery, diagnosticStatus } = student
        const { status, able, recommended } = recovery

        return {
          id,
          name,
          status,
          able,
          diagnosticStatus,
          recommended,
        }
      })
    },
  },
  created() {
    if (this.$route.query.sort) {
      const [ field, direction ] = this.$route.query.sort.split(',')
      this.defaultSort = {
        field,
        direction,
      }
    }
  },
  methods: {
    clickRow({ data, dataIndex }) {
      this.$emit('select-student', {
        data,
        index: dataIndex,
      })
    },
    recoveryAbleRecommendedText(row) {
      if (row.able === null || row.recommended === null) return '-'

      return `${row.able} / ${row.recommended} aptos`
    },
    tableSorted(sort) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          sort: `${sort.field},${sort.direction}`,
        },
      })
    },
    getFinishedStatusVariation(finishedStatus) {
      switch (finishedStatus) {
        case 'FINISHED': return 'success'
        case 'NOT_FINISHED': return 'primary'
        case 'NOT_REQUIRED': return 'neutral'
        default: return null
      }
    },
    getStatusText(finishedStatus) {
      return statusTextEnum[finishedStatus] || ''
    },
    handleDiagnosticStatusText(diagnosticStatus) {
      return diagnosticStatusText[diagnosticStatus] || ''
    },
    getVariation({ diagnosticStatus: performance }) {
      switch (performance) {
        case 'ABLE': return 'success'
        case 'NOT_ABLE': return 'danger'
        case 'ONGOING': return 'warning'
        case 'NOT_STARTED': return 'neutral'
        default: return null
      }
    },
    previousStudent() {
      const currentIndex = this.flatStudents.findIndex((item) => item.id === this.activeStudent.id)

      if (currentIndex !== -1) {
        const previousIndex = currentIndex - 1

        if (previousIndex >= 0) {
          const previousStudent = this.flatStudents[previousIndex]
          this.activeStudent = previousStudent

          this.clickRow({ data: previousStudent, dataIndex: previousIndex })

          this.$router.push({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              activeStudent: previousStudent.id,
            },
          })
        }
      }
    },
    nextStudent() {
      const currentIndex = this.flatStudents.findIndex((item) => item.id === this.activeStudent.id)

      if (currentIndex !== -1) {
        const nextIndex = currentIndex + 1

        if (nextIndex < this.flatStudents.length) {
          const nextStudent = this.flatStudents[nextIndex]
          this.activeStudent = nextStudent

          this.clickRow({ data: nextStudent, dataIndex: nextIndex })

          this.$router.push({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              activeStudent: nextStudent.id,
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .tooltip .tooltip-inner {
  padding: 12px;
}

.knowledge-test-table {
  margin-top: $size-xl;

  &__name {
    min-width: 250px;
  }

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
    display: flex;
    align-items: center;
  }

  ::v-deep  &__info-icon {
    color: $color-ink-light;
    margin-left: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-l;
  }

  &__name h6 {
    font-weight: $font-weight-base;
    font-size: $font-size-m;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-badge i {
    margin-right: $size-xxs;
  }

  ::v-deep .sas-table__header {
    height: $size-xl;
  }

  ::v-deep .sas-badge.sas-badge--warning {
    color: #996108;
    background: #FFF3E0;
    border: 1px solid #FEE7C3;
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    border: 1px solid $color-ink;
    opacity: 0.75;
  }
}
</style>
