<template>
  <drawer
    class="student-drawer"
    :overlay-enabled="false"
    :size="activeQuestion ? '100%' : $tokens.device_l"
    @close="close"
  >
    <StudentDrawerHeader
      :student="student"
      :disable-controls="disableControls"
      :has-previous="hasPrevious"
      :has-next="hasNext"
      :overlay-enabled="false"
      :loading="answeredQuestions.loading"
      :questionnaire="questionnaire"
      :assignments="assignments"
      @close="close"
      @previous-student="$emit('previous-student')"
      @next-student="$emit('next-student')"
    />
    <div class="student-drawer__body">
      <template v-if="hasReport && hasAnswers">
        <StudentDrawerHitRate
          :hit-rate="hitRate"
          :correct="correctsCount"
          :total="questionsCount"
          :redo-count="student.redoCount"
          :loading="answeredQuestions.loading"
        />
        <StudentDrawerQuestionsTitle
          :questions-count="questionsCount"
          :loading="answeredQuestions.loading"
        />
      </template>
      <EmptyState
        v-else-if="!answeredQuestions.loading"
        :title="emptyState.title"
        :description="emptyState.description"
        :image="emptyState.image"
      />
      <template v-if="mq_m">
        <template v-if="answeredQuestions.loading">
          <StudentDrawerQuestionsCardsSkeleton />
        </template>
        <template v-else-if="!answeredQuestions.error">
          <StudentDrawerQuestionsCards
            :questions="questions"
            @selectQuestion="selectQuestion"
          />
        </template>
      </template>
      <template v-else>
        <template v-if="answeredQuestions.loading">
          <StudentDrawerQuestionsTableSkeleton />
        </template>
        <template v-else-if="!answeredQuestions.error">
          <StudentDrawerQuestionsTable
            v-if="questionsCount"
            ref="questionsTable"
            :questions="questions"
            :active-question="activeQuestion"
            @selectQuestion="selectQuestion"
          />
        </template>
      </template>
      <QuestionDrawer
        v-if="activeQuestion"
        :disabled-report="reportIsFromMagnaMission"
        :question="activeQuestion"
        :is-teacher-challenge="isTeacherChallenge"
        :assignment-code="assignmentCodeInitialOrQueryParam"
        :overlay-enabled="false"
        :has-previous="hasPreviousQuestion"
        :has-next="hasNextQuestion"
        :hide-report="hideMoreOptions"
        @update-reports="fetchQuestions"
        @on-close="clearSelectedQuestion"
        @on-previous="previousQuestion"
        @on-next="nextQuestion"
      />
    </div>
  </drawer>
</template>

<script>
import { studentAssignmentStatus } from 'App/utils/status'
import questions from '@/service/questions'
import mediaQueries from 'App/mixins/mediaQueries'
import keyEvents from 'App/mixins/keyEvents'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import EmptyState from 'App/components/EmptyState'
import { orderBy, isEmpty } from 'lodash'
import StudentDrawerHeader from './StudentDrawerHeader'
import StudentDrawerHitRate from './StudentDrawerHitRate'
import StudentDrawerQuestionsTitle from './StudentDrawerQuestionsTitle'
import StudentDrawerQuestionsCards from './StudentDrawerQuestionsCards'
import StudentDrawerQuestionsCardsSkeleton from './StudentDrawerQuestionsCardsSkeleton'
import StudentDrawerQuestionsTable from './StudentDrawerQuestionsTable'
import StudentDrawerQuestionsTableSkeleton from './StudentDrawerQuestionsTableSkeleton'

export default {
  name: 'StudentDrawer',
  components: {
    StudentDrawerHeader,
    StudentDrawerHitRate,
    StudentDrawerQuestionsTitle,
    StudentDrawerQuestionsCards,
    StudentDrawerQuestionsCardsSkeleton,
    StudentDrawerQuestionsTable,
    StudentDrawerQuestionsTableSkeleton,
    QuestionDrawer,
    EmptyState,
  },
  mixins: [ mediaQueries, keyEvents ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    hideMoreOptions: Boolean,
    disableControls: Boolean,
  },
  data() {
    return {
      answeredQuestions: {
        data: {},
        loading: false,
        error: null,
      },
      activeQuestionInnerIndex: -1,
    }
  },
  computed: {
    assignmentCodeInitialOrQueryParam() {
      if (this.$route.query?.assignmentCode) {
        return this.$route.query?.assignmentCode
      }

      return this.student.assignmentCode
    },
    activeStudent() {
      return this.$route.query?.activeStudent ?? null
    },
    assignments() {
      if (isEmpty(this.student.assignments)) {
        return []
      }

      return orderBy(this.student.assignments, [ 'retryCount' ], [ 'asc' ])
    },
    hasReport() {
      return this.student.status === studentAssignmentStatus.FINISHED
              || this.student.status === studentAssignmentStatus.INCOMPLETE
              || this.student.status === studentAssignmentStatus.IN_PROGRESS
    },
    hasAnswers() {
      return !this.answeredQuestions.error
        && Array.isArray(this.answeredQuestions.data)
        && this.answeredQuestions.data.length > 0
    },
    questions() {
      if (!this.answeredQuestions.loading && this.hasAnswers) {
        return this.answeredQuestions.data.map((question, index) => ({
          ...question,
          number: index + 1,
        }))
      }

      return []
    },
    questionsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? this.answeredQuestions.data.length
        : 0
    },
    correctsCount() {
      return !this.answeredQuestions.loading
        && this.hasAnswers
        ? this.answeredQuestions.data.filter(
          (question) => question.correct
        ).length
        : 0
    },
    hitRate() {
      if (!isEmpty(this.assignmentCodeInitialOrQueryParam)) {
        const hitRate = this.assignments.find((assignment) => (
          assignment.code === this.assignmentCodeInitialOrQueryParam
        ))?.hitRate ?? null

        return Math.round(hitRate)
      }

      return Math.round(this.student.hitRate ?? null)
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.questions.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    activeQuestionIndex() {
      return this.questions.findIndex((question) => (
        question.id === this.activeQuestion.id
      ))
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionInnerIndex < this.questions.length - 1
    },
    emptyState() {
      const studentName = this.student.name.split(' ')[0]

      const studentNotStarted = {
        title: this.$t('report.studentDrawer.empty.notStarted.title',
          { aluno: studentName }),
        description: this.$t('report.studentDrawer.empty.notStarted.description',
          { aluno: studentName }),
        image: 'empty-student-report',
      }

      const studentNotFinished = {
        title: this.$t('report.studentDrawer.empty.notFinished.title',
          { aluno: studentName }),
        description: this.$t('report.studentDrawer.empty.notFinished.description'),
        image: 'empty-not-finished',
      }

      return [ 'start', 'Não iniciado' ].includes(this.student.status)
        ? studentNotStarted
        : studentNotFinished
    },
    classroomId() {
      return this.$route.params.classroomId
        || this.$route.query.classroomId
    },
    isTeacherChallenge() {
      return this.questionnaire.data?.isTeacherChallenge || false
    },
    isChallengeActivity() {
      return this.questionnaire.data?.isChallengeActivity || false
    },
    reportIsFromMagnaMission() {
      return this.isTeacherChallenge || this.isChallengeActivity
    },
  },
  watch: {
    assignmentCodeInitialOrQueryParam: 'fetchQuestions',
    activeStudent: 'fetchQuestions',
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    this.fetchQuestions()
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
    this.clearAnsweredQuestions()
  },
  methods: {
    close() {
      delete this.$route.query.assignmentCode
      this.$emit('close')
    },
    async fetchQuestions() {
      if (!this.hasReport) {
        this.clearAnsweredQuestions()

        return
      }

      const beforeRequestStudentId = this.student.id

      this.answeredQuestions.loading = true

      await this.getAnsweredQuestion(beforeRequestStudentId)

      this.answeredQuestions.loading = false
    },
    clearAnsweredQuestions() {
      this.answeredQuestions = {
        data: {},
        loading: false,
        error: null,
      }
    },
    async getAnsweredUserQuestion(beforeRequestStudentId) {
      try {
        const answeredQuestions = await questions
          .fetchAnsweredUserQuestions(this.assignmentCodeInitialOrQueryParam, this.classroomId)

        if (this.student.id === beforeRequestStudentId) {
          this.answeredQuestions.data = answeredQuestions
        }
      } catch (error) {
        this.answeredQuestions.error = error.response
      }
    },
    async getAnsweredQuestion(beforeRequestStudentId) {
      if (this.assignmentCodeInitialOrQueryParam && this.classroomId) {
        try {
          const answeredQuestions = await questions
            .fetchAnsweredQuestions(this.assignmentCodeInitialOrQueryParam, this.classroomId)

          if (this.student.id === beforeRequestStudentId) {
            this.answeredQuestions.data = answeredQuestions
          }
        } catch (error) {
          this.answeredQuestions.error = error.response
        }
      }
    },
    selectQuestion(event) {
      const question = event.data
      const { activeQuestion } = this.$route.query
      if (question.id !== activeQuestion) {
        this.activeQuestionInnerIndex = event.index
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }
      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex - 1],
            index: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previousQuestion()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.filteredQuestions.length - 1) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex + 1],
            index: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.nextQuestion()
      }
    },
    pressEsc(event) {
      if (this.isEscKeyPressed(event) && !this.$route.query.activeQuestion) {
        this.close()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.student-drawer
  &__body
    padding-bottom: $end-space
</style>
