<template>
  <div class="class-report-options">
    <CustomDropdown
      text=""
      origin="bottom-right"
      variation="tertiary"
      icon="more-horizontal"
    >
      <CustomDropdownItem
        icon-left="share"
        @click="handleShareClick"
      >
        {{ $t('share.title') }}
      </CustomDropdownItem>
      <CustomDropdownItem
        icon-left="star"
        @click="handleSurveyClick"
      >
        {{ $t('survey.rateYourExperience') }}
      </CustomDropdownItem>
    </CustomDropdown>
    <SatisfactionSurvey
      ref="survey"
      :class="{'--isHideElement': mq_m}"
    />
    <ShareToolbar
      ref="share"
      :selected-classroom="selectedClassroom"
    />
  </div>
</template>

<script>
import mediaQueries from 'App/mixins/mediaQueries'
import SatisfactionSurvey from 'App/components/SatisfactionSurvey'
import CustomDropdownItem from 'App/components/CustomDropdownItem'
import CustomDropdown from 'App/components/CustomDropdown'
import ShareToolbar from './ShareToolbar'

export default {
  name: 'ClassReportOptions',
  components: {
    CustomDropdown,
    CustomDropdownItem,
    SatisfactionSurvey,
    ShareToolbar,
  },
  mixins: [ mediaQueries ],
  props: {
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleShareClick() {
      this.$refs.share.openShareForm()
    },
    handleSurveyClick() {
      this.$refs.survey.openFeedbackSurvey()
    },
  },
}
</script>
<style lang="sass">
.--isHideElement
  display: none
</style>
