import { saveAs } from 'file-saver'
import http from '@/service/http'
import userAgent from 'App/utils/userAgent'

export default {
  data() {
    return {
      downloading: false,
    }
  },
  methods: {
    async downloadFile(link, fileName, contentType) {
      this.downloading = true
      const response = await http.get(link, {
        responseType: 'blob',
      })
      this.createdBlob(
        response.data,
        contentType,
        fileName
      )
      this.downloading = false
    },
    createdBlob(data, contentType, fileName) {
      const blob = new Blob([ data ], { type: contentType })

      if (userAgent.isIos()) {
        const reader = new FileReader()
        reader.onload = () => {
          window.location.href = reader.result
        }
        reader.filename = fileName
        reader.readAsDataURL(blob)
      } else {
        saveAs(blob, fileName)
      }
    },
  },
}
