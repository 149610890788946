<template>
  <div class="student-drawer-questions-table">
    <s-table
      :fields="[ ...Array(4) ]"
      :loading="true"
      :skeleton-lines="5"
    >
      <template slot="0">
        <div class="student-drawer-questions-table__number">
          <skeleton-loader
            width="100px"
            height="26px"
          />
        </div>
        <skeleton-loader
          width="250px"
          height="16px"
        />
      </template>
      <template slot="1">
        <skeleton-loader
          width="140px"
          height="20px"
        />
      </template>
      <template slot="2">
        <skeleton-loader
          width="70px"
          height="20px"
        />
      </template>
      <template slot="3">
        <skeleton-loader
          border-radius="28px"
          width="28px"
          height="28px"
        />
      </template>
    </s-table>
  </div>
</template>
<script>
import STable from 'App/components/STable'

export default {
  name: 'StudentDrawerQuestionsTableSkeleton',
  components: {
    STable,
  },
}
</script>
<style lang="sass" scoped>
.student-drawer-questions-table
  padding: 0 $size-s $size-s

  +mq-s--mf
    padding: 0 $size-l $size-m

    &__number
      margin-bottom: $size-xxs
</style>
