import qs from 'qs'
import { isEmpty } from 'lodash'
import http from './http'
import apiBff from './apiBff'

const QUESTION_STATES = {
  CORRECT: 'correct',
  WRONG: 'wrong',
  NOT_ANSWERED: 'not_answered',
}

export {
  QUESTION_STATES,
}

const nullableParam = (param, defaultValue = null) => (isEmpty(param) ? defaultValue : param)

export default {
  fetchQuestion: ({
    questionnaireCode,
    assignmentCode,
    questionId,
  }) => {
    if (questionnaireCode) {
      return http.get(
        `/questionnaires/${questionnaireCode}/assignments`
      )
    }
    if (assignmentCode && questionId) {
      return http.get(
        `/assignments/${assignmentCode}/questions/${questionId}`
      )
    }
    if (assignmentCode) {
      return http.get(`assignments/${assignmentCode}/questions`)
    }

    return Promise.reject()
  },
  fetchQuestions: async ({ questionnaireCode }) => {
    let response = {
      data: {},
    }
    if (questionnaireCode) {
      response = await http.get(
        `/questionnaires/${questionnaireCode}/questions`
      )
    }

    return response.data
  },
  fetchPageFlips: async (questionnaireCode) => {
    const response = await http.get(
      `questionnaires/${questionnaireCode}/pageflips`
    )

    return response.data
  },
  fetchVideo: (questionnaireCode) => (
    http.get(`questionnaires/${questionnaireCode}/videos`)
  ),
  postAnswer: async ({
    assignmentCode,
    questionId,
    markedOption,
  }) => {
    const response = await http.post(
      `assignments/${assignmentCode}/answers`,
      { questionId, markedOption }
    )

    return response.data
  },
  fetchAnsweredQuestions: async (assignmentCode, classroomId) => {
    const params = { classroomId }
    const response = await apiBff.get(
      `/assignments/${assignmentCode}/answered-questions`, {
        params,
      }
    )

    return response.data
  },
  fetchAnsweredUserQuestions: async (assignmentCode, classroomId) => {
    const queryParams = classroomId ? `?classroomId=${classroomId}` : ''
    const response = await http
      .get(`/assignments/${assignmentCode}/answered-user-questions${queryParams}`)

    return response.data
  },
  fetchAnsweredQuestionV2: async ({ questionId, assignmentCode, questionOrigin }) => {
    const assignmentCodeNullable = nullableParam(assignmentCode)
    const questionOriginNullable = nullableParam(questionOrigin)

    const params = {
      assignmentCode: assignmentCodeNullable,
      questionOrigin: questionOriginNullable,
    }

    const response = await apiBff.get(
      `/v2/assignments/answered-questions/${questionId}`, {
        params,
        paramsSerializer: (_params) => qs
          .stringify(_params, { arrayFormat: 'comma', skipNulls: true }),
      }
    )

    return response.data
  },
}
