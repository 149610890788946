<template>
  <div class="student-drawer-questions-cards">
    <box
      v-for="index in 5"
      :key="index"
      class="student-drawer-questions-cards__item"
      elevation="2"
      elevation-hover="3"
      :padding="$tokens.size_s"
    >
      <div class="student-drawer-questions-cards__item__detail">
        <div class="student-drawer-questions-cards__item__detail__number">
          <skeleton-loader
            width="100px"
            height="26px"
          />
        </div>
        <span class="student-drawer-questions-cards__item__detail__content">
          <skeleton-loader
            width="100%"
            height="16px"
          />
        </span>
      </div>
      <span class="student-drawer-questions-cards__item__level">
        <skeleton-loader
          width="60px"
          height="20px"
        />
      </span>
      <div class="student-drawer-questions-cards__item__hit">
        <skeleton-loader
          border-radius="28px"
          width="28px"
          height="28px"
        />
      </div>
    </box>
  </div>
</template>
<script>
import mediaQueries from 'App/mixins/mediaQueries'

export default {
  name: 'StudentDrawerQuestionsCardsSkeleton',
  mixins: [ mediaQueries ],
}
</script>
<style lang="sass" scoped>
.student-drawer-questions-cards
  padding: $size-xs $size-s $size-s

  +mq-s--mf
    padding: $size-m $size-l

  &__item
    +space-stack($size-s)
    +flex-center-start

    &__detail
      flex-grow: 1

      &__number
        margin-bottom: $size-xxs

      &__content
        color: $color-ink-light

    &__level
      margin-left: $size-xs

      +mq-s--mf
        margin-left: $size-xl

    &__hit
      margin-left: $size-m

      +mq-s--mf
        margin-left: $size-xxl
</style>
