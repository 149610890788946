var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"proposed-assignment"},[_c('AssignmentsListItem',{attrs:{"assignment":_vm.mappedAssignment,"variation":"secondary"}},_vm._l((_vm.actionButtonsList),function({
        isVisible,
        buttonOptions,
        buttonAction,
        buttonClasses,
        buttonText,
        icon
      },index){return _c('div',{key:`${icon}-${index}`},[(isVisible)?_c('s-button',_vm._b({class:buttonClasses,on:{"click":buttonAction},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(icon,{tag:"component"})]},proxy:true}],null,true)},'s-button',buttonOptions,false),[(buttonText)?[_vm._v(" "+_vm._s(buttonText)+" ")]:_vm._e()],2):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }