<template>
  <div class="proposed-assignment">
    <AssignmentsListItem
      :assignment="mappedAssignment"
      variation="secondary"
    >
      <div
        v-for="({
          isVisible,
          buttonOptions,
          buttonAction,
          buttonClasses,
          buttonText,
          icon
        }, index) in actionButtonsList"
        :key="`${icon}-${index}`"
      >
        <s-button
          v-if="isVisible"
          v-bind="buttonOptions"
          :class="buttonClasses"
          @click="buttonAction"
        >
          <template #icon>
            <component :is="icon" />
          </template>
          <template v-if="buttonText">
            {{ buttonText }}
          </template>
        </s-button>
      </div>
    </assignmentslistitem>
  </div>
</template>

<script>
import AssignmentsListItem from 'App/components/Assignments/AssignmentsListItem'

export default {
  name: 'ProposedAssignment',
  components: {
    AssignmentsListItem,
  },
  props: {
    selectedClassroom: {
      type: Object,
      default: null,
    },
    selectedLecture: {
      type: Object,
      default: null,
    },
    proposedAssignment: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    assignment() {
      return {
        name: 'Questões sugeridas',
        ...this.proposedAssignment,
      }
    },
    lectureName() {
      return this.selectedLecture?.name || ''
    },
    actionButtonsList() {
      const { assignment: { hasReport } } = this
      const buttons = []
      const buttonClasses = [
        'sas-button__icon',
        '--medium',
        'proposed-assignment__actions',
      ]
      const seeReportButton = {
        buttonClasses,
        buttonText: this.$t('commons.seeReport'),
        buttonAction: this.goToStudentsPerformance,
        isVisible: true,
        buttonOptions: { variation: 'secondary' },
      }

      if (hasReport) {
        buttons.push(seeReportButton)
      } else {
        buttons.push({
          ...seeReportButton,
          buttonOptions: {
            background: 'rgba(102, 110, 117)',
            disabled: true,
          },
        })
      }

      return buttons
    },
    mappedAssignment() {
      return {
        subtitle: this.assignment.name,
        title: this.lectureName,
        id: this.assignment.name.split('').length,
        status: null,
      }
    },
  },
  methods: {
    goToStudentsPerformance() {
      const { subject: { id: subjectId } } = this.selectedLecture

      this.$trackEvent({
        category: this.$track.category.proposedQuestions,
        action: this.$track.action.seeReport,
        label: this.selectedLecture.name,
      })

      this.$router.push({
        name: 'teacher-performance-students',
        params: {
          subjectId,
          classroomId: this.selectedClassroom.id,
        },
        query: {
          lectureId: this.selectedLecture.id,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.proposed-assignment {
  &__details {
    &__title {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }

    &__with-badge {
      .sas-badge {
        margin-left: $size-xs;
      }
    }
  }

  ::v-deep .assignments-list-item {
    background: url('App/assets/beige-mask-sm.svg') top right / cover no-repeat,
      url('App/assets/lamp-icon.svg') top 20% left 18px no-repeat !important;
    background-color: rgba(255, 255, 255, 1) !important;

    @include mq-m--mf {
      background: url('App/assets/beige-mask.svg') top right / cover no-repeat,
        url('App/assets/lamp-icon.svg') center left 18px no-repeat !important;
      background-color: rgba(255, 255, 255, 1) !important;
    }

    &__details {
      padding-left: 3.5rem;

      @include mq-m--mf {
        padding-left: 3.2rem;
      }
    }
  }
}

.--margin-right {
  margin-right: 22px;
}

::v-deep .sas-button {
  i {
    @include mq_m__mf {
      margin-right: $size-xs;
    }
  }

  @include mq-m {
    margin-bottom: $size-xs;
  }

  &:disabled {
    opacity: 1;
  }
}
</style>
