<template>
  <SButton
    variation="tertiary"
    icon-left="help-circle"
    size="large"
    @click="openFeedbackSurvey"
  >
    {{ $t('onBoarding.wasItEasyToGetHere') }}
  </SButton>
</template>

<script>
import { mapGetters } from 'vuex'
import { SButton } from '@sas-te/alfabeto-vue'

import { arrayUniq } from 'App/utils/arrays'
import { urls } from 'shared/utils/urls'
import typeform from '@/service/typeform'

export default {
  name: 'TeacherOnBoardingFeedback',
  components: { SButton },
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
    ]),
    teacherLectures() {
      const list = []

      this.classrooms.data
        .forEach(({ lectures }) => {
          lectures.forEach(({ name }) => { list.push(name) })
        })

      return arrayUniq(list)
    },
  },
  methods: {
    openFeedbackSurvey() {
      const accountId = this.user.id
      const grades = arrayUniq(this.user.grades.map(({ name }) => name))
      const platform = 'web'
      const url = urls.BASE_URL.concat(this.$route.path)
      const [ profile ] = this.user.profiles

      const typeformPopup = typeform.createPopup({
        formId: 'oxPoEQOP',
        hiddenFields: {
          account_id: accountId,
          grade: grades,
          platform,
          url,
          lecture: this.teacherLectures,
          profile: profile.name,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="scss" scoped>
.sas-button {
  margin: 0 $size-xs;

  @include mq-m {
    margin: $size-xs 0;
  }
}
</style>
