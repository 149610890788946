<template>
  <drawer
    class="question-drawer"
    :background="$tokens.color_white"
    :disable-overlay="overlayEnabled"
    :size="openQuestionProblems && canShowProblems
      ? '100%' : $tokens.device_m"
  >
    <DrawerNavigator
      :has-next="hasNext"
      :has-previous="hasPrevious"
      :disabled-report="reportIsDisabled"
      :hide-more-options="hideReport"
      :hide-navigation="hideNavigation"
      @report-succeed="updateQuestionDetails"
      @previous="$emit('on-previous')"
      @next="$emit('on-next')"
      @close="$emit('on-close')"
    >
      <template #header>
        <skeleton-loader
          v-if="loading"
          width="120px"
          height="33px"
        />
        <div
          v-else
          class="question-drawer__header"
        >
          <h4 class="question-drawer__header__title">
            {{ $t(
              'report.questionsDrawer.questionNumber',
              { number: question.number }
            ) }}
          </h4>
          <template v-if="hasQuestionStatus">
            <AnswerStatusBadge
              :row="question"
              :tooltip="handleTooltip(question)"
            />
            <small
              :class="[
                'question-drawer__header__hit',
                `--${resultModifier}`
              ]"
            >
              {{ resultLabel }}
            </small>
          </template>
        </div>
      </template>
      <template #child>
        <QuestionDrawerDetails
          :question="questionWithDetails"
          :loading="loading"
        />
        <template v-if="hasHitRate">
          <QuestionDrawerHitRateSkeleton
            v-if="loading"
          />
          <QuestionDrawerHitRate
            v-else
            :hit-rate="question.hitRate || 0"
            :hit-count="question.hitCount || 0"
            :count="question.count || 0"
          />
        </template>
        <QuestionDrawerBodySkeleton v-if="loading" />
        <template v-else>
          <QuestionDrawerComplaint
            v-if="hasComplaint"
            :complaint="complaintsData"
            @show-problems="showDrawerProblems"
          />
          <QuestionDrawerBody
            :question="questionWithDetails"
          />
        </template>
      </template>
    </DrawerNavigator>
    <QuestionDrawerProblems
      v-if="openQuestionProblems && canShowProblems"
      :complaint="questionComplaints.data"
      :loading="questionComplaints.loading"
      :hide-report="hideReport"
      @close="hideDrawerProblems"
    />
  </drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import updateRouteQuery from 'App/mixins/updateRoute'
import questions from '@/service/questions'
import mediaQueries from 'App/mixins/mediaQueries'
import badgeStyle from 'App/mixins/badgeStyle'
import keyEvents from 'App/mixins/keyEvents'
import isEmpty from 'App/utils/isEmpty'
import { isNil } from 'lodash'
import answerStatusEnum from 'App/enums/answerStatus'
import QuestionDrawerProblems from './ProblemsDrawer/QuestionDrawerProblems'
import QuestionDrawerBody from './QuestionDrawerBody'
import QuestionDrawerBodySkeleton from './QuestionDrawerBodySkeleton'
import QuestionDrawerDetails from './QuestionDrawerDetails'
import QuestionDrawerHitRate from './QuestionDrawerHitRate'
import QuestionDrawerHitRateSkeleton from './QuestionDrawerHitRateSkeleton'
import QuestionDrawerComplaint from './QuestionDrawerComplaint'
import DrawerNavigator from './DrawerNavigator'
import AnswerStatusBadge from '../StudentDrawer/components/AnswerStatusBadge'

export default {
  name: 'QuestionDrawer',
  components: {
    QuestionDrawerDetails,
    QuestionDrawerBody,
    QuestionDrawerBodySkeleton,
    QuestionDrawerHitRate,
    QuestionDrawerHitRateSkeleton,
    DrawerNavigator,
    QuestionDrawerComplaint,
    QuestionDrawerProblems,
    AnswerStatusBadge,
  },
  mixins: [ mediaQueries, badgeStyle, keyEvents, updateRouteQuery ],
  props: {
    question: {
      type: Object,
      default: null,
    },
    assignmentCode: {
      type: String,
      required: false,
      default: '',
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    overlayEnabled: {
      type: Boolean,
      default: true,
    },
    disabledReport: Boolean,
    hideReport: Boolean,
    isTeacherChallenge: Boolean,
  },
  data() {
    return {
      questionDetails: {
        data: {},
        loading: false,
        error: null,
      },
      showProblemsReported: false,
      canDispatchUpdateEvent: false,
    }
  },
  computed: {
    ...mapGetters([ 'questionComplaints', 'complaint' ]),
    resultLabel() {
      switch (this.question.answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'Acertou'
        case answerStatusEnum.WRONG:
          return 'Errou'
        case answerStatusEnum.BLANK:
          return 'Em branco'
        default:
          return ''
      }
    },
    resultModifier() {
      switch (this.question.answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'success'
        case answerStatusEnum.WRONG:
          return 'danger'
        case answerStatusEnum.BLANK:
          return 'blank'
        default:
          return ''
      }
    },
    questionWithDetails() {
      return {
        ...this.question,
        ...this.questionDetails.data,
      }
    },
    loading() {
      return this.questionDetails.loading
      || this.questionComplaints.loading
      || !this.question.id
    },
    hasComplaint() {
      const { complaintsData } = this

      if (typeof complaintsData === 'object' && complaintsData !== null) {
        return !isEmpty(complaintsData)
      }

      return false
    },
    isTeacher() {
      return Boolean(this.$route?.params?.classroomId)
    },
    complaintsData() {
      const { isTeacher, questionComplaints, questionDetails } = this

      if (isTeacher) {
        return questionComplaints.data
      }

      return questionDetails.data.questionComplaint
    },
    hasQuestionStatus() {
      return !isNil(this.question.answerStatus)
    },
    hasHitRate() {
      return !isNil(this.question.hitRate) && !isNil(this.question.hitCount)
    },
    openQuestionProblems() {
      return this.$route?.query?.openQuestionProblems === 'true'
    },
    canShowProblems() {
      return !this.questionComplaints.error
    },
    userIsQuestionComplaintAuthor() {
      const { complaints } = this.questionComplaints.data

      return complaints?.some((item) => item.complaintAuthor)
    },
    reportIsDisabled() {
      const {
        disabledReport,
        userIsQuestionComplaintAuthor,
        complaint,
      } = this

      return disabledReport
      || userIsQuestionComplaintAuthor
      || complaint.loading
    },
  },
  watch: {
    question() {
      this.fetchQuestionDetails()
      if (this.$route.params.classroomId && this.question.id) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      }
    },
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    if (this.question.id) {
      this.fetchQuestionDetails()

      if (this.$route.params.classroomId) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      }
    }
  },
  beforeDestroy() {
    if (this.canDispatchUpdateEvent) {
      this.$emit('update-reports')
    }
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
  },
  methods: {
    ...mapActions([ 'getQuestionComplaints' ]),
    async fetchQuestionDetails() {
      this.questionDetails.loading = true
      const beforeRequestQuestionId = this.question.id

      this.getAnsweredQuestions(beforeRequestQuestionId)
    },
    async getAnsweredQuestions(beforeRequestQuestionId) {
      const questionId = this.question.id

      if (!questionId) return

      try {
        const { assignmentCode } = this
        const { questionOrigin } = this.question
        const parameters = {
          questionId,
          assignmentCode,
          questionOrigin,
        }
        const questionDetails = await questions.fetchAnsweredQuestionV2(parameters)

        if (questionId === beforeRequestQuestionId) {
          this.questionDetails.data = questionDetails
        }
      } catch (error) {
        this.questionDetails.error = error.response
      } finally {
        this.questionDetails.loading = false
      }
    },
    pressEsc(event) {
      if (this.isEscKeyPressed(event)) {
        this.$emit('on-close')
      }
    },
    showDrawerProblems() {
      this.updateRouteQuery('openQuestionProblems', 'true')
    },
    hideDrawerProblems() {
      this.updateRouteQuery('openQuestionProblems', 'false')
    },
    updateQuestionDetails() {
      if (this.isTeacher) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      } else {
        this.fetchQuestionDetails()
      }
      this.canDispatchUpdateEvent = true
    },
    handleTooltip(row) {
      const { answerStatus } = row
      switch (answerStatus) {
        case 'CORRECT':
          return 'Resposta correta'
        case 'WRONG':
          return 'Resposta incorreta'
        default:
          return 'Resposta em branco'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer
  ::v-deep .drawer
    overflow: visible
    overflow-y: auto

  &__header
    +flex-center-start

    .sas-badge
      margin-left: $size-s

    &__hit
      margin-left: $size-xs
      font-size: $font-size-s

      &.--success
        color: $color-success-dark

      &.--danger
        color: $color-danger-dark

      &.--blank
        color: $color-ink-light
</style>
