<template>
  <div class="logos-chapter-container">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'LogosChapterContainer',
}
</script>

<style scoped lang="scss">
.logos-chapter-container {
  display: flex;
  flex-direction: column;
  margin-bottom: $size-m;

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-s;
  }
}
</style>
