import * as types from './types'

export default {
  [types.SET_SUBJECTS](state, subjects) {
    state.subjects = {
      ...state.subjects,
      ...subjects,
    }
  },
  [types.SET_SUBJECT](state, subject) {
    state.subject = {
      ...state.subject,
      ...subject,
    }
  },
  [types.SET_SUBJECT_LECTURES](state, subjectLectures) {
    state.subjectLectures = {
      ...state.subjectLectures,
      ...subjectLectures,
    }
  },
}
