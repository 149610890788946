<template>
  <div class="student-questions-title">
    <div class="student-questions-title__title">
      <skeleton-loader
        v-if="loading"
        width="100px"
        height="33px"
      />
      <h4 v-else>
        {{ $t('report.questionsDrawer.title') }}
      </h4>
    </div>
    <div
      class="student-questions-title__count"
    >
      <skeleton-loader
        v-if="loading"
        width="120px"
        height="22px"
      />
      <p v-else>
        {{ $tc(
          'report.questionsDrawer.questionsCount',
          questionsCount,
          { count: questionsCount }
        ) }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StudentDrawerQuestionsTitle',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    questionsCount: {
      type: Number,
      required: true,
    },
  },
}
</script>
<style lang="sass" scoped>
.student-questions-title
  padding: $size-xs $size-s $size-s

  +mq-s--mf
    padding: $size-xs $size-l $size-s

  &__title
    margin-bottom: $size-xxs

  &__count
    color: $color-ink-light
</style>
