<template>
  <div class="adaptive-selector-list">
    <div class="adaptive-selector-list__wrapper">
      <div
        v-for="(option, index) in options"
        :key="index"
        tabindex="0"
        :class="[
          'adaptive-selector-list__item',
          {
            '--active': selectedOption && isEqual(option, selectedOption),
            '--disabled': option && option.disabled,
          }
        ]"
        @click="selectOption(option)"
        @keyup.enter="selectOption(classroom)"
      >
        <slot
          name="option"
          :option="option"
        >
          {{ option }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: 'AdaptiveSelectorList',
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [ Object, String ],
      default: null,
    },
  },
  methods: {
    isEqual(value, other) {
      return isEqual(value, other)
    },
    selectOption(option) {
      this.$emit('select', option)
    },
  },
}
</script>

<style lang="sass" scoped>
.adaptive-selector-list
  &__wrapper
    overflow: auto
    +flex-center-start

  &__item
    border-radius: 10px
    cursor: pointer
    color: $color-ink-light
    flex-shrink: 0
    font-size: $font-size-heading-6-small
    padding: $size-xs $size-s
    +flex-center
    +space-inline($size-xs)
    +transition($speed-x-fast)

    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px $color-primary-light

    &.--active
      background: rgba(192, 217, 246, 0.5)
      color: $color-ink

    &.--disabled
      cursor: not-allowed
      opacity: 0.5

      &:focus
        box-shadow: none
</style>
