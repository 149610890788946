<template>
  <section class="assignment-section-layout">
    <header class="assignment-section-layout__container">
      <div>
        <h3 class="assignment-section-layout__title">
          {{ title }}
        </h3>
        <span class="assignment-section-layout__subtitle">
          {{ subtitle }}
        </span>
      </div>
      <slot name="header" />
    </header>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'AssignmentSectionLayout',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.assignment-section-layout {
  margin-bottom: $size-xl;

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-m;
  }
}
</style>
