<template>
  <div class="context-wrapper">
    <wrapper>
      <div class="context-wrapper__group-selection">
        <slot
          name="groupSelection"
          :groupSelection="contextData.groupPicker"
        />
      </div>
      <div class="context-wrapper__list">
        <slot
          name="assignmentsList"
          :assignmentsList="contextData.assignmentsList"
        />
      </div>
    </wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import updateRoute from 'App/mixins/updateRoute'

export default {
  name: 'ExtraActivityContextWrapper',
  mixins: [ updateRoute ],
  props: {
    selectedProperty: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'extraActivityProperties',
      'extraActivitiesDifficulties',
      'extraActivityAssignments',
      'user',
    ]),
    context() {
      return this.$route.params.context
    },
    contextData() {
      switch (this.context) {
        case 'year':
          return {
            groupPicker: {
              loading: this.extraActivityProperties.loading,
              data: this.extraActivityProperties.data,
            },
            assignmentsList: {
              loading: this.extraActivityProperties.loading
              || this.extraActivityAssignments.loading,
              data: this.extraActivityAssignments.data,
            },
          }

        case 'difficulty':
          return {
            groupPicker: {
              loading: this.extraActivitiesDifficulties.loading,
              data: this.extraActivitiesDifficulties.data,
            },
            assignmentsList: {
              loading: this.extraActivitiesDifficulties.loading
              || this.extraActivityAssignments.loading,
              data: this.extraActivityAssignments.data,
            },
          }

        default:
          return {}
      }
    },
    extraActivityId() {
      return parseInt(this.$route.params.extraActivityId, 10)
    },
    classroomId() {
      const { $route } = this

      return parseInt($route.query.classroomId, 10)
    },
  },
  watch: {
    selectedProperty() {
      if (this.selectedProperty) {
        this.fetchAssignments()
      }
    },
    context() {
      this.$emit('resetProperty')
      this.fetchProperties()
    },
    classroomId() {
      if (this.selectedProperty) {
        this.fetchAssignments()
      }
    },
  },
  created() {
    this.fetchProperties()
  },
  methods: {
    ...mapActions([
      'getExtraActivityProperties',
      'getExtraActivitiesDifficulties',
      'getExtraActivityAssignments',
    ]),
    fetchProperties() {
      if (this.context === 'year') {
        this.getExtraActivityProperties(this.extraActivityId)
      } else if (this.context === 'difficulty') {
        this.getExtraActivitiesDifficulties()
      }
    },
    async fetchAssignments() {
      const queryParams = [
        this.selectedProperty.type,
        this.selectedProperty.id,
      ]
      const { extraActivityId, classroomId } = this
      this.updateRouteQuery('property', this.selectedProperty.id)

      await this.getExtraActivityAssignments({
        extraActivityId,
        properties: queryParams,
        classroomId,
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.context-wrapper
  &__group-selection
    padding: $size-l 0

  &__list
    padding:
      bottom: $end-space
</style>
