<template>
  <div class="class-difficulties-cards">
    <box
      v-for="(row, index) in questions"
      :key="index"
      class="class-difficulties-cards__item"
      elevation="2"
      padding="0"
      @click.native="$emit('selectQuestion', {data: row, dataIndex: index})"
    >
      <div class="class-difficulties-cards__item__number">
        <span>
          {{ row.order }}
        </span>
      </div>
      <div class="class-difficulties-cards__item__question">
        <span
          v-if="mustShowSubject"
          class="class-difficulties-cards__item__question__content"
        >
          {{ row.content.name }}
        </span>
        <h6 class="class-difficulties-cards__item__question__statement">
          {{ truncateText(row.statement, statementMaxLength) }}
        </h6>
      </div>
      <div class="class-difficulties-cards__item__hit-rate">
        <span class="class-difficulties-cards__item__hit-rate__value">
          <b>{{ Math.round(row.hitRate) }}%</b>
        </span>
        <span class="class-difficulties-cards__item__hit-rate__label">
          {{
            $t('report.difficulties.hitsShortest')
          }}
        </span>
      </div>
    </box>
  </div>
</template>

<script>
import truncateText from 'App/utils/truncateText'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'

export default {
  name: 'ClassDifficultiesCards',
  mixins: [ subjectVisibilityToggle ],
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 45
        case 'small':
          return 65
        default:
          return 82
      }
    },
  },
  methods: { truncateText },
}
</script>

<style lang="scss" scoped>
.class-difficulties-cards {
  &__item {
    @include space-stack($size-s);
    @include flex-center-start;

    &__question,
    &__hit-rate {
      padding: $size-s;
    }

    &__number {
      width: $size-m;
      height: $size-m;
      background-color: transparentize($color-ink-lightest, 0.75);
      color: $color-ink-light;
      border-radius: $size-xs;
      @include flex-center;
      flex-shrink: 0;
      margin-left: $size-s;
    }

    &__question {
      flex-grow: 1;
      border-right: 1px solid transparentize($color-ink-lightest, 0.5);

      &__content {
        margin-bottom: $size-xs;
        color: $color-ink-light;
        display: inline-block;
      }
    }

    &__hit-rate {
      &__value {
        display: block;
      }

      &__label {
        color: $color-ink-light;
      }
    }
  }
}
</style>
