<template>
  <div class="popover-dropdown">
    <s-button
      class="popover-dropdown__button"
      variation="tertiary"
      :icon="icon"
      :icon-right="iconRight"
      :icon-left="iconLeft"
      :active="popoverOpen"
      @click.stop="openPopover"
    >
      {{ label }}
    </s-button>
    <transition name="slide-down">
      <box
        v-show="popoverOpen"
        ref="popover"
        elevation="4"
        :class="[
          'popover-dropdown__popover',
          `--${direction}`,
        ]"
      >
        <slot :closePopover="closePopover" />
      </box>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PopoverDropdown',
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: 'bottom-left',
      validator: (value) => value.match(
        /(top-right|top-left|bottom-right|bottom-left)/
      ),
    },
  },
  data() {
    return {
      popoverOpen: false,
    }
  },
  methods: {
    openPopover() {
      if (!this.popoverOpen) {
        this.popoverOpen = true
        document.body.addEventListener('click', this.closeOnClickOutside)
        this.$emit('openPopover')
      }
    },
    closePopover() {
      if (this.popoverOpen) {
        this.popoverOpen = false
        document.body.removeEventListener('click', this.closeOnClickOutside)
        this.$emit('closePopover')
      }
    },
    closeOnClickOutside(event) {
      if (!this.$refs.popover.$el.contains(event.target)) {
        this.closePopover()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.popover-dropdown
  position: relative

  &__popover
    position: absolute
    z-index: 11

    &.--bottom-right
      top: 100%
      left: 0

    &.--bottom-left
      top: 100%
      right: 0

    &.--top-right
      bottom: 100%
      left: 0

    &.--top-left
      bottom: 100%
      right: 0

+v-transition(slide-down)
  opacity: 0
  transform: translateY(-10px)
</style>
