var render = function render(){var _vm=this,_c=_vm._self._c;return _c('box',{class:[
    'box-card',
    'box',
    {
      ['light']: _vm.isLightTheme,
      ['dark']: _vm.isDarkTheme,
    },
    _vm.classes.box
  ],attrs:{"elevation-hover":"2","elevation":_vm.elevation,"background":_vm.background,"border-radius":_vm.$tokens.size_s,"padding":_vm.$tokens.size_s},nativeOn:{"click":function($event){return _vm.$emit('click')}}},[_c('h5',{class:['title', _vm.classes.title]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:['descriptionContainer', _vm.classes.descriptionContainer]},[_c('p',{class:['description', _vm.classes.description]},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{class:['footerContainer', _vm.classes.footerContainer]},[_c('div',{class:'footer'},[_vm._t("footer")],2),(_vm.image)?_c('div',[_c('img',{class:['image', _vm.classes.image],attrs:{"src":_vm.image,"alt":_vm.title}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }