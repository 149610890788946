<template>
  <div class="question-problem-item">
    <h6 class="question-problem-item__author">
      {{ authorName }}
    </h6>
    <div class="question-problem-item__body">
      <div class="question-problem-item__body__reasons">
        <span>
          {{ $t('questions.reportAProblem.reasons').toUpperCase() }}
        </span>
        <p class="question-problem-item__body__reasons__complaint-reasons">
          {{ formattedReasons }}
        </p>
      </div>
      <div class="question-problem-item__body__complaint-description">
        <p>{{ issue.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionProblemItem',
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorName() {
      const { issue } = this

      return issue.complaintAuthor
        ? this.$t('questions.reportAProblem.youReported')
        : issue.author.name
    },
    formattedReasons() {
      return this.issue.complaintTypes.map(
        (type) => this.$t(`questions.reportAProblem.complaintTypes.${type}`)
      ).join(' / ')
    },
  },
}
</script>

<style lang="sass" scoped>
.question-problem-item
  padding: $size-m 0

  &__author
    margin-bottom: $size-s

  &__body
    &__reasons
      margin-bottom: $size-m

      span
        display: block
        font-size: $font-size-xs
        color: #595b5f
        margin-bottom: $size-xxs

      p
        font-weight: 500
</style>
