import { isNil } from 'lodash'

export default {
  methods: {
    updateRouteQuery(param, value) {
      if (isNil(param) || isNil(value)) {
        console.error('updateRouteQuery: param or value is null/undefined', { param, value })

        return
      }

      if (this.$router) {
        const query = {
          ...this.$route.query,
          [param]: value,
        }
        const { name, params, fullPath } = this.$route
        const resolvedRoute = this.$router.resolve({
          name,
          params,
          query,
        })

        if (resolvedRoute.href !== fullPath) {
          this.$router.push({
            name,
            params,
            query,
          })
        }
      }
    },
    updateRouteParam(param, value) {
      if (this.$router) {
        const params = {
          ...this.$route.params,
          [param]: value,
        }
        const { name, query, fullPath } = this.$route
        const resolvedRoute = this.$router.resolve({
          name,
          params,
          query,
        })

        if (resolvedRoute.href !== fullPath) {
          this.$router.push({
            name,
            params,
            query,
          })
        }
      }
    },
    updateRouteQueryWithReplace(param, value) {
      if (this.$router) {
        const query = {
          ...this.$route.query,
        }

        if (isNil(value)) {
          delete query[param]
        } else {
          query[param] = value
        }

        const { name, params, fullPath } = this.$route
        const resolvedRoute = this.$router.resolve({
          name,
          params,
          query,
        })

        if (resolvedRoute.href !== fullPath) {
          this.$router.replace({
            name,
            params,
            query,
          })
        }
      }
    },

  },
}
