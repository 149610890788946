import apiBff from '@/service/apiBff'
import { featureToggles } from './featureToggles'

async function getToggleApi(toggleName, userId) {
  try {
    const { data } = await apiBff.get(`/toggle/${toggleName}?userId=${userId}`)

    return data
  } catch {
    return { isEnabled: false }
  }
}

export async function getToggles(userData) {
  const allToggles = {}

  await Promise.all(
    featureToggles.map(async (flag) => {
      const param = userData[flag.userData]
      allToggles[flag.storeName] = await getToggleApi(flag.toggleName, param)
    })
  )

  return Object.fromEntries(
    featureToggles.map(({ storeName }) => [[ storeName ], allToggles[storeName].isEnabled ])
  )
}
