import cookies from '@/service/cookies'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  user: {},
  token: cookies.getItem('token') || null,
  userLoading: false,
  reallocationDateLimit: {
    loading: false,
    data: null,
    error: null,
  },
  yearSelectedContent: null,
  personalData: {},
  personalDataLoading: false,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
