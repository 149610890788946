<template>
  <div class="drawer-navigator">
    <header class="drawer-navigator__header">
      <slot name="header" />
      <div class="drawer-navigator__header__controls">
        <template v-if="!hideNavigation">
          <div
            v-if="mq_m__mf"
            class="drawer-navigator__header__controls__previous"
          >
            <s-button
              icon="arrow-left"
              variation="tertiary"
              :disabled="!hasPrevious"
              @click="$emit('previous')"
            />
          </div>
          <div
            v-if="mq_m__mf"
            class="drawer-navigator__header__controls__next"
          >
            <s-button
              icon="arrow-right"
              variation="tertiary"
              :disabled="!hasNext"
              @click="$emit('next')"
            />
          </div>
        </template>
        <template v-if="!hideMoreOptions">
          <CustomDropdown
            origin="bottom-right"
            variation="tertiary"
            icon="more-horizontal"
            class="drawer-navigator__header__controls__dropdown --border-left"
            @click.stop
          >
            <CustomDropdownItem
              icon-left="flag"
              :disabled="disabledReport"
              @click.stop="isReportProblemVisible = true"
            >
              {{ $t('questions.reportAProblem.buttonLabel') }}
            </CustomDropdownItem>
          </CustomDropdown>
        </template>
        <div class="drawer-navigator__header__controls__close --border-left">
          <s-button
            icon="x"
            variation="tertiary"
            @click="$emit('close')"
          />
        </div>
      </div>
    </header>
    <slot name="child" />
    <ReportProblem
      v-if="isReportProblemVisible"
      @close="isReportProblemVisible = false"
      @report-succeed="$emit('report-succeed')"
    />
  </div>
</template>

<script>
import mediaQueries from 'App/mixins/mediaQueries'
import ReportProblem from 'App/components/ReportProblem/ReportProblem'
import CustomDropdown from 'App/components/CustomDropdown'
import CustomDropdownItem from 'App/components/CustomDropdownItem'

export default {
  name: 'DrawerNavigator',
  components: {
    CustomDropdown,
    CustomDropdownItem,
    ReportProblem,
  },
  mixins: [ mediaQueries ],
  props: {
    hasNext: {
      type: Boolean,
      default: false,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    hideMoreOptions: {
      type: Boolean,
      default: false,
    },
    disabledReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReportProblemVisible: false,
    }
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="sass" scoped>
.drawer-navigator
  &__header
    padding: $size-s
      left: $size-l
    box-shadow: 0 1px 2px rgba(52, 60, 88, 0.2)
    +flex-space-between

    &__controls
      +flex-center

      &__previous
        padding:
          right: $size-xs

      &__next
        padding:
          left: $size-xs
          right: $size-xs

      &__close
        padding:
          left: $size-xs

      &__dropdown
        padding: 0 $size-xs

.--border-left
  border-left: 1px solid rgba($color-ink-lightest, 0.5)
</style>
