<template>
  <div class="question-drawer-complaint">
    <Alert
      :message="$t('report.questionsDrawer.seeQuestionProblemsDetails')"
      type="warning"
      icon="flag"
      @click="$emit('show-problems')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import mediaQueries from 'App/mixins/mediaQueries'
import Alert from '../Alert'

export default {
  name: 'QuestionDrawerComplaint',
  components: { Alert },
  mixins: [ mediaQueries ],
  props: {
    complaint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([ 'user' ]),
    formattedComplaints() {
      return this.complaint.complaintTypes.map(
        (type) => this.$t(`questions.reportAProblem.complaintTypes.${type}`)
      ).join(' / ')
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-complaint
  padding: $size-m
  border-bottom: 1px solid $color-ink-lightest

  &__header
    margin-bottom: $size-s

    .sas-badge
      color: #794C00

  &__body
    &__complaint-filters
      margin-bottom: $size-m

      span:first-child
        display: block
        color: $color-ink-light
        margin-bottom: $size-xxs

      p
        font-weight: 500
</style>
