<template>
  <div class="students-view-cards">
    <box
      v-for="index in 5"
      :key="index"
      class="students-view-cards__item"
      elevation="2"
      padding="0"
    >
      <div class="students-view-cards__item__student">
        <div class="students-view-cards__item__student__name">
          <skeleton-loader
            width="100%"
            height="22px"
          />
        </div>
        <div class="students-view-cards__item__student__status">
          <skeleton-loader
            width="100px"
            height="22px"
            border-radius="22px"
          />
        </div>
        <div class="students-view-cards__item__student__finished-at">
          <skeleton-loader
            width="54px"
            height="19px"
          />
        </div>
      </div>
      <div class="students-view-cards__item__hit-rate">
        <div class="students-view-cards__item__hit-rate__value">
          <skeleton-loader
            width="30px"
            height="19px"
          />
        </div>
        <div class="students-view-cards__item__hit-rate__label">
          <skeleton-loader
            width="46px"
            height="19px"
          />
        </div>
      </div>
    </box>
  </div>
</template>

<script>
export default {
  name: 'StudentsViewCardsSkeleton',
}
</script>

<style lang="sass" scoped>
.students-view-cards
  &__item
    +space-stack($size-s)
    +flex-center-start

    &__student
      +flex-center-start
      border-right: 1px solid transparentize($color-ink-lightest, 0.5)
      flex-grow: 1
      flex-wrap: wrap
      padding: $size-s

      &__name
        flex: 1 0 100%
        margin-bottom: $size-xxs

      &__status
        margin-right: $size-s

      &__finished-at
        display: inline-block

    &__hit-rate
      min-width: 62px
      padding: $size-xs

      &__value
        margin-bottom: 2px
</style>
