import http from '@/service/http'
import { mountByArray } from 'App/utils/mountQueryParams'
import * as types from './types'

export default {
  getExtraActivities: ({ commit }, gradeId) => {
    commit(types.SET_EXTRA_ACTIVITIES, { loading: true })

    return http.get('extra-activities/', {
      params: {
        gradeId,
      },
    })
      .then((response) => {
        commit(types.SET_EXTRA_ACTIVITIES, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_EXTRA_ACTIVITIES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getExtraActivity: ({ commit }, extraActivityId) => {
    commit(types.SET_EXTRA_ACTIVITY, { loading: true })

    return http.get(`extra-activities/${extraActivityId}`)
      .then((response) => {
        commit(types.SET_EXTRA_ACTIVITY, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_EXTRA_ACTIVITY, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setExtraActivity: ({ commit }, extraActivity) => {
    commit(types.SET_EXTRA_ACTIVITY, {
      data: extraActivity,
    })
  },
  getExtraActivityProperties: ({ commit }, extraActivityId) => {
    commit(types.SET_EXTRA_ACTIVITY_PROPERTIES, { loading: true })

    return http.get(`extra-activities/${extraActivityId}/properties`)
      .then((response) => {
        commit(types.SET_EXTRA_ACTIVITY_PROPERTIES, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_EXTRA_ACTIVITY_PROPERTIES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getExtraActivitiesDifficulties: ({ commit }) => {
    commit(types.SET_EXTRA_ACTIVITIES_DIFFICULTIES, { loading: true })

    return http.get('extra-activities/difficulty-levels')
      .then((response) => {
        commit(types.SET_EXTRA_ACTIVITIES_DIFFICULTIES, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_EXTRA_ACTIVITIES_DIFFICULTIES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getExtraActivityAssignments: (
    { commit }, { extraActivityId, properties, classroomId }
  ) => {
    commit(types.SET_EXTRA_ACTIVITY_ASSIGNMENTS, { loading: true })

    const params = { properties: mountByArray(properties) }

    if (classroomId) {
      params.classroomId = classroomId
    }

    return http.get(
      `extra-activities/${extraActivityId}/questionnaires`,
      { params }
    )
      .then((response) => {
        commit(types.SET_EXTRA_ACTIVITY_ASSIGNMENTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_EXTRA_ACTIVITY_ASSIGNMENTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
}
