<template>
  <div class="report-section-layout">
    <div class="report-section-layout__header">
      <slot name="header">
        <div class="report-section-layout__header">
          <div class="report-section-layout__header__title">
            <skeleton-loader
              v-if="loading"
              width="350px"
              height="38px"
            />
            <h3 v-else>
              {{ title }}
            </h3>
          </div>
          <div class="report-section-layout__header__subtitle">
            <skeleton-loader
              v-if="loading"
              width="100px"
              height="22px"
            />
            <p v-else>
              {{ subtitle }}
            </p>
          </div>
        </div>
      </slot>
    </div>
    <div
      v-if="hasBarSlot"
      class="report-section-layout__top-bar"
    >
      <slot name="top-bar" />
    </div>
    <div class="report-section-layout__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSectionLayout',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasBarSlot() {
      return Object.keys(this.$slots).includes('top-bar')
    },
  },
}
</script>

<style lang="sass" scoped>
.report-section-layout
  margin-bottom: $size-l

  &__header
    margin-bottom: $size-m

    &__title
      margin-bottom: $size-s

    &__subtitle
      color: $color-ink-light

  &__top-bar
    margin-bottom: $size-s
</style>
