<template>
  <div
    :class="['alert',`--${type}`]"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      class="alert__icon"
    >
      <icon :type="icon" />
    </span>
    <p
      class="alert__message"
      v-html="message"
    />
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(info|danger|warning)/),
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.alert
  padding: $font-size-xs
  display: flex
  align-items: center
  justify-content: flex-start
  font-size: $font-size-s
  border-radius: $font-size-xs
  cursor: pointer

  &__icon
    margin-right: $font-size-xs
    height: $size-m

.--info
  background: rgba(192, 217, 246, 0.5)

  .alert__icon
    color: #034DA4

  .alert__message
    color: #02346E

.--danger
  background: $color-danger-lightest

  .alert__icon
    color: $color-danger-darker

  .alert__message
    color: $color-danger-darker

.--warning
  background: $color-warning-lightest

  .alert__icon
    color: $color-warning-darker

  .alert__message
    color: $color-warning-darker
</style>
