<template>
  <div class="empty-state">
    <div class="empty-state__image">
      <img
        :src="src"
        class="empty-state__icon"
        alt="icon video empty state"
      >
    </div>
    <div class="empty-state__content">
      <h4 v-html="title" />
      <div class="empty-state__text">
        <p v-html="description" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    image: {
      type: String,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: true,
    },
    description: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    src() {
      const images = require.context('App/assets/', false, /\.svg$/)

      return images(`./${this.image}.svg`)
    },
  },
  methods: {
    getBack() {
      this.$router.go(-1)
      this.$emit('getBack')
    },
  },
}
</script>

<style lang="sass" scoped>
.empty-state
  display: flex
  align-items: center
  flex-direction: column
  padding: 24px
  text-align: center

  &__icon
    height: 250px
    padding: 12px 0

    +mq_s
      height: 200px

  h4
    margin-top: 15px
    font-size: 24px

  &__text
    padding: 12px 0
    margin: 10px 0

    p
      font-size: 16px
      color: $color-ink-light
</style>
