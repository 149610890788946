var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":_vm.mq_xl__mf ? 'fade' : 'slide-down'}},[_c('div',{staticClass:"teacher-onboarding"},[(_vm.mq_xl__mf)?_c('div',{staticClass:"teacher-onboarding__overlay"}):_vm._e(),_c('div',{staticClass:"teacher-onboarding__content"},[(_vm.mq_m)?_c('button',{staticClass:"skip",on:{"click":_vm.skip}},[_c('Icon',{attrs:{"type":"x"}}),_c('transition',{attrs:{"name":"shrink-text"}},[(_vm.slide === 0)?_c('p',{staticClass:"skip__text"},[_vm._v(" "+_vm._s(_vm.$t('commons.skipIntro'))+" ")]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"illustration"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.slide === 0)?_c('img',{key:"1",staticClass:"illustration__bg",attrs:{"src":require(
              `App/assets/onboarding-1-bg-${_vm.size}.svg`
            )}}):(_vm.slide === 1)?_c('img',{key:"2",staticClass:"illustration__bg",attrs:{"src":require(
              `App/assets/onboarding-2-bg-${_vm.size}.svg`
            )}}):(_vm.slide === 2)?_c('img',{key:"3",staticClass:"illustration__bg",attrs:{"src":require(
              `App/assets/onboarding-3-bg-${_vm.size}.svg`
            )}}):(_vm.slide === 3)?_c('img',{key:"4",staticClass:"illustration__bg",attrs:{"src":require(
              `App/assets/onboarding-4-bg-${_vm.size}.svg`
            )}}):(_vm.slide === 4)?_c('img',{key:"5",staticClass:"illustration__bg",attrs:{"src":require(
              `App/assets/onboarding-5-bg-${_vm.size}.svg`
            )}}):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.slide === 0)?_c('img',{key:"1",staticClass:"illustration__element slide-1",attrs:{"src":require(
              `App/assets/onboarding-1-element-${_vm.size}.svg`
            )}}):_vm._e(),(_vm.slide === 1)?_c('img',{key:"2",staticClass:"illustration__element",attrs:{"src":require(
              `App/assets/onboarding-2-element-${_vm.size}.svg`
            )}}):_vm._e(),(_vm.slide === 2)?_c('img',{key:"3",staticClass:"illustration__element",attrs:{"src":require(
              `App/assets/onboarding-3-element-${_vm.size}.svg`
            )}}):_vm._e(),(_vm.slide === 3)?_c('img',{key:"4",staticClass:"illustration__element",attrs:{"src":require(
              `App/assets/onboarding-4-element-${_vm.size}.svg`
            )}}):_vm._e(),(_vm.slide === 4)?_c('img',{key:"5",staticClass:"illustration__element",attrs:{"src":require(
              `App/assets/onboarding-5-element-${_vm.size}.svg`
            )}}):_vm._e()])],1),_c('div',{staticClass:"body"},[_c('transition',{attrs:{"name":"slide-badge"}},[(_vm.slide === _vm.max - 1)?_c('SBadge',{staticClass:"body__new",attrs:{"size":"large","variation":"success"}},[_vm._v(" "+_vm._s(_vm.$t('commons.new'))+" ")]):_vm._e()],1),_c('div',{staticClass:"body__text"},[_c('h3',{staticClass:"body__text__title"},[_vm._v(" "+_vm._s(_vm.$t(`onBoarding.${_vm.slide + 1}.title`))+" ")]),_c('p',{staticClass:"body__text__description"},[_vm._v(" "+_vm._s(_vm.$t(`onBoarding.${_vm.slide + 1}.description`))+" ")])]),_c('SlideBullets',{attrs:{"total":_vm.max,"current":_vm.slide}}),_c('div',{staticClass:"body__controls"},[(_vm.mq_l__mf)?_c('SButton',{attrs:{"size":"large","variation":"tertiary","icon-left":"x"},on:{"click":_vm.skip}},[_vm._v(" "+_vm._s(_vm.$t('commons.skipIntro'))+" ")]):_vm._e(),_c('div',{staticClass:"body__controls__primary"},[_c('TeacherOnBoardingFeedback'),_c('SButton',{staticClass:"body__controls__action",attrs:{"size":"large"},on:{"click":_vm.action}},[_vm._v(" "+_vm._s(_vm.$t(`onBoarding.${_vm.slide + 1}.action`))+" ")])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }