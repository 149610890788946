<template>
  <div class="question-drawer-body">
    <header class="question-drawer-body__header">
      <div class="question-drawer-body__header__title">
        <skeleton-loader
          width="100px"
          height="33px"
        />
      </div>
      <div class="question-drawer-body__header__skill">
        <skeleton-loader
          width="90px"
          height="28px"
          border-radius="28px"
        />
      </div>
    </header>
    <section class="question-drawer-body__statement">
      <div class="question-drawer-body__statement__line">
        <skeleton-loader
          width="90%"
          height="18px"
        />
      </div>
      <div class="question-drawer-body__statement__line">
        <skeleton-loader
          width="95%"
          height="18px"
        />
      </div>
      <div class="question-drawer-body__statement__line">
        <skeleton-loader
          width="92%"
          height="18px"
        />
      </div>
      <div class="question-drawer-body__statement__line">
        <skeleton-loader
          width="98%"
          height="18px"
        />
      </div>
    </section>
    <ul class="question-drawer-body__options">
      <li class="question-drawer-body__options__item">
        <div class="question-drawer-body__options__item__key">
          <skeleton-loader
            width="30px"
            height="30px"
            border-radius="30px"
          />
        </div>
        <div class="question-drawer-body__options__item__text">
          <skeleton-loader
            width="130px"
            height="18px"
          />
        </div>
      </li>
      <li class="question-drawer-body__options__item">
        <div class="question-drawer-body__options__item__key">
          <skeleton-loader
            width="30px"
            height="30px"
            border-radius="30px"
          />
        </div>
        <div class="question-drawer-body__options__item__text">
          <skeleton-loader
            width="110px"
            height="18px"
          />
        </div>
      </li>
      <li class="question-drawer-body__options__item">
        <div class="question-drawer-body__options__item__key">
          <skeleton-loader
            width="30px"
            height="30px"
            border-radius="30px"
          />
        </div>
        <div class="question-drawer-body__options__item__text">
          <skeleton-loader
            width="130px"
            height="18px"
          />
        </div>
      </li>
      <li class="question-drawer-body__options__item">
        <div class="question-drawer-body__options__item__key">
          <skeleton-loader
            width="30px"
            height="30px"
            border-radius="30px"
          />
        </div>
        <div class="question-drawer-body__options__item__text">
          <skeleton-loader
            width="90px"
            height="18px"
          />
        </div>
      </li>
    </ul>
    <section class="question-drawer-body__explanation">
      <div class="question-drawer-body__explanation__title">
        <skeleton-loader
          width="100px"
          height="33px"
        />
      </div>
      <div class="question-drawer-body__explanation__line">
        <skeleton-loader
          width="90%"
          height="18px"
        />
      </div>
      <div class="question-drawer-body__explanation__line">
        <skeleton-loader
          width="95%"
          height="18px"
        />
      </div>
      <div class="question-drawer-body__explanation__line">
        <skeleton-loader
          width="80%"
          height="18px"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'QuestionDrawerBodySkeleton',
}
</script>

<style lang="sass" scoped>
.question-drawer-body
  padding-bottom: $end-space

  &__header
    padding: $size-l $size-l 0
    margin-bottom: $size-m
    +flex-space-between

  &__statement
    padding: 0 $size-l
    margin-bottom: $size-m

    &__line
      margin-bottom: $size-xs

  &__options
    padding: 0 $size-l
    margin-bottom: $size-xl

    &__item
      margin-bottom: $size-s
      +flex-center-start

      &__key
        margin-right: $size-s

  &__explanation
    padding: $size-l
    border-top: 1px solid $color-ink-lightest

    &__title
      margin-bottom: $size-s

    &__line
      margin-bottom: $size-xs
</style>
