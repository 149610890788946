<template>
  <div class="student-drawer-questions-table">
    <s-table
      ref="questionsTable"
      :content="questions"
      :selected-row="activeQuestion"
      :default-sort="defaultSort"
      :fields="fields"
      @click-row="clickRow"
    >
      <template
        slot="number"
        slot-scope="{ row }"
      >
        <h6 class="student-drawer-questions-table__number">
          {{ $t(
            'report.questionsDrawer.questionNumber',
            { number: row.number }
          ) }}
        </h6>
        <span class="student-drawer-questions-table__statement">
          {{ row.statement }}
        </span>
      </template>
      <template
        v-if="mustShowSubject"
        slot="content"
        slot-scope="{ row }"
      >
        <span class="student-drawer-questions-table__content">
          {{ row.content.name }}
        </span>
      </template>
      <template
        slot="level"
        slot-scope="{ row }"
      >
        <span class="student-drawer-questions-table__level">
          {{ $t(
            `levels.${row.level}`
          ) }}
        </span>
      </template>
      <template
        slot="correct"
        slot-scope="{ row }"
      >
        <div class="student-drawer-questions-table__hit">
          <AnswerStatusBadge
            :row="row"
            :tooltip="handleTooltip(row)"
          />
        </div>
      </template>
    </s-table>
  </div>
</template>
<script>
import mediaQueries from 'App/mixins/mediaQueries'
import badgeStyle from 'App/mixins/badgeStyle'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import STable from 'App/components/STable'
import answerStatusEnum from 'App/enums/answerStatus'
import AnswerStatusBadge from './components/AnswerStatusBadge'

export default {
  name: 'StudentDrawerQuestionsTable',
  components: {
    STable,
    AnswerStatusBadge,
  },
  mixins: [ mediaQueries, badgeStyle, subjectVisibilityToggle ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          value: 'number',
          text: this.$t('report.questionsDrawer.question'),
          sortable: true,
        },
        {
          value: 'level',
          text: this.$t('report.questionsDrawer.level'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'correct',
          text: this.$t('report.questionsDrawer.answer'),
          sortable: true,
        },
      ],
      defaultSort: {
        field: 'number',
        direction: 'asc',
      },
    }
  },
  created() {
    this.addContentFieldOnSecondPosition()
  },
  methods: {
    addContentFieldOnSecondPosition() {
      if (this.mustShowSubject) {
        const contentField = {
          value: 'content',
          text: this.$t('report.questionsDrawer.content'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        }

        this.fields.splice(1, 0, contentField)
      }
    },
    clickRow(event) {
      this.$emit('selectQuestion', {
        data: event.data,
        index: event.dataIndex,
      })
    },
    previousQuestion() {
      this.$refs.questionsTable.previous()
    },
    nextQuestion() {
      this.$refs.questionsTable.next()
    },
    handleTooltip(row) {
      const { answerStatus } = row
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'Resposta correta'
        case answerStatusEnum.WRONG:
          return 'Resposta incorreta'
        case answerStatusEnum.BLANK:
          return 'Resposta em branco'
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.student-drawer-questions-table
  padding: 0 $size-s $size-s

  +mq-s--mf
    padding: 0 $size-l $size-m

    &__number
      margin-bottom: $size-xxs

    &__statement
      color: $color-ink-light

  ::v-deep .sas-table__body tr
      cursor: pointer

</style>
