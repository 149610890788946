import * as types from './types'

export default {
  [types.SET_USER](state, { user }) {
    state.user = user
  },
  [types.SET_TOKEN](state, { token }) {
    state.token = token
  },
  [types.SET_USER_LOADING](state, loading) {
    state.userLoading = loading
  },
  [types.CLEAR_ALL](state) {
    state.user = {
      name: '',
    }
    state.token = null
    state.userLoading = false
  },
  [types.REALLOCATION_DATE_LIMIT](state, payload) {
    state.reallocationDateLimit = {
      ...state.reallocationDateLimit,
      ...payload,
    }
  },
  [types.SET_YEAR_SELECTED_CONTENT](state, year) {
    state.yearSelectedContent = year
  },
  [types.SET_PERSONAL_DATA](state, payload) {
    state.personalData = payload
  },
  [types.SET_PERSONAL_DATA_LOADING](state, loading) {
    state.personalDataLoading = loading
  },
}
