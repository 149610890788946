<template>
  <header class="students-view-header">
    <div class="students-view-header__title">
      <h3 v-if="!loading">
        {{ $t('report.studentsView.title') }}
      </h3>
      <skeleton-loader
        v-else
        width="350px"
        height="38px"
      />
    </div>
    <div class="students-view-header__subtitle">
      <p v-if="!loading">
        {{ $tc(
          'report.studentsView.studentsCount',
          studentsCount,
          { count: studentsCount },
        ) }}
      </p>
      <skeleton-loader
        v-else
        width="100px"
        height="22px"
      />
    </div>
  </header>
</template>

<script>

export default {
  name: 'StudentsViewHeader',
  props: {
    studentsCount: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.students-view-header
  margin-bottom: $size-m
  display: flex
  flex-direction: row
  align-items: center
  grid-gap: $size-s

  &__subtitle
    color: $color-ink-light

</style>
