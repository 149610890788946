<template>
  <div
    v-if="canShowExtraDetails"
    class="assignment-extra-details"
  >
    <div class="assignment-extra-details__assignment-details">
      <template v-if="hasSomeText(subtitle)">
        <skeleton-loader
          v-if="loading"
          width="120px"
          height="25px"
        />
        <h6
          v-else
          class="assignment-extra-details__assignment-details__subtitle"
        >
          {{ subtitle }}
        </h6>
      </template>

      <template v-if="hasValidBadge">
        <s-badge :variation="badge.variation">
          {{ badge.text }}
        </s-badge>
      </template>

      <template v-if="hasSomeText(title)">
        <skeleton-loader
          v-if="loading"
          width="50%"
          height="27px"
        />
        <h5
          v-else
          class="assignment-extra-details__assignment-details__title"
        >
          {{ title }}
        </h5>
      </template>
    </div>
    <div
      v-if="hasSomeText(startedTime) || hasSomeText(finishedTime)"
      class="assignment-extra-details__time-details"
    >
      <template v-if="hasSomeText(startedTime)">
        <skeleton-loader
          v-if="loading"
          width="160px"
          height="22px"
        />
        <span
          v-else
          class="assignment-extra-details__time-details__started-time"
        >
          {{ startedTime }}
        </span>
      </template>
      <template v-if="hasSomeText(finishedTime)">
        <skeleton-loader
          v-if="loading"
          width="170px"
          height="22px"
        />
        <span
          v-else
          class="assignment-extra-details__time-details__finished-time"
        >
          {{ finishedTime }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import isEmpty from 'App/utils/isEmpty'

export default {
  name: 'ExtraDetails',
  props: {
    loading: Boolean,
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    startedTime: {
      type: String,
      default: '',
    },
    finishedTime: {
      type: String,
      default: '',
    },
    badge: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    canShowExtraDetails() {
      const {
        title,
        subtitle,
        startedTime,
        finishedTime,
        badge,
      } = this

      return !isEmpty(title)
        || !isEmpty(subtitle)
        || !isEmpty(startedTime)
        || !isEmpty(finishedTime)
        || !isEmpty(badge)
    },
    hasValidBadge() {
      return !isEmpty(this.badge)
    },
  },
  methods: {
    hasSomeText(text) {
      return !isEmpty(text)
    },
  },
}
</script>

<style lang="scss">
.assignment-extra-details {
  padding: $size-s;
  display: flex;
  flex-wrap: wrap;

  @include mq-s--mf {
    padding: $size-m $size-l;
  }

  @include mq-m--mf {
    flex-wrap: nowrap;
  }

  &__assignment-details {
    flex-grow: 1;
    @include flex-center-start;
    flex-wrap: wrap;

    &__subtitle {
      color: $color-ink-light;
      margin-right: $size-s;
    }

    &__title {
      flex-basis: 100%;
      margin: $size-xs 0 $size-s;
    }
  }

  &__time-details {
    flex-shrink: 1;
    white-space: nowrap;

    @include mq-m--mf {
      text-align: right;
    }

    &__started-time,
    &__finished-time {
      color: $color-ink-light;
      display: block;
    }

    &__started-time {
      margin-bottom: $size-xs;
    }

    ::v-deep .sas-skeleton-loader {
      display: inline-block;
    }
  }
}

</style>
