<template>
  <div class="share-url">
    <input
      ref="url"
      class="sas-input__field --medium"
      type="text"
      readonly
      :value="url"
    >
    <s-button
      v-tooltip.top="{
        content: $t('share.copied'),
        trigger: 'manual',
        show: copiedTooltipOpen,
      }"
      size="large"
      icon-left="copy"
      :loading="loading"
      @click="copyToClipboard"
    >
      {{ $t('share.copy') }}
    </s-button>
  </div>
</template>

<script>
export default {
  name: 'ShareUrl',
  props: {
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copiedTooltipOpen: false,
    }
  },
  methods: {
    async copyToClipboard() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.url)
      } else {
        const urlInput = this.$refs.url
        urlInput.focus()
        urlInput.select()
        document.execCommand('copy')
      }
      this.copiedTooltipOpen = true
      setTimeout(() => {
        this.copiedTooltipOpen = false
      }, 1500)
    },
  },
}
</script>

<style lang="sass" scoped>
.share-url
  display: flex
  flex-direction: column
  width: 100%

  +mq-s--mf
    flex-direction: row
    justify-content: center

    .sas-button
        border-top-left-radius: 0
        border-bottom-left-radius: 0

  .sas-button
    padding: 0 $size-m

  .sas-input__field
    margin-bottom: $size-xs
    background: rgba($color-ink-lightest, 0.25)
    height: $element-size-l
    font-size: $font-size-m

    +mq-s--mf
      margin-bottom: 0
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      min-width: 320px
</style>
