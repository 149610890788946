var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"delete-confirmation-modal",attrs:{"close-on-click-outside":false,"close-button-enabled":false,"footer-divider-enabled":false,"padding":{
    header: 0,
    body: `${_vm.$tokens.size_m} ${_vm.$tokens.size_l} ${_vm.$tokens.size_xs}`,
    footer: `0 ${_vm.$tokens.size_l} ${_vm.$tokens.size_m}`,
  }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('s-button',{attrs:{"size":"large","variation":"tertiary"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('myActivities.deleteConfirmation.cancel'))+" ")]),_c('s-button',{attrs:{"size":"large","variation":"danger","loading":_vm.loading},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.$t('myActivities.deleteConfirmation.confirm'))+" ")])]},proxy:true}])},[_c('h4',{staticClass:"delete-confirmation-modal__title",domProps:{"innerHTML":_vm._s(_vm.$t('myActivities.deleteConfirmation.title', {
      activity: _vm.activity.title,
    }))}}),_c('p',{staticClass:"delete-confirmation-modal__description",domProps:{"innerHTML":_vm._s(_vm.$t('myActivities.deleteConfirmation.description', {
      classroom: _vm.classroom,
    }))}}),_c('div',{staticClass:"delete-confirmation-modal__warning"},[_c('icon',{staticClass:"delete-confirmation-modal__warning__icon",attrs:{"type":"alert-triangle"}}),_c('p',{staticClass:"delete-confirmation-modal__warning__text"},[_vm._v(" "+_vm._s(_vm.$t('myActivities.deleteConfirmation.warning'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }