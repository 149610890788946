<template>
  <drawer
    class="filter-drawer"
    background="white"
    position="bottom"
    size="100%"
  >
    <header class="filter-drawer__header">
      <h4>
        {{ $t('report.filter') }}
      </h4>
      <s-button
        icon="x"
        variation="tertiary"
        @click="$emit('close')"
      />
    </header>
    <div class="filter-drawer__content">
      <h5 class="filter-drawer__content__heading">
        {{ $t('report.questionsView.level') }}
      </h5>
      <label
        v-for="(option, index) in levelOptions"
        :key="`filter${index}`"
        :for="option.value"
      >
        <input
          :id="option.value"
          v-model="levelFilterProxy"
          type="radio"
          name="level"
          :value="option"
        >
        {{ option.label }}
      </label>
      <h5 class="filter-drawer__content__heading">
        Ordenar por
      </h5>
      <label
        v-for="(option, index) in sortOptions"
        :key="`sort${index}`"
        :for="option.id"
      >
        <input
          :id="option.id"
          v-model="sortOptionProxy"
          type="radio"
          name="sort"
          :value="option"
        >
        {{ option.label }}
      </label>
    </div>
    <footer class="filter-drawer__footer">
      <s-button
        width="100%"
        size="large"
        @click="$emit('close')"
      >
        {{ $t('report.filter') }}
      </s-button>
    </footer>
  </drawer>
</template>

<script>
export default {
  name: 'FilterDrawer',
  props: {
    levelOptions: {
      type: Array,
      required: true,
    },
    levelFilterObject: {
      type: Object,
      required: true,
      validator: (level) => level.value && level.label,
    },
    sortOptions: {
      type: Array,
      required: true,
    },
    sortOptionObject: {
      type: Object,
      required: true,
      validator: (option) => option.value && option.label,
    },
  },
  computed: {
    levelFilterProxy: {
      get() {
        return this.levelFilterObject
      },
      set(value) {
        this.$emit('onLevelSelect', value)
      },
    },
    sortOptionProxy: {
      get() {
        return this.sortOptionObject
      },
      set(value) {
        this.$emit('onSortSelect', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-drawer {
  ::v-deep .drawer{
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
    @include flex-center-start;
    padding: $size-xs {
      right: $size-xxs;
      left: $size-s;
    }

    h4 {
      flex-grow: 1;
    }
  }

  &__content {
    flex-grow: 1;
    padding: $size-m $size-s;
  }

    &__heading {
      margin-bottom: $size-s;
    }

      &:nth-child(n+2) {
        margin-top: $size-l;
      }

    label {
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      display: block;
      padding: $size-xs;
      @include space-stack($size-xs);
    }

    [type=radio] {
      height: $size-m;
      margin-right: $size-xs;
      width: $size-m;
    }

  &__footer {
    box-shadow: 0 0 7px rgba($color-ink, .25);
    padding: $font-size-s;
  }
}
</style>
