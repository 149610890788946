import { render, staticRenderFns } from "./ClassDifficultiesCards.vue?vue&type=template&id=713ff5ad&scoped=true"
import script from "./ClassDifficultiesCards.vue?vue&type=script&lang=js"
export * from "./ClassDifficultiesCards.vue?vue&type=script&lang=js"
import style0 from "./ClassDifficultiesCards.vue?vue&type=style&index=0&id=713ff5ad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713ff5ad",
  null
  
)

export default component.exports