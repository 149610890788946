<template>
  <header class="class-difficulties-header">
    <template v-if="loading">
      <div class="class-difficulties-header__text">
        <skeleton-loader
          width="280px"
          height="38px"
          class="class-difficulties-header__text__title"
        />
        <skeleton-loader
          width="300px"
          height="22px"
          class="class-difficulties-header__text__subtitle"
        />
      </div>
      <template v-if="mq_l__mf">
        <skeleton-loader
          width="190px"
          height="36px"
        />
      </template>
    </template>
    <template v-else>
      <div class="class-difficulties-header__text">
        <h3 class="class-difficulties__header__text__title">
          {{ $t('report.difficulties.title') }}
        </h3>
        <p class="class-difficulties-header__text__subtitle">
          {{ $t('report.difficulties.subtitle') }}
        </p>
      </div>
      <s-button
        v-if="isHighSchool && !noQuestions"
        class="class-difficulties-header__view-all"
        icon-right="arrow-right"
        variation="tertiary"
        @click="$emit('viewAll')"
      >
        {{ $t('report.difficulties.viewAll') }}
      </s-button>
      <template v-if="mq_l__mf && exportButtonIsVisible">
        <s-button
          icon-left="download"
          :disabled="!canBeExported"
          :loading="downloading"
          @click="$emit('exportQuestions')"
        >
          {{ $t('report.difficulties.export.short') }}
        </s-button>
      </template>
    </template>
  </header>
</template>

<script>
import mediaQueries from 'App/mixins/mediaQueries'

export default {
  name: 'ClassDifficultiesHeader',
  mixins: [ mediaQueries ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    downloading: Boolean,
    canBeExported: Boolean,
    exportButtonIsVisible: Boolean,
  },
  computed: {
    isHighSchool() {
      if (!this.questionnaire.data) return false

      return this.questionnaire.data.isHighSchool
    },
  },
}
</script>

<style lang="scss" scoped>
.class-difficulties-header {
  margin-bottom: $size-m;

  @include mq-m--mf {
    @include flex-center-start;
  }

  &__view-all {
    @include mq-m--mf {
      margin-right: $size-s;
    }
  }

  &__text {
    margin-bottom: $size-s;
    flex-grow: 1;

    @include mq-m--mf {
      margin-bottom: 0;
    }

    &__subtitle {
      margin-top: $size-xxs;
      color: $color-ink-light;
    }
  }
}
</style>
