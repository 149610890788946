<template>
  <modal
    class="share-modal"
    :padding="$tokens.size_m"
    :hide-close-button="true"
    @close="$emit('close')"
  >
    <ShareForm
      :loading="shareUrlCache.loading"
      :url="shareUrlCache.data[questionnaireCode] || ''"
      @close="$emit('close')"
    />
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ShareForm from 'App/components/ShareForm'

export default {
  name: 'ShareModal',
  components: {
    ShareForm,
  },
  props: {
    questionnaireCode: {
      type: String,
      required: true,
    },
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'shareUrlCache',
    ]),
    gradeId() {
      return this.selectedClassroom?.grade?.id
    },
  },
  created() {
    const { questionnaireCode, gradeId } = this
    this.getShareUrl({ questionnaireCode, gradeId })
  },
  methods: {
    ...mapActions([
      'getShareUrl',
    ]),
  },
}
</script>

<style lang="sass" scoped>
.share-modal
  ::v-deep .sas-modal__header
    display: none
</style>
