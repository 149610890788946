var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,{tag:"component",class:[
    _vm.$style.avatar,
    {
      [_vm.$style.clicked]: _vm.selected,
      [_vm.$style.clickable]: _vm.enableSelect,
    },
  ],style:(_vm.style),attrs:{"aria-checked":_vm.selected},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.selected)?_c('Icon',{class:_vm.$style.checkIcon,attrs:{"size":"28","type":"check"}}):_vm._e(),(_vm.$scopedSlots.default)?_vm._t("default"):(!_vm.src)?_c('span',{class:_vm.$style.avatarText,attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.firstLetter))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }