<template>
  <component
    :is="componentType"
    :aria-checked="selected"
    :class="[
      $style.avatar,
      {
        [$style.clicked]: selected,
        [$style.clickable]: enableSelect,
      },
    ]"
    :style="style"
    @click="$emit('click')"
  >
    <Icon
      v-if="selected"
      :class="$style.checkIcon"
      size="28"
      type="check"
    />
    <slot v-if="$scopedSlots.default" />
    <span
      v-else-if="!src"
      aria-hidden="true"
      :class="$style.avatarText"
    >{{ firstLetter }}</span>
  </component>
</template>

<script>
import { Icon } from '@sas-te/alfabeto-vue'

export default {
  name: 'Avatar',
  components: { Icon },
  props: {
    enableSelect: Boolean,
    selected: Boolean,
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    firstLetter() {
      return this.title.substring(0, 1).toLocaleUpperCase()
    },
    componentType() {
      return this.enableSelect ? 'button' : 'div'
    },
    style() {
      if (!this.src) {
        return null
      }

      return {
        backgroundImage: `url(${this.src})`,
      }
    },
  },
}
</script>

<style lang="scss" module>
$size: 48px;
$size-desktop: 58px;

.avatar {
  @include flex-center();

  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;
  margin-right: $size-xs;
  font-size: $font-size-s;
  border-radius: $border-radius-circle;
  background: $color-ice;
  appearance: none;
  border: 0;
  background-size: cover;
  background-position: center;

  &:hover,
  &:focus {
    @include input-focus-outline();
  }

  @include mq-l--mf() {
    height: $size-desktop;
    width: $size-desktop;
    min-height: $size-desktop;
    min-width: $size-desktop;
    margin-right: 12px;
  }
}

.checkIcon {
  padding: 10px;
}

.clickable {
  cursor: pointer
}

.clicked {
  background-color: $color-primary;
  color: $color-white;

  .avatarText {
    display: none;
  }
}
</style>
