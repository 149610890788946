<template>
  <div>
    <label
      class="order-selector__label"
      for="order-selector"
    >
      Ordenador por
    </label>
    <AdaptiveSelector
      id="order-selector"
      :options="options"
      :selected-option="selectedOption.name"
      :threshold="0"
      @select="setSelectOption"
    >
      <template #option="{ option }">
        {{ option.name }}
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>

import AdaptiveSelector from 'App/components/AdaptiveSelector/AdaptiveSelector'

export default {
  name: 'OrderSelector',
  components: {
    AdaptiveSelector,
  },
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    options() {
      return [
        {
          id: 1,
          name: 'Mais recentes',
          order: 'desc',
        },
        {
          id: 2,
          name: 'Mais antigas',
          order: 'asc',
        },
      ]
    },
  },
  created() {
    this.setSelectOption(this.options[0])
  },
  methods: {
    setSelectOption(option) {
      this.selectedOption = option
      this.$emit('change-option', option)
    },
  },
}
</script>

<style lang="scss" scoped>
.order-selector {
  &__label {
    font-weight: $font-weight-regular;
    font-size: $font-size-s;
    line-height: line-height-heading;
    color: $color-ink-light;
  }
}

::v-deep .adaptive-selector-dropdown__handle {
  font-weight: $font-weight-regular;
  font-size: $font-size-m;
  line-height: $line-height-text;
  color: $color-ink;
}
</style>
