import http from '@/service/http'
import * as types from './types'

export default {
  getClassrooms: ({ commit }, contentYear = null) => {
    commit(types.SET_CLASSROOMS, {
      loading: true,
      data: null,
      error: null,
    })

    const params = {}

    if (contentYear) {
      params.contentYear = contentYear ?? new Date().getFullYear()
    }

    return http.get('classrooms', { params })
      .then((response) => {
        commit(types.SET_CLASSROOMS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_CLASSROOMS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setActiveClassroom: ({ commit }, classroom) => {
    commit(types.SET_ACTIVE_CLASSROOM, { data: classroom })
  },
}
