<template>
  <box
    :class="[
      'questions-cards__item',
      `--${variation}`
    ]"
    elevation="2"
    padding="0"
  >
    <div
      v-if="questionNumberIsVisible"
      class="questions-cards__item__number"
    >
      <span>
        {{ question.order }}
      </span>
    </div>
    <div class="questions-cards__item__question">
      <span
        v-if="mustShowSubject"
        class="questions-cards__item__question__content"
      >
        {{ question.content.name }}
      </span>
      <h6 class="questions-cards__item__question__statement">
        {{ truncateText(question.statement, statementMaxLength) }}
      </h6>
    </div>
    <div class="questions-cards__item__hit-rate">
      <span class="questions-cards__item__hit-rate__value">
        <b>{{
          question.hitRate !== null ? `${Math.round(question.hitRate)}%` : '-'
        }}</b>
      </span>
      <span
        v-if="question.hitRate !== null"
        class="questions-cards__item__hit-rate__label"
      >
        {{
          $t('report.difficulties.hitsShortest')
        }}
      </span>
    </div>
  </box>
</template>

<script>
import truncateText from 'App/utils/truncateText'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'

export default {
  name: 'QuestionsCard',
  mixins: [ subjectVisibilityToggle ],
  props: {
    question: {
      type: Object,
      required: true,
      validator: ({ statement, content, hitRate }) => (
        statement
        && content
        && content.name
        && (typeof hitRate === 'number' || hitRate === null)
      ),
    },
    variation: {
      type: String,
      default: 'neutral',
      validator: (value) => value.match(/(neutral|warning)/),
    },
    questionNumberIsVisible: Boolean,
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 16
        case 'small':
          return 28
        case 'medium':
          return 43
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: {
    truncateText,
  },
}
</script>

<style lang="scss" scoped>
.questions-cards__item {
  @include space-stack($size-s);
  @include flex-center-start;

  &.--warning {
    box-shadow: 0 0 0 2px #794C00;
  }

  &__number {
    width: $size-m;
    height: $size-m;
    background-color: transparentize($color-ink-lightest, 0.75);
    color: $color-ink-light;
    border-radius: $size-xs;
    @include flex-center;
    flex-shrink: 0;
    margin-left: $size-s;

    span {
      font-weight: 900;
    }

    .--warning & {
      background-color: $color-warning-lightest;

      span {
        color: $color-warning-darkest;
      }
    }
  }

  &__question{
    flex-grow: 1;
    border-right: 1px solid transparentize($color-ink-lightest, 0.5);
    padding: $size-s;
      left: 12px;

    &__content {
      margin-bottom: $size-xs;
      color: $color-ink-light;
      display: inline-block;
    }
  }

  &__hit-rate {
    min-width: $size-xxl;
    padding: $size-s;

    &__value {
      display: block;
    }

    &__label {
      color: $color-ink-light;
    }
  }
}
</style>
