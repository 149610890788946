import { isEmpty } from 'lodash'
import assessmentStatusEnum from '../enums/assessmentStatus'

export default function assessmentStatus(assessment) {
  if (isEmpty(assessment)) {
    return assessmentStatusEnum.NOT_STARTED
  }

  if (!isEmpty(assessment?.status)) {
    return assessmentStatusEnum[assessment.status]
  }

  if (isEmpty(assessment?.finishedAt)) {
    return assessmentStatusEnum.IN_PROGRESS
  }

  return assessmentStatusEnum.FINISHED
}
