<template>
  <div :class="['questions-view-table', `--${variation}`]">
    <s-table
      ref="sTableRef"
      :fields="fields"
      :content="questions"
      :selected-row="activeQuestion"
      :default-sort="defaultSort"
      :loading="loading"
      :skeleton-lines="5"
      @click-row="clickRow"
    >
      <template #number="{ row }">
        <skeleton-loader
          v-if="loading"
          width="24px"
          height="24px"
        />
        <div
          v-else
          class="questions-view-table__number"
        >
          <span>
            {{ row.order }}
          </span>
        </div>
      </template>
      <template #statement="{ row }">
        <div
          v-if="!loading"
          class="questions-view-table__question"
        >
          <span
            v-if="mustShowSubject"
            class="questions-view-table__question__content"
          >
            {{ row.content.name }}
          </span>
          <h6>
            {{ truncateText(row.statement, statementMaxLength) }}
          </h6>
        </div>
        <template v-else>
          <skeleton-loader
            class="questions-view-table__question__content"
            width="20%"
            height="19px"
          />
          <skeleton-loader
            width="270px"
            height="25px"
          />
        </template>
      </template>
      <template #level="{ row }">
        <div
          v-if="!loading"
          class="questions-view-table__level"
        >
          {{ $t(`levels.${row.level}`) }}
        </div>
        <skeleton-loader
          v-else
          width="60px"
          height="19px"
        />
      </template>
      <template #numberOfComplaints="{ row }">
        <template v-if="!loading">
          <p class="questions-view-table__quantity">
            <b>
              {{ row.numberOfComplaints }}
            </b>
          </p>
        </template>
        <template v-else>
          <div class="questions-view-table__hit-rate">
            <skeleton-loader
              width="34px"
              height="22px"
            />
          </div>
          <div class="questions-view-table__hit-count">
            <skeleton-loader
              width="100%"
              height="19px"
            />
          </div>
        </template>
      </template>
      <template #details>
        <template v-if="!loading">
          <s-button variation="secondary">
            {{ $t('commons.details') }}
          </s-button>
        </template>
        <template v-else>
          <skeleton-loader
            width="90px"
            height="36px"
          />
        </template>
      </template>
    </s-table>
  </div>
</template>

<script>
import truncateText from 'App/utils/truncateText'
import STable from 'App/components/STable'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'

export default {
  name: 'ReportedQuestionsTable',
  components: { STable },
  mixins: [ subjectVisibilityToggle ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
    variation: {
      type: String,
      default: 'neutral',
      validator: (value) => value.match(/(neutral|warning)/),
    },
    isClassroomActivity: Boolean,
  },
  data() {
    return {
      defaultSort: {
        field: 'numberOfComplaints',
        direction: 'desc',
      },
    }
  },
  computed: {
    fields() {
      const fields = [
        {
          value: 'statement',
          text: this.$t('report.questionsView.questionWithReportedProblem'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'level',
          text: this.$t('report.questionsView.level'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => {
            if (a === b) return 0
            if (a === 'easy') return -1 * modifier
            if (a === 'hard') return modifier
            if (a === 'medium' && b === 'easy') return modifier

            return -1 * modifier
          },
        },
        {
          value: 'numberOfComplaints',
          text: this.$t('report.questionsView.quantity'),
          sortable: true,
          width: '144px',
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ]

      if (!this.isClassroomActivity) {
        fields.unshift({ value: 'number', text: 'Nº', sortable: true })
      }

      return fields
    },
    statementMaxLength() {
      switch (this.$mq) {
        case 'large':
          return 80
        case 'x_large':
          return 100
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: {
    truncateText,
    clickRow({ data, dataIndex }) {
      this.$emit('selectQuestion', {
        question: data,
        index: dataIndex,
      })
    },
    previousQuestion() {
      this.$refs.sTableRef.previous()
    },
    nextQuestion() {
      this.$refs.sTableRef.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-view-table {
  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }

  &.--warning {
    ::v-deep .sas-table__header {
      background-color:  $color-warning-lightest;
      color: $color-warning-darker;
      font-weight: 900;
    }

    ::v-deep .sas-table thead tr:hover {
      background-color: transparentize($color-warning-lighter, 0.5);
    }
  }

  &__number {
    width: $size-m;
    height: $size-m;
    color: $color-ink-light;
    background: rgba(201, 204, 207, 0.3);
    border-radius: $size-xs;
    @include flex-center;

    span {
      font-weight: 900;
    }

    .--warning & {
      background-color: $color-warning-lightest;
    }

      span {
        color: $color-warning-darkest;
      }
  }

  &__question {
    &__content {
      color: $color-ink-light;
      margin-bottom: $size-xxs;
    }
  }

  &__level {
    color: $color-ink-light;
    font-size: $font-size-s;
  }

  &__hit-rate {
    margin-bottom: $size-xxs;
  }

  &__hit-count {
    color: $color-ink-light;
  }
}
</style>
