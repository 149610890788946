<template>
  <main class="app-main">
    <div
      v-if="!isMFE"
      class="app-main__menu"
    >
      <transition name="menu">
        <SMenu />
      </transition>
    </div>
    <div class="app-main__content">
      <div
        v-if="lectures.loading"
        class="app-main__loading"
      >
        <LoadingSpinner />
      </div>
      <router-view />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

import LoadingSpinner from 'App/components/LoadingSpinner'
import { SMenu } from '@sas-te/main-menu-vue'

export default {
  name: 'BaseLayout',
  components: {
    SMenu,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters([
      'lectures',
    ]),
    isMFE() {
      return window.eureka_report_init
    },
    trackingProductCode: () => parseInt(
      process.env.VUE_APP_TRACKING_PRODUCT_CODE,
      10,
    ),
  },
  created() {
    this.$setTitle(this.$t('app.title'))
  },
}
</script>

<style lang="sass">
body
  padding: 0
  margin: 0
  background: $color-ice

.app-main
  &__loading
    background: $color_ice
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - #{$menu-height})
    position: fixed
    top: $menu-height
    left: 0
    z-index: 9

  &__menu
    margin-bottom: $menu-height

    .content-menu
      z-index: 10
</style>
