<template>
  <div
    ref="dropdown"
    class="custom-dropdown"
    tabindex="0"
    @keyup.esc="hideMenu()"
  >
    <s-button
      class="custom-dropdown__button"
      :class="[
        status ? `--${status}` : '',
        !menuVisible ? 'closed' : 'opened'
      ]"
      :icon="text.length === 0 ? icon : null"
      :icon-right="text.length ? icon : null"
      :variation="variation"
      :disabled="disabled"
      :size="size"
      :width="text.length ? width : null"
      @click="toggleMenu($event)"
    >
      {{ text }}
    </s-button>

    <dropdown-menu
      v-if="menuVisible"
      :width="menuWidth"
      :origin="origin"
    >
      <slot />
    </dropdown-menu>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => value.match(/(small|medium|large)/),
    },
    variation: {
      type: String,
      default: 'secondary',
      required: false,
      validator: (value) => value.match(/(primary|secondary|tertiary|danger)/),
    },
    status: {
      type: String,
      default: null,
      required: false,
      validator: (value) => value.match(/(success|error|warning)/),
    },
    width: {
      type: String,
      default: '100%',
      required: false,
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'chevron-down',
      required: false,
    },
    menuWidth: {
      type: String,
      default: null,
      required: false,
    },
    origin: {
      type: String,
      default: 'bottom-right',
      required: false,
      validator: (value) => value.match(
        /(top-right|top-left|bottom-right|bottom-left)/
      ),
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
      required: false,
    },
    open: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  watch: {
    open(open) {
      this.menuVisible = open
    },
    menuVisible(menuVisbile) {
      this.$emit('toggle', menuVisbile)

      if (this.closeOnClickOutside) {
        if (menuVisbile) {
          document.addEventListener('click', this.clickOutsideHandler, true)
        } else {
          document.removeEventListener('click', this.clickOutsideHandler, true)
        }
      }
    },
  },
  methods: {
    toggleMenu($event) {
      this.$emit('click', $event)
      this.menuVisible = !this.menuVisible

      if (!this.menuVisible) {
        this.$emit('blur')
      }
    },
    openMenu() {
      this.menuVisbile = true
    },
    hideMenu() {
      this.menuVisible = false
      this.$emit('blur')
    },
    clickOutsideHandler(event) {
      if (!this.$el.contains(event.target) && this.menuVisible) {
        this.hideMenu()
      }
    },
  },
}
</script>

<style lang="sass">
.custom-dropdown
  display: inline-block
  position: relative
  width: max-content
  &:focus
    outline: 0

  &.--loading
    cursor: progress
    pointer-events: none

    .sas-button__icon
      opacity: 0

  &__spinner
    position: absolute
    right: 8px
    bottom: 6px
    z-index: 100
</style>
