import actions from './actions'
import mutations from './mutations'
import { featureTogglesInitialState } from './utils/featureToggles'

const state = {
  toggles: featureTogglesInitialState,
}

export default {
  state,
  mutations,
  actions,
}
