<template>
  <modal
    class="redirect-modal"
    :width="mq_m ? '90vw' : '700px'"
    :close-on-click-outside="false"
    :close-button-enabled="false"
    :padding="{ header: 0, body: 0, footer: 0 }"
  >
    <img
      class="redirect-modal__image"
      :src="imageSource"
    >
    <h4 class="redirect-modal__title">
      {{ title }}
    </h4>
    <p class="redirect-modal__description">
      {{ description }}
    </p>
    <Spinner
      color="#8CD3AE"
      stroke-width="5"
      custom-size="64px"
    />
  </modal>
</template>

<script>
import querystring from 'querystring'

import mediaQueries from 'App/mixins/mediaQueries'
import { urls } from 'shared/utils/urls'

export default {
  name: 'RedirectToCQ',
  mixins: [ mediaQueries ],
  props: {
    classroom: {
      type: Object,
      required: true,
    },
    lectureId: {
      type: Number,
      required: true,
    },
    imageSource: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  created() {
    this.redirect()
  },
  methods: {
    redirect() {
      const TIME_TO_REDIRECT = 2000
      const url = '@baseurl/questoes?@query'
        .replace('@baseurl', urls.CQ_URI)
        .replace('@query', querystring.stringify({
          lectureId: this.lectureId,
          classroomId: this.classroom.id,
        }))
      setTimeout(() => window.location.assign(url), TIME_TO_REDIRECT)
    },
  },

}
</script>

<style lang="sass" scoped>
.redirect-modal
  text-align: center

  ::v-deep .sas-modal__body
    +flex-column-center
    padding: $size-xl #{2*$size-xxl} $size-l !important

    +mq-m
      padding: $size-xl $size-m $size-l !important

  &__image
    margin-bottom: $size-xl
    width: 100%

  &__title
    margin-bottom: $size-s

  &__description
    margin-bottom: $size-s
</style>
