<template>
  <modal
    class="create-magna-mission-modal"
    @close="$emit('close')"
  >
    <h4
      class="create-magna-mission-modal__title"
    >
      Missão Magna criada com sucesso!
    </h4>
    <img
      class="create-magna-mission-modal__image"
      src="App/assets/mission-preview.png"
      alt="Pré-visualização da missão magna"
    >
    <template #footer>
      <s-button
        size="large"
        variation="tertiary"
        @click="$emit('cancel')"
      >
        Continuar no relatório
      </s-button>
      <div class="create-magna-mission-modal__group">
        <s-button
          size="large"
          variation="secondary"
          :href="linkToMagnaMissionEdit"
        >
          Editar missão
        </s-button>
        <s-button
          size="large"
          :href="linkToArena"
        >
          Ir para Arena
        </s-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { urls } from 'shared/utils/urls'

export default {
  name: 'CreateMagnaMissionModal',
  props: {
    questionnaireCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      linkToArena: `${urls.EUREKA_URI}/ancestral-arena`,
    }
  },
  computed: {
    linkToMagnaMissionEdit() {
      return `${this.linkToArena}/teacher/mission/${this.questionnaireCode}`
    },
  },
}
</script>

<style lang="scss" scoped>
.create-magna-mission-modal {
  ::v-deep .sas-modal {
    width: auto !important;

    &__body {
      padding: $size-m 0;
    }

    &__footer {
      padding: $size-s;
    }
  }
  &__title {
    margin-bottom: $size-l;
    padding-left: $size-s;
    font-weight: $font-weight-medium;
    font-size: $font-size-heading-5;
    line-height: $line-height-heading;
    color: $color-ink;
  }

  &__image {
    max-width: 100%;
  }

  &__group {
    @include flex-center;
    gap: $size-xs;
  }
}
</style>
