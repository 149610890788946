<template>
  <box
    elevation="1"
    :class="[
      'assignments-list-item',
      { '--unreleased': unreleased },
    ]"
    :elevation-hover="unreleased ? '1' : '3'"
  >
    <div class="assignments-list-item__details">
      <span>{{ assignment.subtitle }}</span>
      <h6>
        {{ assignment.title | truncate(statementMaxLength) }}
      </h6>
    </div>
    <div
      v-if="unreleased"
      class="assignments-list-item__release-date"
    >
      <icon
        class="assignments-list-item__release-date__icon"
        type="calendar"
      />
      <p class="assignments-list-item__release-date__text">
        {{ formattedReleaseDate }}
      </p>
    </div>
    <slot v-else />
  </box>
</template>

<script>
import formatDate from 'App/mixins/formatDate'

export default {
  name: 'AssignmentsListItem',
  mixins: [ formatDate ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    unreleased() {
      return this.assignment.status === 'unreleased'
    },
    formattedReleaseDate() {
      return this.localizedDayAndMonth(this.assignment.releaseDate)
    },
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 25
        case 'small':
          return 32
        case 'medium':
          return 58
        case 'large':
          return 58
        case 'x_large':
          return 82
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.assignments-list-item {
  @include flex-center-start;
  flex-wrap: wrap;
  @include space-stack($size-s);
  padding: $size-s !important;

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m !important;
  }

  &__details {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-m--mf {
      padding-right: $size-m;
      flex-grow: 1;
      flex-basis: auto;
    }

    span {
      display: block;
      color: $color-ink-lighter;
      margin-bottom: $size-xs;
    }

    h6 {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0
      }
    }
  }

  &__release-date {
    display: flex;
    align-items: flex-end;

    ::v-deep &__icon {
      min-width: 24px;
      color: $color-ink-lighter;
      margin-right: $size-xxs;
    }

    &__text {
      white-space: nowrap;
      color: $color-ink-light;
    }
  }
}
</style>
