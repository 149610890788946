<template>
  <div :class="$style.container">
    <SkeletonLoader
      v-for="item in items"
      :key="item"
      :class="$style.card"
      width="100%"
      height="60px"
    />
  </div>
</template>

<script>
import { generateSkeletonKeys } from 'App/utils/arrays'

import { SkeletonLoader } from '@sas-te/alfabeto-vue'

export default {
  name: 'SyllabusStudentsCardsSkeleton',
  components: {
    SkeletonLoader,
  },
  data() {
    return {
      items: generateSkeletonKeys(3),
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding-top: 18px;
}

.card {
  border-radius: $border-radius-m;
  margin-bottom: $size-xs;
}
</style>
