export default function truncate(text = 'a', length, clamp = '...') {
  if (!length && length !== 0) {
    throw new Error('The length parameter must be set')
  }

  if (text.length <= length || length <= 0) return text

  const textWithoutClamp = text.slice(0, length - clamp.length)

  return textWithoutClamp + clamp
}
