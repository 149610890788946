<template>
  <div>
    <SkeletonLoader
      v-if="isLoading"
      width="240px"
      height="38px"
    />
    <AdaptiveSelector
      v-else
      :dropdown-direction="dropdownDirection"
      :options="subjects"
      :selected-option="selectedOption"
      :threshold="3"
      @select="$emit('select', $event)"
    >
      <template #selectedOption="{ option }">
        <div :class="$style.option">
          <img
            :class="$style.optionIcon"
            :src="require(
              `App/assets/subject/${subjectTheme(option).icon}.svg?inline`
            )"
          >
          {{ option.name }}
        </div>
      </template>
      <template #optionsTitle>
        <h4 :class="$style.optionsTitle">
          Selecionar disciplina
        </h4>
      </template>
      <template #option="{ option }">
        <div :class="$style.option">
          <img
            :class="$style.optionIcon"
            :src="require(
              `App/assets/subject/${subjectTheme(option).icon}.svg?inline`
            )"
          >
          {{ option.name }}
        </div>
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import { SkeletonLoader } from '@sas-te/alfabeto-vue'

import AdaptiveSelector from 'App/components/AdaptiveSelector/AdaptiveSelector'

import themes from '@/themes'

export default {
  name: 'SubjectPicker',
  components: {
    AdaptiveSelector,
    SkeletonLoader,
  },
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Object,
      default: null,
    },
    isLoading: Boolean,
    dropdownDirection: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    subjectTheme: (subject) => themes.subjects[subject.id],
  },
}
</script>

<style lang="scss" module>
.optionsTitle {
  flex-grow: 1;
}

.option {
  @include flex-center-start;

  flex-grow: 1;
  font-weight: $font_weight_medium;

  .optionIcon {
    display: block;
    height: $element-size-s;
    width: $element-size-s;
    margin-right: $font-size-xs;
    overflow: hidden;
    flex-shrink: 0;
  }
}
</style>
