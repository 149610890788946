var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sas-table-container"},[_c('Box',{staticClass:"sas-table-container__container",attrs:{"padding":"0","elevation":_vm.elevation}},[_c('table',{staticClass:"sas-table"},[(!_vm.loading && _vm.fields.length)?_c('thead',{staticClass:"sas-table__header"},[_c('tr',_vm._l((_vm.fields),function(field,index){return _c('th',{key:index,class:{'--sortable': field.sortable },style:({
              minWidth: field.minWidth || '',
              width: field.width || '',
              whiteSpace: field.whiteSpace || '',
              overflow: field.overflow || '',
              textOverflow: field.textOverflow || '',
            }),on:{"click":function($event){field.sortable ? _vm.updateSort(field.value) : null}}},[_c('div',{staticClass:"sas-table__header__field"},[_vm._v(" "+_vm._s(field.text)+" "),(field.value === _vm.sortField && field.sortable)?_c('icon',{class:[
                  'sas-table__header__field__icon',
                  `--${_vm.sortDirection}`
                ],attrs:{"size":"16","type":"arrow-down"}}):_vm._e()],1)])}),0)]):(_vm.fields.length)?_c('thead',{staticClass:"sas-table__header"},[_c('tr',_vm._l((_vm.fields),function(field,index){return _c('th',{key:index,style:({
              minWidth: field && field.minWidth || '',
              width: field && field.width || ''
            })},[_c('div',{staticClass:"sas-table__header__field"},[_c('skeleton-loader',{attrs:{"width":"50%","height":"16px"}})],1)])}),0)]):_vm._e(),(!_vm.loading)?_c('tbody',{staticClass:"sas-table__body"},_vm._l((_vm.visibleContent),function(row,index){return _c('tr',{key:index,class:{ '--active': index === _vm.selectedVisibleIndex },attrs:{"tabindex":index},on:{"click":function($event){return _vm.rowClick(row, index)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.rowClick(row, index)}}},_vm._l((_vm.fields),function(field,fieldIndex){return _c('td',{key:fieldIndex,style:({ 'text-align': field.textAlign || '' })},[(!_vm.$scopedSlots[field.value])?[(_vm.formatData)?[_vm._v(" "+_vm._s(_vm.formatData(row[field.value]))+" ")]:[_vm._v(" "+_vm._s(row[field.value])+" ")]]:_vm._t(field.value,null,{"row":row})],2)}),0)}),0):_c('tbody',{staticClass:"sas-table__body"},_vm._l((_vm.skeletonLines),function(index){return _c('tr',{key:index},_vm._l((_vm.fields),function(field,fieldIndex){return _c('td',{key:fieldIndex},[_vm._t(field ? field.value : fieldIndex)],2)}),0)}),0),(_vm.footer.length)?_c('tfoot',{staticClass:"sas-table__footer"},[_vm._t("footer")],2):_vm._e()])]),(_vm.paginate)?_c('SPagination',{ref:"pagination",attrs:{"total-items":_vm.content.length,"items-per-page":_vm.itemsPerPage,"page":_vm.pagination.page},on:{"range-change":_vm.rangeChange,"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("pagination",null,{"start":props.start,"end":props.end,"total":props.total})]}}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }