<template>
  <div class="class-report">
    <header class="class-report__header">
      <Header
        light-mode-on
        :title="title"
        :subtitle="subtitle"
        :navigation="goBackRoute"
        :loading="loadingClassPicker || loading"
        :hide-body="Boolean(error)"
      >
        <template #toolbar-right>
          <skeleton-loader
            v-if="loading && !error"
            opacity="1"
            height="22px"
            :width="mq_m ? '36px' : '105px'"
          />
          <template v-else-if="reportOptionsIsVisible">
            <template v-if="mq_m">
              <ClassReportOptions :selected-classroom="selectedClassroom" />
            </template>
            <template v-else>
              <SatisfactionSurvey
                :selected-classroom="selectedClassroom"
                :questionnaire="questionnaire.data || {}"
                :access-number-to-show-survey-auto="10"
              />
              <ShareToolbar :selected-classroom="selectedClassroom" />
            </template>
          </template>
        </template>
        <template
          v-if="author"
          #body
        >
          <div class="class-report__header__author">
            <template
              v-if="!isChallengeActivity
                && !(loadingClassPicker || loading)"
            >
              <ProfilePicture
                v-if="!isActivityAuthor"
                :user="author.user"
              />
              <span class="author">
                {{ authorLabel }}
              </span>
            </template>
          </div>
        </template>
        <template #right>
          <ClassPicker
            v-model="selectedClassroom"
            :classrooms="filteredClassrooms"
            :loading="loadingClassPicker"
            :disabled="isQuestionnaireLoading || isArenaMission"
          />
        </template>
      </Header>
      <wrapper class="class-report__tabs">
        <template v-if="!loading">
          <ReportTabs
            :tab-routes="tabRoutes"
            :loading="loading"
            :questionnaire="questionnaire"
          />
        </template>
      </wrapper>
    </header>
    <keep-alive v-if="!error">
      <router-view
        v-if="questionnaire.data"
        class="class-report__body"
        :routes="tabRoutes"
        :students-statistics="studentsStatistics"
        :questionnaire="questionnaire"
        :is-challenge-activity="isChallengeActivity"
        :is-teacher-challenge="isTeacherChallenge"
        :is-challenge-reinforcement="isChallengeReinforcement"
        :selected-classroom="selectedClassroom"
        :loading="loading"
        :is-eureka-grade-selected-classroom="isEurekaGradeSelectedClassroom"
      />
    </keep-alive>
    <wrapper v-else>
      <EmptyState
        v-if="hasNoStudents"
        :image="noStudents.image"
        :title="noStudents.title"
        :description="noStudents.description"
      />
      <EmptyState
        v-else-if="error"
        :image="errorState.image"
        :title="errorState.title"
        :description="errorState.description"
      >
        <s-button
          icon-left="refresh-ccw"
          @click="refresh"
        >
          {{ $t('commons.tryAgain') }}
        </s-button>
      </EmptyState>
    </wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import mediaQueries from 'App/mixins/mediaQueries'
import updateRoute from 'App/mixins/updateRoute'
import statisticsApi from '@/service/statistics'
import ClassPicker from 'App/components/ClassPicker/ClassPicker'
import Header from 'App/components/Header/Header'
import EmptyState from 'App/components/EmptyState'
import ProfilePicture from 'App/components/ProfilePicture'
import SatisfactionSurvey from 'App/components/SatisfactionSurvey'
import { GRADES, STAGES } from 'App/utils/grades'
import { isEmpty, isNil } from 'lodash'
import ClassReportOptions from './partials/ClassReportOptions'
import ShareToolbar from './partials/ShareToolbar'
import ReportTabs from './partials/ReportTabs'
import applicationTypeEnum from '../AncestralArena/enums/applicationTypeEnum'

export default {
  name: 'ClassReport',
  components: {
    ClassPicker,
    Header,
    EmptyState,
    ReportTabs,
    ProfilePicture,
    ShareToolbar,
    SatisfactionSurvey,
    ClassReportOptions,
  },
  mixins: [ mediaQueries, updateRoute ],
  fromExtraActivities: {
    type: Boolean,
    default: false,
  },
  data() {
    return {
      questionnaire: {
        loading: false,
        error: false,
        data: null,
      },
      studentsStatistics: {
        loading: false,
        error: null,
        data: [],
      },
      selectedClassroom: null,
      loadingClassPicker: true,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
      'yearSelectedContent',
    ]),
    ...mapState({
      showEurekaChallengeReinforcement: ({ featureToggles }) => featureToggles
        .toggles.eurekaChallengeReinforcement,
    }),
    isEurekaGradeSelectedClassroom() {
      return !this.isArenaMission
      && this.showEurekaChallengeReinforcement
      && this.user.isMiddleSchoolTeacher
      && this.isMiddleSchoolSelectedClassroom
    },
    isArenaMission() {
      return this.isChallengeActivity || this.isTeacherChallenge || this.isChallengeReinforcement
    },
    isMiddleSchoolSelectedClassroom() {
      const id = this.selectedClassroom?.grade?.id

      if (!id) return false

      const selectedGrade = GRADES.find((grade) => grade.id === id)

      return selectedGrade?.type === STAGES.MIDDLE_SCHOOL
    },
    isQuestionnaireLoading() {
      return this.questionnaire.loading
    },
    hasQuestionnaire() {
      return !isEmpty(this.questionnaire.data)
    },
    isHomeMission() {
      if (!this.hasQuestionnaire) return false

      return this.questionnaire.data?.applicationType === applicationTypeEnum.HOME
    },
    isChallengeActivity() {
      if (!this.hasQuestionnaire) return false

      return this.questionnaire.data.isChallengeActivity
    },
    isTeacherChallenge() {
      if (!this.hasQuestionnaire) return false

      return this.questionnaire.data.isTeacherChallenge
    },
    isChallengeReinforcement() {
      if (!this.hasQuestionnaire) return false

      return this.questionnaire.data.isChallengeReinforcement
    },
    tabRoutes() {
      const tabRoutes = {}
      const tabKeys = [
        'general',
        'questions',
        'students',
      ]
      let baseRouteName = 'teacher-report'
      const query = { ...this.$route.query }

      if (this.$route.params.extraActivityId) {
        baseRouteName = 'teacher-extra-activity-report'
      }

      if (this.$route.query.lectureId) {
        query.lectureId = this.$route.query.lectureId
      }

      if (this.$route.query.propertyId) {
        query.propertyId = this.$route.query.propertyId
      }

      tabKeys.forEach((tabKey) => {
        tabRoutes[tabKey] = {
          name: `${baseRouteName}-${tabKey}`,
          query,
        }
      })

      return tabRoutes
    },
    goBackRoute() {
      const extraActivityBaseRouteName = 'teacher-extra-activity'
      const navigateFromExtraActivity = this.$route.name.includes(extraActivityBaseRouteName)
      const query = { ...this.$route.query, classroomId: this.classroomPathParam }

      if (this.questionnaire.data) {
        return this.getGoBackRouteData(this.questionnaire.data)
      } if (navigateFromExtraActivity) {
        return {
          label: 'Atividades extras',
          route: {
            name: 'teacher-extra-activity',
            params: {
              extraActivityId: this.$route.params.extraActivityId,
              context: this.$route.query.context,
            },
            query,
          },
        }
      }

      return {
        label: this.$t('commons.back'),
        route: {
          name: 'teacher-sas-activities',
          query,
        },
      }
    },
    classroomPathParam() {
      return parseInt(this.$route.params.classroomId, 10)
    },
    filteredClassrooms() {
      if (!this.classrooms.ready || isNil(this.selectedClassroom)) {
        return []
      }

      return this.classrooms.data.filter(
        ({ grade }) => grade.id === this.selectedClassroom.grade.id
      )
    },
    title() {
      return this.questionnaire.data?.title ?? ''
    },
    subtitle() {
      return this.questionnaire.data?.subtitle ?? ''
    },
    author() {
      return this.questionnaire.data?.author
    },
    isActivityAuthor() {
      return this.author?.id === this.user.id
    },
    reportOptionsIsVisible() {
      const isChallengeQuestionnaire = this.isTeacherChallenge || this.isChallengeActivity

      return !isChallengeQuestionnaire && !this.error
    },
    authorLabel() {
      const complement = this.isHomeMission ? '• missão magna para casa' : '• missão magna ao vivo'

      if (this.isActivityAuthor) {
        return `criado por mim ${complement}`
      }

      if (this.author?.user?.name) {
        const teacherName = this.author.user.name

        return `criado por Prof. ${teacherName} ${complement}`
      }

      return ''
    },
    loading() {
      return !this.classrooms.ready
        || this.questionnaire.loading
        || this.studentsStatistics.loading
    },
    error() {
      return this.classrooms.error
        || this.questionnaire.error
        || this.studentsStatistics.error
    },
    hasNoStudents() {
      return this.questionnaire.error
        && this.questionnaire.error.status === 404
    },
    errorState() {
      return {
        image: 'error',
        title: this.$t('report.classroomStatistics.error.title'),
        description: this.$t('report.classroomStatistics.error.description'),
      }
    },
    noStudents() {
      return {
        image: 'data-empty',
        title: this.$t('report.classroomStatistics.emptyStudents.title'),
        description: this.$t('report.classroomStatistics.emptyStudents.description'),
      }
    },
    gradeId() {
      return this.selectedClassroom?.grade.id
    },
  },
  watch: {
    selectedClassroom(newSelectedClassroom, oldSelectedClassroom) {
      this.updateRouteParam('classroomId', this.selectedClassroom.id)
      const isNotInitialState = oldSelectedClassroom !== null

      if (newSelectedClassroom !== oldSelectedClassroom && isNotInitialState) {
        this.refresh()
      }
    },
  },
  created() {
    this.fetchStatistics()
    this.fetchClassrooms()
  },
  methods: {
    ...mapActions([ 'getClassrooms' ]),
    async fetchClassrooms() {
      try {
        if (!this.classrooms.ready) {
          await this.getClassrooms(this.yearSelectedContent)
        }

        if (this.classroomPathParam) {
          this.selectedClassroom = this.classrooms.data.find(
            ({ id }) => id === this.classroomPathParam
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingClassPicker = false
      }
    },
    async fetchStudentsStatistics() {
      const { classroomId, questionnaireCode } = this.$route.params

      if (!classroomId || !questionnaireCode) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
        await this.fetchClassrooms()
      }

      this.studentsStatistics.loading = true

      try {
        const response = await statisticsApi.getStudentsStatistics({
          classroomId,
          questionnaireCode,
          contentYear: this.yearSelectedContent,
        })

        this.studentsStatistics.data = response.data
      } catch (error) {
        this.studentsStatistics.error = true
      } finally {
        this.studentsStatistics.loading = false
      }
    },
    async fetchQuestionnaire() {
      const { questionnaireCode } = this.$route.params

      this.questionnaire.loading = true
      this.questionnaire.data = null
      this.questionnaire.error = false

      try {
        const response = await statisticsApi.getQuestionnaire(questionnaireCode)
        this.questionnaire.data = response
      } catch (error) {
        this.questionnaire.error = true
      } finally {
        this.questionnaire.loading = false
      }
    },
    fetchStatistics() {
      this.fetchQuestionnaire()
      this.fetchStudentsStatistics()
    },
    refresh() {
      this.fetchStatistics()
    },
    tabParam(questionnaire) {
      const { applicationType } = questionnaire

      if (applicationType === applicationTypeEnum.HOME) {
        return applicationTypeEnum.HOME
      }

      return applicationTypeEnum.LIVE
    },
    getGoBackRouteData(questionnaire) {
      const query = {
        ...this.$route.query,
        classroomId: this.classroomPathParam,
        tab: this.tabParam(questionnaire),
      }
      let goBackRouteData = {
        label: this.$t('commons.back'),
        route: { name: 'teacher-sas-activities', query },
      }

      if (questionnaire.isChallengeActivity || questionnaire.isTeacherChallenge) {
        goBackRouteData = {
          label: this.$t('commons.back'),
          route: { name: 'teacher-arena-ancestral', query },
        }
      }
      if (questionnaire.isHighSchool) {
        goBackRouteData = {
          label: 'Atividades do livro',
          route: { name: 'teacher-sas-activities', query },
        }
      }
      if (questionnaire.isExtraActivity || questionnaire.isEnemByDifficulty) {
        const {
          query: {
            classroomId,
            lectureId,
            property,
            context,
          },
          params: { extraActivityId },
        } = this.$route

        goBackRouteData = {
          label: 'Atividades extras',
          route: {
            name: 'teacher-extra-activity',
            params: { extraActivityId, context },
            query: { classroomId, lectureId, property },
          },
        }
      }

      return goBackRouteData
    },
  },
}
</script>

<style lang="scss" scoped>
.class-report {
  &__header {
    background-color: $color-white;

    &__author {
      @include flex-center-start;
      margin-top: $size-xxs;
      span {
        color: $color-ink-light;
      }

      .profile-picture {
        margin-right: $size-xs;
      }
    }
  }

  &__body {
    padding: $size-m 0 $size-xxl;

    @include mq-m--mf {
      padding: $size-xl 0 $size-xxl;
    }
  }
}
</style>
