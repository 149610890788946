import { render, staticRenderFns } from "./RedirectToCQ.vue?vue&type=template&id=437be749&scoped=true"
import script from "./RedirectToCQ.vue?vue&type=script&lang=js"
export * from "./RedirectToCQ.vue?vue&type=script&lang=js"
import style0 from "./RedirectToCQ.vue?vue&type=style&index=0&id=437be749&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437be749",
  null
  
)

export default component.exports