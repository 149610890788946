export default {
  subjects: {
    75: {
      color: '#349ac6',
      icon: 'default',
    },
    74: {
      color: '#349ac6',
      icon: 'default',
    },
    5: {
      color: '#6885eb',
      icon: 'matematica',
    },
    39: {
      color: '#349ac6',
      icon: 'default',
    },
    109: {
      color: '#349ac6',
      icon: 'default',
    },
    76: {
      color: '#349ac6',
      icon: 'default',
    },
    98: {
      color: '#9f5de2',
      icon: 'quimica',
    },
    65: {
      color: '#fbb665',
      icon: 'lingua_inglesa',
    },
    38: {
      color: '#4bc5e0',
      icon: 'ciencias',
    },
    71: {
      color: '#f86f6f',
      icon: 'lingua_portuguesa',
    },
    31: {
      color: '#fcbd43',
      icon: 'arte',
    },
    55: {
      color: '#43a5ec',
      icon: 'geografia',
    },
    60: {
      color: '#40cf9c',
      icon: 'historia',
    },
    79: {
      color: '#cd69cf',
      icon: 'biologia',
    },
    48: {
      color: '#7565da',
      icon: 'fisica',
    },
    70: {
      color: '#349ac6',
      icon: 'default',
    },
    32: {
      color: '#349ac6',
      icon: 'default',
    },
    45: {
      color: '#f7a061',
      icon: 'lingua_espanhola',
    },
    96: {
      color: '#b3d15e',
      icon: 'sociologia',
    },
    1: {
      color: '#349ac6',
      icon: 'default',
    },
    91: {
      color: '#7ae078',
      icon: 'natureza_e_sociedade',
    },
    77: {
      color: '#349ac6',
      icon: 'default',
    },
    46: {
      color: '#7ccbc1',
      icon: 'filosofia',
    },
    40: {
      color: '#349ac6',
      icon: 'default',
    },
  },
}
