<template>
  <div class="essential-learning-table">
    <h3 class="essential-learning-table__title">
      Aprendizagens essenciais avaliadas
    </h3>
    <skeleton-loader
      v-if="loading"
      width="930px"
      height="406px"
    />
    <s-table
      v-else
      ref="essentialLearningEvaluatedTable"
      :content="flatEssentialLearningList"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      :items-per-page="5"
      @sorted="tableSorted"
    >
      <template
        slot="essentialLearningTitle"
        slot-scope="{ row }"
      >
        <div class="essential-learning-table__name">
          <h6>
            {{ row.essentialLearningTitle }}
          </h6>
        </div>
      </template>

      <template
        slot="diagnosticPercentage"
        slot-scope="{ row }"
      >
        <p class="essential-learning-table__hit-rate">
          <b>{{ row.diagnosticPercentage }}%</b>
        </p>
      </template>

      <template
        slot="recoveryPercentage"
        slot-scope="{ row }"
      >
        <p class="essential-learning-table__hit-rate">
          <b>{{ row.recoveryPercentage | handlePercentage }}</b>
        </p>
      </template>
    </s-table>
  </div>
</template>

<script>
import STable from 'App/components/STable'
import { isNil, isNaN } from 'lodash'

export default {
  name: 'EssentialLearningEvaluatedDrawerTable',
  components: {
    STable,
  },
  filters: {
    handlePercentage: (percentage) => {
      if (isNaN(percentage) || isNil(percentage)) return '-'

      return `${percentage}%`
    },
  },
  props: {
    essentialLearningList: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSort: {
        field: 'essentialLearningTitle',
        direction: 'asc',
      },
      fields: [
        {
          value: 'essentialLearningTitle',
          text: 'Aprendizagem essencial',
          sortable: true,
        },
        {
          value: 'diagnosticPercentage',
          text: 'Diagnóstica',
          sortable: true,
        },
        {
          value: 'recoveryPercentage',
          text: 'Resgates',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    flatEssentialLearningList() {
      return this.essentialLearningList.data.reduce((list, curr) => {
        list.push({
          essentialLearningTitle: curr.essentialLearningTitle,
          diagnosticPercentage: curr.performance.diagnostic,
          recoveryPercentage: curr.performance.recovery,
        })

        return list
      }, [])
    },
    isListPopulated() {
      return this.flatEssentialLearningList.length > 0
    },
  },
  watch: {
    essentialLearningList: {
      handler() {
        if (!this.isListPopulated) return
        this.sendTrackEventIfListPopulated()
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.sort) {
      const [ field, direction ] = this.$route.query.sort.split(',')
      this.defaultSort = {
        field,
        direction,
      }
    }
    if (this.isListPopulated) this.sendTrackEventIfListPopulated()
  },
  methods: {
    sendTrackEventIfListPopulated() {
      this.$trackEvent({
        category: this.$track.category.logosReport,
        action: this.$track.action.seeLogosQuestionDetails,
        label: this.flatEssentialLearningList,
      })
    },
    tableSorted(sort) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          sort: `${sort.field},${sort.direction}`,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.essential-learning-table {
  &__name {
    min-width: 250px;
  }

  &__title {
    color: $color-ink;
    margin-bottom: $size-m;
    display: flex;
    align-items: center;
  }

  &__hit-rate {
    float: right;
  }

  ::v-deep  &__info-icon {
    color: $color-ink-light;
    margin-left: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-l;
  }

  &__name h6 {
    font-weight: $font-weight-base;
    font-size: $font-size-m;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-badge i {
    margin-right: $size-xxs;
  }

  ::v-deep .sas-table__header {
    height: $size-xl;
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    border: 1px solid $color-ink;
    opacity: 0.75;
  }
}
</style>
