export const featureToggles = [
  {
    toggleName: 'eureka_challenge_reinforcement',
    storeName: 'eurekaChallengeReinforcement',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'assignment_report_sessions',
    storeName: 'isShowSessions',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'assignment_report_ancestral_arena_tab',
    storeName: 'isShowAncestralArenaTab',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'eureka_home_missions',
    storeName: 'eurekaHomeMissions',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'recomposition_report_available',
    storeName: 'isShowRecompositionAvailableReport',
    defaultValue: false,
    userData: 'schoolId',
  },
]

export const featureTogglesInitialState = Object.fromEntries(
  featureToggles.map(({ storeName, defaultValue }) => [[ storeName ], defaultValue ])
)
