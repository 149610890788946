<template>
  <div
    :class="['header', {
      '--is-flex': isFlex,
      '--layout-subtitle': subtitle,
      '--theme-light': lightModeOn,
    }]"
  >
    <div class="header__toolbar">
      <wrapper>
        <div class="header__toolbar__left">
          <skeleton-loader
            v-if="loading"
            width="105px"
            height="22px"
            :opacity="lightModeOn ? '1' : '0.5'"
          />
          <s-button
            v-else-if="navigation.route"
            :to="navigation.route"
            :color="navigationLightMode"
            variation="tertiary"
            icon-left="arrow-left"
          >
            {{ navigation.label }}
          </s-button>
          <s-button
            v-else
            :href="navigation.href"
            :color="navigationLightMode"
            variation="tertiary"
            icon-left="arrow-left"
          >
            {{ navigation.label }}
          </s-button>
        </div>
        <div class="header__toolbar__right">
          <slot name="toolbar-right" />
        </div>
      </wrapper>
    </div>
    <wrapper v-if="!hideBody">
      <div class="header__body">
        <div class="header__content">
          <skeleton-loader
            v-if="loading"
            width="220px"
            height="48px"
            :opacity="lightModeOn ? '1' : '0.5'"
          />
          <template v-else>
            <h5
              v-if="subtitle"
              class="h5"
            >
              {{ subtitle }}
            </h5>
            <h2 class="h2">
              {{ title }}
            </h2>
          </template>
          <slot name="body" />
        </div>
        <div class="header__right-slot">
          <slot name="right" />
        </div>
      </div>
      <div class="header__bottom-slot">
        <slot name="bottom" />
      </div>
    </wrapper>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    navigation: {
      type: Object,
      required: true,
      validator: (value) => value.label && (value.route || value.href),
    },
    isFlex: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    customStyle: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    lightModeOn: {
      type: Boolean,
      required: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navigationLightMode() {
      return this.lightModeOn
        ? this.$tokens.color_ink_light
        : this.$tokens.color_white
    },
  },
}
</script>

<style lang="sass" scoped>
=flex
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding:
    top: 50px
    bottom: $element-size-l

.h2
  font-size: $font-size-heading-2-small

  +mq-s--mf
    font-size: $font-size-heading-2

.h5
  font-size: $font-size-heading-6

  +mq-s--mf
    font-size: $font-size-heading-5

.header
  background: linear-gradient(250.13deg, #1F3161 39.85%, #0A4891 99.22%)
  color: $color-white

  &__toolbar
    padding: $size-s 0
    border-bottom: 1px solid transparentize($color-white, 0.75)

    .sas-wrapper
      +flex-space-between

    &__left
      +flex-center-start

    &__right
      +flex-center-start

    .sas-skeleton-loader
      margin: 7px 0

  &__body
    padding:
      top: 40px
      bottom: $size-m

    +mq-l--mf
      +flex

  &__right-slot
    margin-top: $size-s

    +mq-m--mf
      margin-top: $size-m

  &.--theme-light
    background: $color-white
    color: $color-ink

    .header__toolbar
      border-bottom: 1px solid transparentize($color-ink-lightest, 0.5)

      .sas-skeleton-loader
        margin: 5px 0

    .header__content
      .h5
        color: $color-ink-light

  &.--layout-subtitle
    .header__body
      padding-top: $size-l

  &.--is-flex
    .header__body
      +flex
</style>
