<template>
  <div class="adaptive-selector">
    <AdaptiveSelectorList
      v-if="isHorizontalList"
      :selected-option="selectedOption"
      :options="options"
      @select="$emit('select', $event)"
    >
      <template #option="{ option }">
        <slot
          name="option"
          :option="option"
        />
      </template>
    </AdaptiveSelectorList>
    <AdaptiveSelectorDropdown
      v-else
      :selected-option="selectedOption"
      :options="options"
      :options-title="optionsTitle"
      :group-by="groupBy"
      :dropdown-direction="dropdownDirection"
      :filterable="filterable"
      :filter-placeholder="filterPlaceholder"
      :empty-string="emptyString"
      :empty-image="emptyImage"
      :disabled="disabled"
      @search="search"
      @select="select"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <template #selectedOption="{ option }">
        <slot
          name="selectedOption"
          :option="option"
        />
      </template>
      <template #skeleton>
        <slot name="skeleton" />
      </template>
      <template #option="{ option }">
        <slot
          name="option"
          :option="option"
        />
      </template>
      <template #optionsTitle>
        <slot name="optionsTitle" />
      </template>
    </AdaptiveSelectorDropdown>
  </div>
</template>

<script>
import AdaptiveSelectorList from './AdaptiveSelectorList'
import AdaptiveSelectorDropdown from './AdaptiveSelectorDropdown'

export default {
  name: 'AdaptiveSelector',
  components: {
    AdaptiveSelectorList,
    AdaptiveSelectorDropdown,
  },
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [ Object, String ],
      default: null,
    },
    threshold: {
      type: Number,
      default: null,
    },
    optionsTitle: {
      type: String,
      default: null,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    filterPlaceholder: {
      type: String,
      default: null,
    },
    emptyString: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    groupBy: {
      type: String,
      default: null,
    },
    dropdownDirection: {
      type: String,
      default: 'right',
      validator: (value) => value.match(/(left|right)/),
    },
    initialIndexSelectedOption: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    isHorizontalList() {
      return !Number.isInteger(this.threshold)
        || (
          this.searchString.length === 0
          && this.options
          && this.options.length <= this.threshold
        )
    },
  },
  watch: {
    options(options) {
      if (
        options
        && options.length
        && this.selectedOption === null
        && (
          this.isHorizontalList
          || (!this.isHorizontalList && !this.filterable)
        )
      ) {
        this.select(this.options[0])
      }
    },
  },
  mounted() {
    if (
      this.selectedOption === null
      && this.options
      && this.options.length) {
      this.select(this.options[this.initialIndexSelectedOption])
    }
  },
  methods: {
    select(value) {
      this.$emit('select', value)
    },
    search(text) {
      this.searchString = text
      this.$emit('search', this.searchString)
    },
  },
}
</script>

<style lang="sass" scoped>
.adaptive-selector
  z-index: 1
</style>
