import { urls } from 'shared/utils/urls'

import http from './http'

const config = { baseURL: urls.AUTH_API }
const configV2 = { baseURL: urls.AUTH_API_V2 }
// TODO: trocar pelo bff da aplicação
const configEurekaBff = { baseURL: urls.EUREKA_BFF_API }

export default {
  getUser: () => http.get('users/me', config),
  getPersonalData: () => http.get('personal-data', configV2),
  getSignedIn: () => http.get('signin'),
  getReallocationDateLimit: () => http.get(
    '/users/me/reallocation-login-date-limit',
    configV2,
  ),
  postExchangeToken: (token) => http.post('/v1/exchange-token/onb-to-sas/', { token }, configEurekaBff),
}
