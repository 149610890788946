export default {
  methods: {
    accentFold(s) {
      const accentMap = {
        á: 'a',
        ã: 'a',
        é: 'e',
        í: 'i',
        ó: 'o',
        õ: 'o',
        ú: 'u',
      }

      if (!s) {
        return ''
      }

      let ret = ''

      for (let i = 0; i < s.length; i += 1) {
        ret += accentMap[s.charAt(i)] || s.charAt(i)
      }

      return ret
    },
  },
}
