import { render, staticRenderFns } from "./ShareUrl.vue?vue&type=template&id=28d9bfae&scoped=true"
import script from "./ShareUrl.vue?vue&type=script&lang=js"
export * from "./ShareUrl.vue?vue&type=script&lang=js"
import style0 from "./ShareUrl.vue?vue&type=style&index=0&id=28d9bfae&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d9bfae",
  null
  
)

export default component.exports