<template>
  <div class="class-difficulties-cards">
    <box
      v-for="index in 5"
      :key="index"
      class="class-difficulties-cards__item"
      elevation="2"
      padding="0"
    >
      <skeleton-loader
        class="class-difficulties-cards__item__number"
        width="24px"
        height="24px"
      />
      <div class="class-difficulties-cards__item__question">
        <skeleton-loader
          class="class-difficulties-cards__item__question__content"
          width="20%"
          height="19px"
        />
        <skeleton-loader
          width="100%"
          height="25px"
        />
      </div>
      <div class="class-difficulties-cards__item__hit-rate">
        <skeleton-loader
          class="class-difficulties-cards__item__hit-rate__value"
          width="30px"
          height="19px"
        />
        <skeleton-loader
          width="46px"
          height="19px"
        />
      </div>
    </box>
  </div>
</template>

<script>
export default {
  name: 'ClassDifficultiesCardsSkeleton',
}
</script>

<style lang="sass" scoped>
.class-difficulties-cards
  &__item
    +space-stack($size-s)
    +flex-center-start

    &__question,
    &__hit-rate
      padding: $size-s

    &__number
      margin-left: $size-s

    &__question
      flex-grow: 1
      border-right: 1px solid transparentize($color-ink-lightest, 0.5)

      &__content
        margin-bottom: $size-xs
        display: inline-block

    &__hit-rate
      &__value
        margin-bottom: $size-xxs
</style>
