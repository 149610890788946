import { mapActions, mapState } from 'vuex'

import { getCookie } from 'App/utils/cookies'

export default {
  data() {
    return {
      appYearAssignmentReport: parseInt(getCookie('app_year_assignment_report'), 10),
    }
  },
  computed: {
    ...mapState([ 'yearSelectedContent', 'user' ]),
    userContentYear() {
      return this.user?.contentYear
    },
  },
  methods: {
    ...mapActions([ 'setYearSelectedContent' ]),
  },
  watch: {
    user() {
      const {
        userContentYear,
        appYearAssignmentReport,
        setYearSelectedContent,
      } = this

      if (this.userContentYear) {
        if (appYearAssignmentReport) {
          setYearSelectedContent(appYearAssignmentReport)
        } else {
          setYearSelectedContent(userContentYear)
        }
      }
    },
  },
}
