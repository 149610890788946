import answerStatusEnum from 'App/enums/answerStatus'

export default {
  methods: {
    badgeIcon(answerStatus) {
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'check'
        case answerStatusEnum.WRONG:
          return 'x'
        case answerStatusEnum.BLANK:
          return 'slash'
        default:
          return 'slash'
      }
    },
    badgeVariation(answerStatus) {
      switch (answerStatus) {
        case answerStatusEnum.CORRECT:
          return 'success'
        case answerStatusEnum.WRONG:
          return 'danger'
        default:
          return 'warning'
      }
    },
  },
}
