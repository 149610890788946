import * as types from './types'

export default {
  [types.SET_COMPLAINTS](state, complaints) {
    state.complaintTypes = {
      ...state.complaintTypes,
      ...complaints,
    }
  },
  [types.SET_COMPLAINT](state, complaint) {
    state.complaint = {
      ...state.complaint,
      ...complaint,
    }
  },
  [types.CLEAR_COMPLAINTS](state, complaint) {
    state.complaint = {
      ...state.complaint,
      ...complaint,
    }
  },
  [types.GET_QUESTION_COMPLAINTS](state, questionComplaints) {
    state.questionComplaints = {
      ...state.questionComplaints,
      ...questionComplaints,
    }
  },
}
