<template>
  <s-badge
    v-tooltip.top="tooltip"
    :icon="badgeIcon(row.answerStatus)"
    :variation="badgeVariation(row.answerStatus)"
    :class="{ 'sas-badge--default': handleBadgeClass }"
  />
</template>

<script>
import badgeStyle from 'App/mixins/badgeStyle'
import answerStatusEnum from 'App/enums/answerStatus'

export default {
  name: 'Badge',
  mixins: [ badgeStyle ],
  props: {
    row: {
      type: Object,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    handleBadgeClass() {
      return this.row?.answerStatus === answerStatusEnum.BLANK
    },
  },
}
</script>
<style lang="scss" scoped>
.sas-badge {
  &--default {
    background: $color-ink-lightest;
    color: $color-ink-light;
  }
}
</style>
