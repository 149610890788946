<template>
  <div>
    <span class="logos-mission-item__chapter">
      Capítulo {{ chapterNumber }}
    </span>
    <box
      class="logos-mission-item"
      :elevation-hover="3"
    >
      <template>
        <div
          class="logos-mission-item__column-group"
        >
          <h1
            class="logos-mission-item__title"
          >
            {{ logosMission.title }}
          </h1>
        </div>

        <div class="logos-mission-item__group">
          <icon
            class="logos-mission-item__icon"
            type="users"
          />
          <p class="logos-mission-item__text">
            {{ logosMission.students.finished }} /
            {{ logosMission.students.total }} finalizaram
          </p>
        </div>

        <slot />
      </template>
    </box>
  </div>
</template>

<script>

export default {
  name: 'LogosMissionItem',
  props: {
    logosMission: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chapterNumber: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.logos-mission-item {
  @include flex-space-between;
  flex-wrap: wrap;
  padding: $size-s !important;
  margin-bottom: $size-s;

  &__chapter {
  display: block;
  color: $color-ink-light;
  margin-bottom: $size-xs;
}

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m;
  }

  &__group {
    display: inline-flex;
    margin-bottom: $size-xs;

    @include mq-m--mf {
      margin-bottom: 0;
    }
  }

  &__column-group {
    display: inline-block;
    width: 100%;

    @include mq-m--mf {
      width: 35%;
    }
  }

  &__title {
    font-size: $font-size-heading-6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ::v-deep &__icon {
    min-width: 24px;
    color: $color-ink-light;
    margin-right: $size-xs;
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    color: $color-ink-light;
    font-size: $font-size-s;
    font-weight: $font-weight-semi-bold;
  }
}
</style>
