<template>
  <header :class="$style.header">
    <wrapper :class="$style.wrapper">
      <div :class="$style.content">
        <h1 :class="$style.title">
          {{ title }}
        </h1>
        <p
          v-if="description"
          :class="$style.description"
        >
          {{ description }}
        </p>
      </div>
      <div v-if="$slots.default">
        <slot />
      </div>
    </wrapper>
  </header>
</template>

<script>
export default {
  name: 'SyllabusHeader',
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.header {
  background-color: $color-primary-dark;
  color: $color-white;
  padding: {
    top: $size-l;
    bottom: $size-l;
  };
  margin-bottom: $size-l;
}

.wrapper {
  @include mq-l__mf() {
    @include flex-space-between();
  }
}

.content {
  flex-grow: 1;
  margin-bottom: $size-m;

  @include mq-l__mf() {
    padding-right: $size-xs;
    margin-bottom: 0;
  }
}

.title {
  font-size: $font-size-heading-3;
  margin-bottom: $size-xxs;

  @include mq-l--mf() {
    font-size: $font-size-heading-2;
  }
}

.description {
  opacity: .7;

  @include mq-l--mf() {
    font-size: $font-size-heading-5-small;
  }
}
</style>
