<template>
  <s-button
    class="custom-dropdown-item"
    variation="tertiary"
    role="option"
    data-dropdown="item"
    width="100%"
    :icon-left="iconLeft"
    @click="$emit('click', $event)"
  >
    <slot />
  </s-button>
</template>

<script>
export default {
  name: 'CustomDropdownItem',
  props: {
    iconLeft: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>

<style lang="sass">
.custom-dropdown-item
  border-radius: 0 !important
  height: auto !important
  +space-inset(12px $size-s !important)
  justify-content: flex-start !important
  text-align: left

  &:not(:first-child)
    border-top: 1px solid rgba($color-ink, .15)
</style>
