import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import { urls } from 'shared/utils/urls'

const api = axios.create({
  baseURL: urls.ASSIGNMENTS_API,
  timeout: process.env.VUE_APP_TIMEOUT,
})

api.interceptors.request.use(addAuthorizationHeader)
api.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default api
