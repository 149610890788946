const PROFILES = {
  STUDENT: 2,
  TEACHER: 3,
  SCHOOL: 7,
  MANAGER: 17,
  CONSULTANT: 5,
}

const STAGES = {
  5: 'KINDERGARDEN',
  9: 'HIGH_SCHOOL',
  10: 'MIDDLE_SCHOOL',
  11: 'MIDDLE_SCHOOL',
  MIDDLE_SCHOOL: 'middle_school',
  HIGH_SCHOOL: 'high_school',
  KINDERGARDEN: 'kindergarden',
}

const containsUserProfile = (profiles, profileId) => (
  profiles?.some((profile) => profile.id === profileId)
)

const filterExistingGrades = (gradesIds, grades) => (
  grades.filter((grade) => gradesIds.includes(grade.id))
)

export {
  PROFILES,
  STAGES,
  containsUserProfile,
  filterExistingGrades,
}
