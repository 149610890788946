import * as types from './types'

export default {
  [types.SET_TEACHER_ASSIGNMENTS](state, teacherAssignments) {
    state.teacherAssignments = {
      ...state.teacherAssignments,
      ...teacherAssignments,
    }
  },
  [types.SET_BOOK_ASSIGNMENTS](state, bookAssignments) {
    state.bookAssignments = {
      ...state.bookAssignments,
      ...bookAssignments,
    }
  },
}
