<template>
  <div class="questions-cards">
    <QuestionsCardsSkeleton v-if="loading" />
    <template v-else>
      <QuestionsCard
        v-for="(question, index) in sortedQuestions"
        :key="index"
        :question-number-is-visible="!isClassroomActivity"
        :question="question"
        :variation="variation"
        @click.native="selectQuestion(question, index)"
      />
    </template>
  </div>
</template>

<script>
import QuestionsCard from './QuestionsCard'
import QuestionsCardsSkeleton from './QuestionsCardsSkeleton'

export default {
  name: 'QuestionsCards',
  components: {
    QuestionsCard,
    QuestionsCardsSkeleton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    sortOption: {
      type: Object,
      required: true,
      validator: (value) => value.field && value.direction,
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
    variation: {
      type: String,
      default: 'neutral',
    },
    isClassroomActivity: Boolean,
  },
  computed: {
    sortedQuestions() {
      const { field, direction } = this.sortOption
      const modifier = direction === 'asc' ? 1 : -1
      const sortedQuestions = [ ...this.questions ]

      return sortedQuestions.sort((a, b) => {
        if (a[field] > b[field]) return modifier
        if (a[field] < b[field]) return -1 * modifier

        return 0
      })
    },
  },
  watch: {
    questions() {
      if (this.activeQuestion) {
        const index = this.questions.findIndex(
          (question) => question.id === this.activeQuestion.id
        )
        this.selectQuestion(this.activeQuestion, index)
      }
    },
  },
  methods: {
    selectQuestion(question, index) {
      this.$emit('selectQuestion', {
        question,
        index,
      })
    },
  },
}
</script>
