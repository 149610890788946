<template>
  <div class="extra-activity-group-picker">
    <skeleton-loader
      v-if="loading"
      width="240px"
      height="38px"
    />
    <AdaptiveSelector
      v-else
      dropdown-direction="left"
      :options="groups"
      :selected-option="selectedGroup"
      @select="$emit('select', $event)"
    >
      <template #option="{ option }">
        {{ option.name }}
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import AdaptiveSelector from './AdaptiveSelector/AdaptiveSelector'

export default {
  name: 'ExtraActivityGroupPicker',
  components: {
    AdaptiveSelector,
  },
  model: {
    prop: 'selectedGroup',
    event: 'select',
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    selectedGroup: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
