<template>
  <div class="students-view-search">
    <div class="students-view-search__name-field">
      <s-input
        v-if="!loading"
        v-model="searchString"
        icon-left="search"
        size="large"
        width="100%"
        :placeholder="$t('report.students.search')"
        :label="$t('report.filterBy')"
      />
      <skeleton-loader
        v-else
        width="100%"
        height="44px"
      />
    </div>
    <template v-if="hasFilterBy && !loading">
      <div class="students-view-search__status-field">
        <template v-if="mq_m">
          <s-button
            variation="tertiary"
            icon-left="filter"
            @click="toggleDrawer"
          >
            {{ $t('report.filter') }}
          </s-button>
        </template>
        <template v-else>
          <s-select
            v-model="activeStatusFilter"
            size="large"
            variation="secondary"
            track-by="label"
            :options="statusFilterOptions"
            :label="$t('report.filterBy')"
          />
        </template>
      </div>
      <div
        v-if="isShowSessions"
        class="students-view-search__status-field"
      >
        <template v-if="!mq_m && !isArenaMission">
          <s-select
            v-model="activeVisualizeBy"
            size="large"
            variation="secondary"
            track-by="label"
            :options="visualizeByOptions"
            label="Visualizar por"
          />
        </template>
      </div>
    </template>
    <drawer
      v-if="drawerVisible"
      background="white"
      position="bottom"
      size="100%"
    >
      <div class="students-view-search__drawer">
        <header class="students-view-search__drawer__header">
          <h4>
            {{ $t('report.students.filter') }}
          </h4>
          <s-button
            icon="x"
            variation="tertiary"
            @click="toggleDrawer"
          />
        </header>
        <div class="students-view-search__drawer__content">
          <h5 class="students-view-search__drawer__content__heading">
            {{ $t('report.students.statusLabel') }}
          </h5>
          <label
            v-for="(option, index) in statusFilterOptions"
            :key="`filter${index}`"
            :for="option.value"
          >
            <input
              :id="option.value"
              v-model="activeStatusFilter"
              type="radio"
              name="status"
              :value="option"
            >
            {{ option.label }}
          </label>
          <h5 class="students-view-search__drawer__content__heading">
            {{ $t('report.sortBy') }}
          </h5>
          <label
            v-for="(option, index) in sortOptions"
            :key="`sort${index}`"
            :for="option.value"
          >
            <input
              :id="option.value"
              v-model="activeSort"
              type="radio"
              name="sort"
              :value="option"
            >
            {{ option.label }}
          </label>
        </div>
        <footer class="students-view-search__drawer__footer">
          <s-button
            width="100%"
            size="large"
            @click="toggleDrawer"
          >
            {{ $t('report.filter') }}
          </s-button>
        </footer>
      </div>
    </drawer>
  </div>
</template>
<script>
import { isNotEmpty } from 'App/utils/arrays'
import mediaQueries from 'App/mixins/mediaQueries'
import { studentAssignmentStatus } from 'App/utils/status'
import isEmpty from 'lodash/isEmpty'
import { mapState } from 'vuex'
import visualizeBy from './enums/visualizeBy'

export default {
  name: 'StudentsViewSearch',
  mixins: [ mediaQueries ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasFilterBy: {
      type: Boolean,
      default: true,
    },
    customFilterOptions: {
      type: Array,
      default: () => ([]),
    },
    isArenaMission: Boolean,
  },
  data() {
    return {
      drawerVisible: false,
      searchString: '',
      defaultStatusFilter: 'all',
      defaultSort: 'finishedAt,desc',
      activeSort: null,
      activeStatusFilter: null,
      activeVisualizeBy: null,
      defaultVisualizeBy: visualizeBy.SESSION_MORE_RECENT,
    }
  },
  computed: {
    ...mapState({
      isShowSessions: ({ featureToggles }) => featureToggles
        .toggles.isShowSessions,
    }),
    statusFilterOptions() {
      const { customFilterOptions, defaultFilterOptions } = this

      return isNotEmpty(customFilterOptions) ? customFilterOptions : defaultFilterOptions
    },
    visualizeByOptions() {
      return [
        {
          value: visualizeBy.SESSION_MORE_RECENT,
          label: 'Sessão mais recente',
        },
        {
          value: visualizeBy.GREATER_SCORE,
          label: 'Maior pontuação',
        },
        {
          value: visualizeBy.FIRST_SESSION,
          label: 'Primeira sessão',
        },
      ]
    },
    defaultFilterOptions() {
      return [
        {
          value: 'all',
          label: 'Todos os status',
        },
        {
          value: studentAssignmentStatus.NOT_STARTED,
          label: studentAssignmentStatus.NOT_STARTED,
        },
        {
          value: studentAssignmentStatus.IN_PROGRESS,
          label: studentAssignmentStatus.IN_PROGRESS,
        },
        {
          value: studentAssignmentStatus.FINISHED,
          label: studentAssignmentStatus.FINISHED,
        },
      ]
    },
    sortOptions() {
      return [
        {
          value: 'finishedAt,desc',
          label: this.$t(
            'report.students.sort.recentFirst'
          ),
        },
        {
          value: 'finishedAt,asc',
          label: this.$t(
            'report.students.sort.recentLast'
          ),
        },
        {
          value: 'hitRate,desc',
          label: this.$t(
            'report.students.sort.greaterHitRate'
          ),
        },
        {
          value: 'hitRate,asc',
          label: this.$t(
            'report.students.sort.smallerHitRate'
          ),
        },
      ]
    },
  },
  mounted() {
    this.handleQueryParams()
  },
  methods: {
    handleQueryParams() {
      if (this.$route.query.name) {
        this.searchString = this.$route.query.name
      }

      this.$watch('searchString', (name) => {
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            name,
          },
        })
      })

      if (this.hasFilterBy) {
        if (this.$route.query.status) {
          this.activeStatusFilter = this.statusFilterOptions.find((option) => (
            option.value === this.$route.query.status
          ))
        }

        if (this.$route.query.sort) {
          this.activeSort = this.sortOptions.find((option) => (
            option.value === this.$route.query.sort
          ))
        }

        if (this.$route.query.visualizeBy) {
          this.activeVisualizeBy = this.sortOptions.find((option) => (
            option.value === this.$route.query.sort
          ))
        }

        this.$watch('activeStatusFilter', (status) => {
          if (isEmpty(status)) return

          this.$router.replace({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              status: status.value,
            },
          })
        })

        this.$watch('activeSort', (sort) => {
          if (isEmpty(sort)) return

          this.$router.replace({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              sort: sort.value,
            },
          })
        })

        this.$watch('activeVisualizeBy', (sort) => {
          if (isEmpty(sort)) return

          this.$router.replace({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              visualizeBy: sort.value,
            },
          })
        })

        if (!this.$route.query.status) {
          this.activeStatusFilter = this.statusFilterOptions.find((option) => (
            option.value === this.defaultStatusFilter
          ))
        }

        if (!this.$route.query.sort) {
          this.activeSort = this.sortOptions.find((option) => (
            option.value === this.defaultSort
          ))
        }

        if (!this.$route.query.visualizeBy) {
          this.activeVisualizeBy = this.visualizeByOptions.find((option) => (
            option.value === this.defaultVisualizeBy
          ))
        }
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible
    },
  },
}
</script>
<style lang="scss" scoped>
.students-view-search {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: $size-s;
  grid-gap: $size-m;

  @include mq-xs--mf {
    flex-wrap: nowrap;
  }

  &__name-field {
    flex-grow: 1;
    flex-basis: 100%;
    margin-bottom: $size-s;

    @include mq-xs--mf {
      flex-basis: auto;
      margin-bottom: 0;
      margin-right: $size-s;
    }

    @include mq-m--mf {
      margin-right: 0;
    }
  }

  &__status-field {
    ::v-deep .sas-select {
      min-width: 180px;
    }
  }

  &__status-field-label {
    color: $color-ink-light;
    margin-left: $size-m;
    margin-right: $size-xs;
  }

  ::v-deep .sas-input__field {
    background-color: $color-white;
  }

  &__drawer {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__header {
      border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
      @include flex-center-start;
      padding: $size-xs $size-xxs $size-xs $size-s;

      h4 {
        flex-grow: 1;
      }
    }

    &__content {
      flex-grow: 1;
      padding: $size-m $size-s;

      &__heading {
        margin-bottom: $size-s;

        &:nth-child(n+2) {
          margin-top: $size-l;
        }
      }

      label {
        font-size: $font-size-m;
        font-weight: $font-weight-regular;
        display: block;
        padding: $size-xs;
        @include space-stack($size-xs);
      }

      [type=radio] {
        height: $size-m;
        margin-right: $size-xs;
        width: $size-m;
      }
    }

    &__footer {
      box-shadow: 0 0 7px rgba($color-ink, .25);
      padding: $font-size-s;
    }
  }
}
</style>
