<template>
  <div class="loading-spinner">
    <Spinner :size="size" />
    <p v-if="text.length > 0">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.loading-spinner
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center

  p
    font-size: 24px
    padding-top: 20px
</style>
