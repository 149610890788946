<template>
  <div class="magna-mission-banner">
    <h1 class="magna-mission-banner__title">
      Missão Magna de Reforço
    </h1>
    <div class="magna-mission-banner__subheader">
      <h2 class="magna-mission-banner__subtitle">
        Aproveite questões de dificuldade para gerar uma atividade de revisão
      </h2>
      <SButton
        class="magna-mission-banner__link"
        variation="tertiary"
        icon-left="external-link"
        @click="handleLinkToArena"
      >
        Arena Ancestral
      </SButton>
      <SButton
        class="magna-mission-banner__button"
        :disabled="!canGenerateMission"
        @click="handleGenerateReinforcement"
      >
        Gerar Missão
      </SButton>
    </div>
  </div>
</template>

<script>
import { SButton } from '@sas-te/alfabeto-vue'

import { urls } from 'shared/utils/urls'

const WAIT_SEND_GTM_EVENT_MS = 1000

export default {
  name: 'MagnaMissionBanner',
  components: {
    SButton,
  },
  props: {
    canGenerateMission: Boolean,
  },
  created() {
    this.linkToArena = `${urls.EUREKA_URI}/ancestral-arena`
  },

  methods: {
    gtmTrackClick({ label = '' } = {}) {
      this.$trackEvent({
        category: this.$track.category.sasActivities,
        action: this.$track.action.teacherGenerateReinforcement,
        label,
      })
    },
    handleLinkToArena() {
      this.gtmTrackClick({ label: this.$track.label.goToAncestralArena })
      setTimeout(() => {
        window.location.href = this.linkToArena
      }, WAIT_SEND_GTM_EVENT_MS)
    },
    handleGenerateReinforcement() {
      this.$emit('generate-reinforcement-mission')
    },
  },

}
</script>

<style lang="scss" scoped>
.magna-mission-banner {
  padding: $size-l;
  background-image: url('~App/assets/background-arena.png');
  background-image: no-repeat;
  background-size: cover;
  border-radius: 10px;

  &__title {
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $size-l;
    color: $color-white;
    text-shadow: -1px 3px 0px #1C2179;
  }

  &__subheader {
    @include flex-space-between;
    margin-top: $size-s;
  }

  &__subtitle {
    font-family: Lato;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-heading-5;
    line-height: 121%;
    color: $color-white;
  }

  &__link {
      ::v-deep &.sas-button {
        color: $color-white;

        &__text {
          font-family: Rowdies;
          font-weight: $font-weight-regular;
          font-size: $font-size-s;
        }
      }
  }

  &__button {
    padding: $size-xs $size-s;
    background: #2B70C9;
    box-shadow: 0 4px 0 #004EB3;
    border-radius: 35px;

    ::v-deep &.sas-button__text {
      font-family: Rowdies;
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      line-height: 124%;
      color: $color-white;
      text-shadow: 0px 1px 0px $color-black;
    }
  }
}
</style>
