<template>
  <div class="question-drawer-problems">
    <drawer
      :close-click-outside="true"
      :background="$tokens.color_white"
      @close="$emit('close')"
    >
      <DrawerNavigator
        hide-navigation
        :disabled-report="questionReportedByTeacher"
        :hide-more-options="hideReport"
        @close="$emit('close')"
        @report-succeed="updateComplaints"
      >
        <template #header>
          <skeleton-loader
            v-if="loading"
            width="120px"
            height="33px"
          />
          <div
            v-else
            class="question-drawer-problems__header"
          >
            <h4 class="question-drawer-problems__header__title">
              {{ $t('questions.reportAProblem.problemReported') }}
            </h4>
          </div>
        </template>
        <template #child>
          <div class="question-drawer-problems__body">
            <div
              v-if="loading"
              class="question-drawer-problems__body__loading"
            >
              <spinner size="large" />
            </div>
            <template v-else>
              <ProblemsPicker
                v-if="formattedProblemsFilter.length"
                :options="formattedProblemsFilter"
                @selectedOption="selectedOption($event)"
              />
              <h5
                v-if="selectedFilter"
                class="question-drawer-problems__body__filter-title"
              >
                {{ `${selectedFilter.label} (${selectedFilter.quantity})` }}
              </h5>
              <template
                v-for="(questionComplaint, index) in filteredComplaintsList"
              >
                <div
                  :key="index"
                  class="question-drawer-problems__body__item"
                >
                  <QuestionProblemItem
                    :issue="questionComplaint"
                  />
                </div>
              </template>
            </template>
          </div>
        </template>
      </DrawerNavigator>
    </drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProblemsPicker from './ProblemsPicker'
import DrawerNavigator from '../DrawerNavigator'
import QuestionProblemItem from './QuestionProblemItem'

export default {
  name: 'QuestionDrawerProblems',
  components: {
    DrawerNavigator,
    ProblemsPicker,
    QuestionProblemItem,
  },
  props: {
    complaint: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProblemFilter: null,
      onReportProblem: false,
    }
  },
  computed: {
    formattedProblemsFilter() {
      if (this.complaint.complaintFilter) {
        return this.complaint.complaintFilter.map((item) => {
          const type = item.complaintType

          return {
            ...item,
            label: this.$t(`questions.reportAProblem.complaintTypes.${type}`),
          }
        })
      }

      return []
    },
    selectedFilter() {
      const { selectedProblemFilter, formattedProblemsFilter } = this

      if (selectedProblemFilter) {
        return formattedProblemsFilter.find(
          (item) => item.complaintType === selectedProblemFilter.complaintType
        )
      }

      return null
    },
    classroomId() {
      return this.$route.params.classroomId
    },
    questionId() {
      return this.$route.query.activeQuestion
    },
    filteredComplaintsList() {
      const { complaint, selectedProblemFilter } = this

      if (selectedProblemFilter !== null) {
        const allComplaints = complaint.complaints
        const complaintsByProblemFilter = complaint.complaints.filter(
          ({ complaintTypes }) => complaintTypes.includes(
            selectedProblemFilter.complaintType
          )
        )

        return selectedProblemFilter.complaintType === 'all'
          ? allComplaints
          : complaintsByProblemFilter
      }

      return []
    },
    questionReportedByTeacher() {
      return this.complaint?.complaints?.some((item) => item.complaintAuthor)
    },
  },
  methods: {
    ...mapActions([ 'getQuestionComplaints' ]),
    selectedOption(event) {
      const option = { ...event }
      this.selectedProblemFilter = option
    },
    toggleReportAProblem() {
      const TRANSITION_TIME = 100
      setTimeout(
        () => { this.onReportProblem = !this.onReportProblem },
        TRANSITION_TIME
      )
    },
    updateComplaints() {
      const { questionId, classroomId } = this

      this.getQuestionComplaints({
        questionId,
        classroomId,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-problems
  &__header
    +flex-center
    +flex-space-between
    width: 100%
    padding-right: $size-xs

  &__body
    ::v-deep .problem-picker
      margin: $size-m $size-m 0

    &__loading
      margin-top: 30vh
      +flex-center

    &__filter-title
      margin: $size-m $size-m 0

    &__item
      padding: $size-m $size-m 0

      &:not(:last-child)
        border-bottom: 1px solid #C9CCCF

    &__filter-title
      margin-top: $size-l
</style>
