var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extra-activity"},[_c('Header',{attrs:{"title":_vm.extraActivity.ready ? _vm.extraActivity.data.name : '',"navigation":{
      label: _vm.$t('assignments.extraActivities'),
      route: {
        name: 'teacher-sas-activities',
        query: {
          classroomId: _vm.classroomQueryParam,
          lectureId: _vm.$route.query.lectureId
        }
      },
    },"loading":_vm.extraActivity.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('ClassPicker',{attrs:{"classrooms":_vm.filteredClassrooms,"loading":_vm.loadingClassPicker},model:{value:(_vm.selectedClassroom),callback:function ($$v) {_vm.selectedClassroom=$$v},expression:"selectedClassroom"}})]},proxy:true},{key:"bottom",fn:function(){return [_c('Tabs',{attrs:{"theme":"dark","tabs":_vm.tabs}})]},proxy:true}])}),_c('ExtraActivityContextWrapper',{attrs:{"selected-property":_vm.selectedProperty},on:{"resetProperty":function($event){_vm.selectedProperty = null}},scopedSlots:_vm._u([{key:"groupSelection",fn:function({ groupSelection }){return [(groupSelection)?_c('ExtraActivityGroupPicker',{attrs:{"loading":groupSelection.loading,"groups":groupSelection.data},model:{value:(_vm.selectedProperty),callback:function ($$v) {_vm.selectedProperty=$$v},expression:"selectedProperty"}}):_vm._e()]}},{key:"assignmentsList",fn:function({ assignmentsList }){return [(assignmentsList)?[(assignmentsList.loading)?_c('AssignmentsListSkeleton'):(assignmentsList.data)?_c('AssignmentsList',{attrs:{"assignments":assignmentsList.data},scopedSlots:_vm._u([{key:"default",fn:function({ assignment }){return [_c('TeacherAssignmentsActions',{attrs:{"assignment":assignment,"complaint-badge":assignment.numberOfComplaints > 0},on:{"action":_vm.handleAssignmentAction,"select":function($event){_vm.selectedForSharing = assignment}}})]}}],null,true)}):_vm._e()]:_vm._e()]}}])}),(_vm.selectedForSharing)?_c('ShareModal',{attrs:{"selected-classroom":_vm.selectedClassroom,"questionnaire-code":_vm.selectedForSharing.questionnaireCode},on:{"close":function($event){_vm.selectedForSharing = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }