<template>
  <div class="filters-bar">
    <skeleton-loader
      v-if="loading"
      width="100%"
      height="44px"
    />
    <s-input
      v-else
      class="filters-bar__search"
      :value="searchTerm"
      placeholder="Pesquisar questão"
      icon-left="search"
      size="large"
      width="100%"
      @input="$emit('onSearch', $event)"
    />
    <FilterControl
      :level-filter="levelFilter"
      :sort-option="sortOption"
      :questionnaire="questionnaire"
      :is-filtered-by-order="isFilteredByOrder"
      @onLevelSelect="$emit('onLevelSelect', $event)"
      @onSortSelect="$emit('onSortSelect', $event)"
    />
  </div>
</template>

<script>
import FilterControl from './FilterControl'

export default {
  name: 'FiltersBar',
  components: {
    FilterControl,
  },
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    levelFilter: {
      type: String,
      required: true,
    },
    sortOption: {
      type: Object,
      required: true,
      validator: (value) => value.field && value.direction,
    },
    questionnaire: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    isFilteredByOrder: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.filters-bar{
  .sas-input {
    margin-bottom: $size-s
  }

  @include mq-s--mf {
    @include flex-center-start;

    .sas-input {
      flex-grow: 1;
      margin: {
        bottom: 0;
        right: $size-s;
      }
    }
  }

  ::v-deep .sas-input__field {
    background-color: $color-white;
  }
}
</style>
