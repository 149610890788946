<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import uniq from 'lodash/uniq'
import storage from '@/service/storage'
import typeform from '@/service/typeform'
import manageSelectedYearContent from 'App/mixins/manageSelectedYearContent'
import useGTMInitializer from './shared/mixins/useGTMInitializer'

export default {
  mixins: [ manageSelectedYearContent, useGTMInitializer ],
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
      'yearSelectedContent',
      'personalData',
    ]),
  },
  watch: {
    user() {
      const { isManager, isSchool, isTeacher } = this.user

      if (isTeacher || isManager || isSchool) {
        if (JSON.parse(process.env.VUE_APP_TRACKING_ENABLED)) {
          this.pushUserToDataLayer()
        }
      }
    },
  },
  async created() {
    await this.getUser()
    await this.getPersonalData()
    this.setupApplication()

    typeform.install(this.setSurveyButtonVisible(true))
  },
  methods: {
    ...mapActions('typeform', [ 'setTypeformReady' ]),
    ...mapActions([
      'getUser',
      'getClassrooms',
      'getPersonalData',
    ]),
    setupApplication() {
      const { isTeacher, isSchool, isManager } = this.user

      if (isTeacher || isSchool || isManager) {
        storage.setItem('domain', 'activities-report')
      } else {
        storage.setItem('domain', 'activities')
      }
    },
    async pushUserToDataLayer() {
      const {
        id: accountId,
        school,
        profiles,
        grades,
      } = this.user

      const userSummary = {
        accountId,
        schoolId: school.id,
        profileId: profiles[0].id,
        gradeId: grades.map(({ id }) => id),
        prospection: this.personalData.schoolProspection,
      }

      await this.getClassrooms(this.yearSelectedContent)
      userSummary.lectureId = uniq(
        this.classrooms.data
          .map(({ lectures }) => lectures.map((lecture) => lecture.id))
          .flat()
      )

      window.dataLayer.push(userSummary)
    },
    setSurveyButtonVisible(value) {
      this.setTypeformReady(value)
    },
  },
}
</script>

<style lang="scss">
@import '~@sas-te/alfabeto-vue';
@import '~@sas-te/alfabeto-vue/src/scss/packages/vue-toasted';
</style>
