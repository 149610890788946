import { format } from 'date-fns'
import dateLocale from '@/service/dateLocale'

export default {
  data() {
    return {
      dateLocale: null,
    }
  },
  async created() {
    const locale = process.env.VUE_APP_I18N_LOCALE
      || process.env.VUE_APP_I18N_FALLBACK_LOCALE
    this.dateLocale = await dateLocale(locale)
  },
  methods: {
    format(dateTime, formatString) {
      return dateTime
        ? format(dateTime, formatString, { locale: this.dateLocale })
        : '-'
    },
    localizedDayAndMonth(date) {
      const formattedString = this.format(new Date(date), 'PPP')

      return formattedString.replace(/( de )?\d{4}$/, '')
    },
  },
}
