<template>
  <div
    role="tablist"
    class="tabs"
  >
    <s-button
      v-for="tab in tabs"
      :key="tab.name"
      role="tab"
      :class="['tabs__item', {
        '--active': isTabActive(tab),
        '--disabled': isTabDisabled(tab)
      }]"
      variation="tertiary"
      @click="openTab(tab)"
    >
      {{ text(tab) }}
    </s-button>
  </div>
</template>

<script>
import updateRoute from 'App/mixins/updateRoute'
import { isNil, has } from 'lodash'

export default {
  name: 'TabSelector',
  mixins: [ updateRoute ],
  props: {
    tabs: {
      type: Array,
      default: () => [],
      validator: (value) => value.every((tab) => has(tab, 'name') && has(tab, 'text')),
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => value.match(/(primary|secondary)/),
    },
    isActiveParam: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentTab: null,
    }
  },
  computed: {
    tabQueryParam() {
      return this.$route.query.tab
    },
  },
  watch: {
    tabQueryParam: {
      immediate: true,
      handler() {
        const { tabs, tabQueryParam, openTab } = this
        const [ firstTab ] = tabs
        const tabFound = tabs.find(({ name }) => name === tabQueryParam)

        if (tabFound) {
          openTab(tabFound)
        } else {
          openTab(firstTab)
        }
      },
    },
  },
  methods: {
    updateTabQueryParam(value) {
      if (!this.isActiveParam) return

      this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
        },
      })
    },
    openTab(tab) {
      if ((!isNil(tab) && this.isTabActive(tab)) || tab?.isDisabled) {
        return
      }

      this.currentTab = tab

      this.$emit('change-tab', tab)

      if (!this.isActiveParam) return
      this.updateRouteQueryWithReplace('tab', this.currentTab?.name)
    },
    isTabActive(tab) {
      if (isNil(tab)) return false

      return this.currentTab?.name === tab.name
    },
    isTabDisabled(tab) {
      if (isNil(tab)) return false

      return tab?.isDisabled ?? false
    },
    text(tab) {
      return tab?.text ?? tab?.name
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  overflow: auto;
  @include flex-center-start;
  margin-bottom: $size-m;

  &__item {
    @include space-inline($size-s);
    border-radius: 0;
    flex-shrink: 0;

    &:focus {
      box-shadow: none !important;
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      height: 5px;
      width: 0%;
      border-radius: 10px 10px 0 0;
      background: $color-primary-dark;
      @include transition(0.3s);
    }

    ::v-deep .sas-button__text {
      color: $color-ink-light;
      font-size: $font-size-s;
      line-height: 20px;
    }

    &.--active {
      &::after {
        width: 100%;
      }

      ::v-deep .sas-button__text {
        color: $color-ink;
      }
    }
  }
}
</style>
