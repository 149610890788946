import http from './http'

export default {
  getProposedAssignmentInfo: (subjectId, classroomId) => http.get(
    `classrooms/${classroomId}/subjects/${subjectId}/proposed-assignment-info`
  ),
  getProposedAssignmentStudents: (subjectId, classroomId) => http.get(
    `classrooms/${classroomId}/subjects/${subjectId}/proposed-assignments-students-report`
  ),
  getProposedAssignmentHistoric: (subjectId, studentId) => http.get(
    `/subjects/${subjectId}/proposed-assignments?studentId=${studentId}`
  ),
}
