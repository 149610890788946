<template>
  <s-button
    class="sas-page-button"
    variation="tertiary"
    :active="active"
    :disabled="disabled"
    :icon="icon"
    :title="title"
    :loading="loading"
    @click="pageChange"
  >
    <span
      v-if="html"
      v-html="html"
    />
  </s-button>
</template>

<script>
export default {
  name: 'SPageButton',
  props: {
    page: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    html: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pageChange() {
      if (
        this.page === '...'
        || this.disabled
        || this.active
      ) {
        return
      }

      this.$emit('page-change', this.page)
    },
  },
}
</script>
