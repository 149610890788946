<template>
  <div class="knowledge-test-table-details">
    <slot name="selector" />

    <s-table
      ref="questionsTable"
      :content="mappedQuestions"
      :fields="fieldsWithDynamicOptions"
      :paginate="true"
      @sorted="tableSorted"
      @click-row="clickRow"
    >
      <template
        slot="statement"
        slot-scope="{ row }"
      >
        <div class="knowledge-test-table-details__name">
          <h6 :title="row.statement">
            {{ row.statement }}
          </h6>
        </div>
      </template>

      <template
        slot="rightOption"
        slot-scope="{ row }"
      >
        <p class="knowledge-test-table-details__right-option">
          {{ letterIndex(Number(row.rightOption)) }}
        </p>
      </template>

      <template
        slot="hitRate"
        slot-scope="{ row }"
      >
        <p class="knowledge-test-table-details__hit-rate">
          {{ row.hitRate | formatPercentage }}
        </p>
      </template>

      <template
        v-for="(dynamicRow) in dynamicRows"
        :slot="dynamicRow.slot"
        slot-scope="{ row }"
      >
        <div
          :key="dynamicRow.slot"
          class="knowledge-test-table-details__retry-count"
        >
          {{ row[dynamicRow.row] | formatPercentage }}
        </div>
      </template>

      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.paginationQuestions',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
  </div>
</template>

<script>
import mediaQueries from 'App/mixins/mediaQueries'
import formatDate from 'App/mixins/formatDate'
import STable from 'App/components/STable'
import { isNaN, isNil } from 'lodash'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'RecompositionKnowledgeTestClassDetailsTable',
  components: {
    STable,
  },
  filters: {
    formatPercentage: (value) => {
      if (isNaN(value) || isNil(value)) return '-'

      return `${value}%`
    },
  },
  mixins: [
    mediaQueries,
    formatDate,
  ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultSort: {
        field: 'name',
        direction: 'asc',
      },
    }
  },
  computed: {
    fieldsWithDynamicOptions() {
      return [
        {
          value: 'statement',
          text: 'Questão',
          sortable: true,
        },
        {
          value: 'rightOption',
          text: 'Alternativa Correta',
          sortable: true,
        },
        {
          value: 'hitRate',
          text: 'Índice de Acertos',
          sortable: true,
        },
        ...this.generateDynamicOptions(),
      ]
    },
    mappedQuestions() {
      return this.questions?.map((question) => {
        const markedOptions = this.generateMarkedOption(question?.markedOptions ?? [])
        const flattedMarkedOptions = markedOptions.reduce((acc, curr) => ({ ...acc, ...curr }), {})

        return {
          id: question.id,
          statement: question.statement,
          rightOption: question.rightOption,
          hitRate: question.hitRate,
          ...flattedMarkedOptions,
        }
      }) ?? []
    },
    questionWithMoreOptions() {
      if (isEmpty(this.questions)) return []

      return this.questions.reduce((acc, curr) => {
        if (isEmpty(curr.markedOptions)) return 0
        if (curr.markedOptions.length > acc) return curr.markedOptions.length

        return acc
      }, 0)
    },
    dynamicRows() {
      return Array.from({ length: this.questionWithMoreOptions }, (_, index) => ({
        slot: `chose${this.letterIndex(index)}`,
        row: `markedOption${this.letterIndex(index)}`,
      })) ?? []
    },
  },
  created() {
    if (this.$route.query.sortClass) {
      const [ field, direction ] = this.$route.query.sortClass.split(',')
      this.defaultSort = {
        field,
        direction,
      }
    }
  },
  methods: {
    generateDynamicOptions() {
      if (isEmpty(this.questions)) return []

      return Array.from({ length: this.questionWithMoreOptions }, (_, index) => ({
        value: `chose${this.letterIndex(index)}`,
        text: `Escolheram ${this.letterIndex(index)}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        sortable: true,
      }))
    },
    generateMarkedOption(markedOptions) {
      if (isEmpty(markedOptions)) return []

      return markedOptions.map((option, index) => ({
        [`markedOption${this.letterIndex(index)}`]: option.rate,
        [`chose${this.letterIndex(index)}`]: option.rate,
      }))
    },
    letterIndex(index) {
      return String.fromCharCode(65 + index)
    },
    tableSorted(sort) {
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          sortClass: `${sort.field},${sort.direction}`,
        },
      })
    },
    previousQuestion() {
      this.$refs.knowledgeTestClassDetailsTable.previous()
    },
    nextQuestion() {
      this.$refs.knowledgeTestClassDetailsTable.next()
    },
    clickRow({ data }) {
      this.$emit('select-question', {
        question: data,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.knowledge-test-table-details {
  &__name h6 {
    font-weight: $font-weight-base;
    font-size: $font-size-m;
    min-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  &__right-option {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  &__retry-count {
    display: flex;
    align-content: center;
    justify-content: flex-end;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-table-container {
    margin-top: $size-m;
  }

  ::v-deep .sas-table-container__container {
    overflow: auto;
  }

  ::v-deep .sas-badge i {
    margin-right: $size-xxs;
  }

  ::v-deep .sas-table__header {
    height: $size-xxl;
  }

  ::v-deep .sas-table__header__field {
    height: 100%;
    text-align: center;
  }

  ::v-deep .sas-table__body tr {
    cursor: pointer;
    min-width: 100px;
  }

  ::v-deep .sas-table__body tr td {
    max-width: 220px;
  }

  ::v-deep .sas-table__body th {
    padding: $size-xs;
  }

  ::v-deep .sas-table thead th {
    padding: $size-s;

    &:first-child {
      padding-left: $size-m;
    }
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    border: 1px solid $color-ink;
    opacity: 0.75;
  }

  ::v-deep .sas-table__header__field .feather {
    overflow: visible;
  }
}
</style>
