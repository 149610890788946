<template>
  <box
    class="ancestral-arena-list-item"
    :elevation-hover="3"
  >
    <div class="ancestral-arena-list-item__column-group">
      <h1
        class="ancestral-arena-list-item__title truncate"
        :title="title"
      >
        {{ title }}
      </h1>
      <h2 class="ancestral-arena-list-item__subtitle">
        {{ subtitle }}
      </h2>
    </div>

    <div class="ancestral-arena-list-item__group">
      <icon
        class="ancestral-arena-list-item__icon"
        type="check-square"
      />
      <p class="ancestral-arena-list-item__text">
        {{ numberOfQuestions }}
      </p>
    </div>

    <div
      v-if="hasFinishedDate"
      v-tooltip.top="'Data de finalização'"
      class="ancestral-arena-list-item__group"
    >
      <icon
        class="ancestral-arena-list-item__icon"
        type="calendar"
      />
      <p class="ancestral-arena-list-item__text">
        {{ formattedReleaseDate }}
      </p>
    </div>

    <s-badge
      v-if="isOnGoingMission"
      class="ancestral-arena-list-item__badge"
      size="large"
    >
      Em andamento
    </s-badge>

    <slot />
  </box>
</template>

<script>
import simplur from 'simplur'
import formatDate from 'App/mixins/formatDate'
import missionTypes from 'App/enums/missionTypes'
import missionStatusEnum from '../enums/missionStatusEnum'

export default {
  name: 'AncestralArenaListItem',
  mixins: [ formatDate ],
  props: {
    matchHistory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.matchHistory?.title
    },
    hasFinishedDate() {
      return !!this.matchHistory?.finishedAt
    },
    isOnGoingMission() {
      return this.matchHistory?.finishedStatus === missionStatusEnum.ONGOING
    },
    formattedReleaseDate() {
      return this.format(new Date(this.matchHistory?.finishedAt), "dd/MM/yyyy - HH'h'mm")
    },
    numberOfQuestions() {
      return simplur`${this.matchHistory?.questionCount} quest[ão|ões]`
    },
    subtitle() {
      return missionTypes[this.matchHistory?.type] ?? ''
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-list-item {
  @include flex-space-between;
  flex-wrap: wrap;
  padding: $size-s;

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m;
  }

  &__group {
    display: inline-flex;
    margin-bottom: $size-xs;

    @include mq-m--mf {
      margin-bottom: 0;
    }
  }

  &__column-group {
    display: inline-block;
    width: 100%;

    @include mq-m--mf {
      width: 18%;
    }
  }

  &__title {
    font-size: $font-size-heading-6;
    margin-bottom: $size-s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mq-m--mf {
      margin-bottom: $size-xs;
    }
  }

  &__subtitle {
    color: $color-ink-light;
    font-size: $font-size-heading-6-small;
    margin-bottom: $size-s;

    @include mq-m--mf {
      margin-bottom: 0;
    }
  }

  ::v-deep &__icon {
    min-width: 24px;
    color: $color-ink-lighter;
    margin-right: $size-xs;
    fill: $color-ink-light;
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    color: $color-ink-light;
  }

  &__badge {
    color: $color-warning-darker;
    background: $color-warning-lightest;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
