var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adaptive-selector-list"},[_c('div',{staticClass:"adaptive-selector-list__wrapper"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,class:[
        'adaptive-selector-list__item',
        {
          '--active': _vm.selectedOption && _vm.isEqual(option, _vm.selectedOption),
          '--disabled': option && option.disabled,
        }
      ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectOption(option)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectOption(_vm.classroom)}}},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(option)+" ")]},{"option":option})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }