<template>
  <div class="students-view-cards">
    <box
      v-for="(item, index) in students"
      :key="index"
      class="students-view-cards__item"
      elevation="2"
      elevation-hover="3"
      padding="0"
      @click.native="selectStudent(item, index)"
    >
      <div class="students-view-cards__item__student">
        <h6 class="students-view-cards__item__student__name">
          {{ item.name }}
        </h6>
        <div class="students-view-cards__item__student__status">
          <s-badge
            size="small"
            :variation="getStatusVariation(item.status)"
          >
            {{ item.status }}
          </s-badge>
        </div>
        <span
          v-if="item.status === studentAssignmentStatus.FINISHED"
          class="students-view-cards__item__student__finished-at"
        >
          {{
            $t('report.students.at')
          }}
          {{ format(item.finishedAt, 'd MMM') }}
        </span>
      </div>
      <div class="students-view-cards__item__hit-rate">
        <template v-if="item.status === studentAssignmentStatus.FINISHED">
          <span class="students-view-cards__item__hit-rate__value">
            <b>{{ Math.round(item.hitRate) }}%</b>
          </span>
          <span class="students-view-cards__item__hit-rate__label">
            {{ $t('report.students.hits') }}
          </span>
        </template>
        <template v-else>
          --
        </template>
      </div>
    </box>
  </div>
</template>

<script>
import { getStatusVariation, studentAssignmentStatus } from 'App/utils/status'
import formatDate from 'App/mixins/formatDate'

export default {
  name: 'StudentsViewCards',
  mixins: [
    formatDate,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      studentAssignmentStatus,
    }
  },
  methods: {
    getStatusVariation,
    selectStudent(data, index) {
      this.$emit('selectStudent', {
        data,
        index,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-cards {
  &__item {
    @include space-stack($size-s);
    @include flex-center-start;

    &__student {
      @include flex-center-start;
      border-right: 1px solid transparentize($color-ink-lightest, 0.5);
      flex-grow: 1;
      padding: $size-s;
      flex-wrap: wrap;

      &__name {
        flex: 1 0 100%;
        margin-bottom: $size-xxs;
      }

      &__status {
        margin-right: $size-s;
      }

      &__finished-at {
        display: inline-block;
        color: $color-ink-light;
      }
    }

    &__hit-rate {
      min-width: 62px;
      padding: $size-xs;

      &__value {
        display: block;
      }

      &__label {

        color: $color-ink-light;
      }
    }

  }
}
</style>
