<template>
  <drawer
    class="recomposition-drawer"
    :overlay-enabled="false"
    :size="activeQuestion ? '100%' : $tokens.device_l"
    @close="close"
  >
    <RecompositionDrawerHeader
      :student="student"
      :overlay-enabled="false"
      :has-previous="hasPrevious"
      :has-next="hasNext"
      @close="close"
      @previous-student="$emit('previous-student')"
      @next-student="$emit('next-student')"
    />
    <div class="recomposition-drawer__charts-header">
      <h6
        class="recomposition-drawer__charts-header-title"
      >
        {{ headingTopicName }} · Capítulo {{ recompositionChapterNumber }}
      </h6>
      <h5
        class="recomposition-drawer__charts-header-subtitle"
      >
        {{ recompositionChapterName }}
      </h5>
    </div>

    <div
      v-if="isActivityNotStarted && !isLoading"
      class="recomposition-drawer__body"
    >
      <EmptyState
        :title="emptyState.title"
        :description="emptyState.description"
        :image="emptyState.image"
      />
    </div>
    <div
      v-else
      class="recomposition-drawer__body"
    >
      <div class="recomposition-drawer__charts-container">
        <div
          class="recomposition-drawer__charts-content"
        >
          <EssentialLearningEvaluatedDrawerTable
            :essential-learning-list="essentialLearningList"
            :loading="isLoading"
          />
        </div>
      </div>
      <div class="recomposition-drawer__table-container">
        <div class="recomposition-drawer__details">
          <div class="recomposition-drawer__details-header">
            <h6 class="recomposition-drawer__details-title">
              Detalhamentos do Aluno
            </h6>
            <span class="recomposition-drawer__details-subtitle">
              Dados de aprofundamento de relatório
            </span>
          </div>

          <TabSelector
            :class="[
              'recomposition-drawer__detail-tabs',
              [{ '-minimal-margin': isRecoveryTab}],
            ]"
            :tabs="tabs"
            :is-active-param="false"
            @change-tab="changeTab"
          />
        </div>
        <skeleton-loader
          v-if="isLoading"
          width="930px"
          height="406px"
        />
        <RecompositionDrawerTestTable
          v-else
          v-show="isDiagnosticTab"
          :questions="diagnosticQuestions.data"
          :loading="diagnosticQuestions.loading"
          @select-question="selectQuestion"
        />
        <skeleton-loader
          v-if="isLoading"
          width="930px"
          height="406px"
        />
        <RecompositionDrawerRecoveryQuestions
          v-if="isRecoveryTab"
          :questions="recoveryQuestions.data"
          :loading="recoveryQuestions.loading"
          @select-question="selectQuestion"
        >
          <template #selector>
            <s-select
              v-model="recompositionRecoveryOption"
              class="recomposition-drawer__recovery-selector"
              size="large"
              variation="secondary"
              track-by="label"
              :options="mappedOptions"
            />
            <h5
              class="recomposition-drawer__learning-title"
            >
              Aprendizagem essencial avaliada: {{ essentialLearningTitle }}
            </h5>
          </template>
        </RecompositionDrawerRecoveryQuestions>
      </div>
    </div>
    <QuestionDrawer
      v-if="activeQuestion"
      :question="activeQuestion"
      hide-navigation
      :overlay-enabled="false"
      :has-previous="hasPreviousQuestion"
      :has-next="hasNextQuestion"
      @on-close="clearSelectedQuestion"
      @on-previous="previousQuestion"
      @on-next="nextQuestion"
    />
  </drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { isNil, isEmpty } from 'lodash'
import keyEvents from 'App/mixins/keyEvents'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import logosApi from '@/service/logos'
import EmptyState from 'App/components/EmptyState'
import TabSelector from 'App/components/TabSelector'
import RecompositionDrawerHeader from './RecompositionDrawerHeader'
import RecompositionDrawerTestTable from './components/RecompositionDrawerTestTable/RecompositionDrawerTestTable'
import RecompositionDrawerRecoveryQuestions from './components/RecompositionDrawerRecoveryQuestions/RecompositionDrawerRecoveryQuestions'
import EssentialLearningEvaluatedDrawerTable from './components/EssentialLearningEvaluatedDrawerTable/EssentialLearningEvaluatedDrawerTable'

const questionsTypeEnum = {
  RECOMPOSITION_DIAGNOSTIC: 'RECOMPOSITION_DIAGNOSTIC',
  RECOMPOSITION_RECOVERY: 'RECOMPOSITION_RECOVERY',
  RECOMPOSITION_RECOVERY_RESULT: 'RECOMPOSITION_RECOVERY_RESULT',
}

export default {
  name: 'RecompositionDrawer',
  components: {
    EmptyState,
    TabSelector,
    QuestionDrawer,
    RecompositionDrawerHeader,
    RecompositionDrawerTestTable,
    EssentialLearningEvaluatedDrawerTable,
    RecompositionDrawerRecoveryQuestions,
  },
  mixins: [ keyEvents ],
  props: {
    student: {
      type: Object,
      required: true,
    },
    classroomId: {
      type: Number,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    diagnosticStatus: {
      type: String,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    questionnaireCode: {
      type: String,
      required: true,
    },
    disableControls: Boolean,
    recompositionChapter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      essentialLearningList: {
        loading: false,
        data: [],
        error: null,
      },
      selectedTabName: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC,
      questions: {
        [questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]: {
          loading: true,
          data: [],
          error: null,
        },
        [questionsTypeEnum.RECOMPOSITION_RECOVERY]: {
          loading: true,
          data: [],
          error: null,
        },
      },
      recoveryOptions: {
        [questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]: {
          loading: true,
          error: null,
          data: [],
        },
        [questionsTypeEnum.RECOMPOSITION_RECOVERY]: {
          loading: true,
          error: null,
          data: [],
        },
      },
      recoveryResults: {
        loading: false,
        data: null,
        error: null,
      },
      activeQuestionInnerIndex: -1,
      recompositionRecoveryOption: null,
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    essentialLearningTitle() {
      return this.recompositionRecoveryOption?.essentialLearningTitle ?? ''
    },
    emptyState() {
      if (this.diagnosticStatus === 'ONGOING') {
        return {
          image: 'empty-student-report',
          title: `${this.student.name} ainda não finalizou a atividade`,
          description: `Incentive ${this.student.name} a finalizar para ver seu desempenho e questões respondidas.`,
        }
      }

      return {
        image: 'empty-student-report',
        title: `${this.student.name} ainda não iniciou a atividade`,
        description: `Incentive ${this.student.name} a iniciar a atividade para ver o seu desempenho e questões respondidas.`,
      }
    },
    activeStudent() {
      return this.$route.query?.activeStudent ?? null
    },
    isQuestionsLoading() {
      return this.questions[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC].loading
        || this.questions[questionsTypeEnum.RECOMPOSITION_RECOVERY].loading
    },
    isRecoveryOptionsLoading() {
      return this.recoveryOptions[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC].loading
        || this.recoveryOptions[questionsTypeEnum.RECOMPOSITION_RECOVERY].loading
    },
    isEssentialLearningListLoading() {
      return this.essentialLearningList.loading
    },
    isLoading() {
      return this.isQuestionsLoading
      || this.isRecoveryOptionsLoading
      || this.isEssentialLearningListLoading
    },
    isActivityNotStarted() {
      return isEmpty(this.recoveryOptions?.RECOMPOSITION_DIAGNOSTIC?.data)
    },
    tabs() {
      return [
        {
          name: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC,
          text: 'Questões da Missão Lógos',
          isDisabled: false,
        },
        {
          name: questionsTypeEnum.RECOMPOSITION_RECOVERY,
          text: 'Questões do Resgate de conhecimento',
          isDisabled: !this.isRecoveryDiagnosticFinished,
        },
      ]
    },
    mappedOptions() {
      return this.recoveryOptions[questionsTypeEnum.RECOMPOSITION_RECOVERY]
        .data.map(({ code, title, essentialLearningTitle }) => ({
          value: code,
          label: title,
          essentialLearningTitle,
        }))
    },
    isDiagnosticTab() {
      return this.selectedTabName === questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC
    },
    isRecoveryTab() {
      return this.selectedTabName === questionsTypeEnum.RECOMPOSITION_RECOVERY
    },
    isRecoveryResultsTab() {
      return this.selectedTabName === questionsTypeEnum.RECOMPOSITION_RECOVERY_RESULT
    },
    activeQuestions() {
      return this.questions[this.selectedTabName].data ?? []
    },
    recoveryOptionsDiagnostic() {
      return this.recoveryOptions?.[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC].data ?? []
    },
    recoveryOptionsRecovery() {
      return this.recoveryOptions?.[questionsTypeEnum.RECOMPOSITION_RECOVERY].data ?? []
    },
    diagnosticQuestions() {
      return this.questions[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]
    },
    recoveryQuestions() {
      return this.questions[questionsTypeEnum.RECOMPOSITION_RECOVERY]
    },
    recompositionChapterId() {
      return this.$route.params.recompositionChapterId ?? null
    },
    recompositionChapterName() {
      return this.recompositionChapter?.chapterTitle ?? ''
    },
    recompositionChapterNumber() {
      return this.recompositionChapter?.chapterNumber ?? 0
    },
    headingTopicName() {
      return this.recompositionChapter?.headingTopicName ?? ''
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.activeQuestions.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    isRecoveryDiagnosticFinished() {
      return !isEmpty(this.questions.RECOMPOSITION_RECOVERY.data)
    },
    activeQuestionIndex() {
      return this.activeQuestions.findIndex((question) => (
        question.id === this.activeQuestion?.id ?? null
      ))
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionInnerIndex < this.activeQuestions.length - 1
    },
  },
  watch: {
    activeStudent(newValue, oldValue) {
      if (newValue !== oldValue) return this.handleFetchData()

      return oldValue
    },
    mappedOptions(newMappedOptions) {
      this.recompositionRecoveryOption = newMappedOptions?.[0] ?? null
    },
    recompositionRecoveryOption(newRecompositionOption, oldRecompositionOption) {
      if (!isNil(oldRecompositionOption) && newRecompositionOption !== oldRecompositionOption) {
        this.fetchRefreshRecompositionRecovery()
      }
    },
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    this.handleFetchData()
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
  },
  methods: {
    handleFetchData() {
      this.fetchEssentialLearning()
      this.fetchRecoveryResults()
      this.fetchStudentQuestions({ type: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC })
      this.fetchStudentQuestions({ type: questionsTypeEnum.RECOMPOSITION_RECOVERY })
    },
    changeTab(tab) {
      this.selectedTabName = tab?.name ?? questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC
    },
    close() {
      delete this.$route.query.assignmentCode
      this.$emit('close')
    },
    performanceColors(performance) {
      const green = '#0DC18B'
      const red = '#EB0000'

      if (performance >= 65) return green

      return red
    },
    async fetchEssentialLearning() {
      this.essentialLearningList.loading = true
      const studentId = this.student.id
      const { recompositionChapterId } = this
      try {
        const { data } = await logosApi.getStudentEssentialLearningList({
          studentId,
          recompositionChapterId,
        })
        this.essentialLearningList.data = data
        this.essentialLearningList.error = false
      } catch (error) {
        this.essentialLearningList.error = true
      } finally {
        this.essentialLearningList.loading = false
      }
    },
    async fetchRecoveryOptions(type) {
      if (!type) {
        console.error('Missing params fetchRecoveryOptions:', {
          type,
        })

        return
      }
      const recoveryOptions = this.recoveryOptions[type]
      recoveryOptions.loading = true
      try {
        const { data } = await logosApi.getStudentRecoveryOptions({
          types: type,
          recompositionChapterId: this.recompositionChapterId,
          studentId: this.student.id,
        })
        recoveryOptions.data = data
        recoveryOptions.error = false
      } catch (error) {
        recoveryOptions.error = true
      } finally {
        recoveryOptions.loading = false
      }
    },
    fetchRefreshRecompositionRecovery() {
      this.fetchStudentQuestions({
        type: questionsTypeEnum.RECOMPOSITION_RECOVERY,
        questionnaireCode: this.recompositionRecoveryOption.value,
        notUpdateOptions: true,
      })
    },
    async fetchStudentQuestions({
      type,
      questionnaireCode,
      notUpdateOptions,
    } = {
      type: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC,
      questionnaireCode: null,
      notUpdateOptions: false,
    }) {
      if (!notUpdateOptions) {
        await this.fetchRecoveryOptions(type)
      }

      const code = questionnaireCode ?? this.recoveryOptions[type].data?.[0]?.code ?? null

      const questions = this.questions[type]
      const studentId = this.student.id
      questions.loading = true

      if (!studentId || !code) {
        if (this.recoveryOptions.RECOMPOSITION_RECOVERY.data.length === 0) {
          questions.loading = false

          return
        }

        console.error('Missing params fetchStudentQuestions:', {
          questionnaireCode: code,
          studentId,
        })
        questions.loading = false

        return
      }

      try {
        const { data } = await logosApi.getStudentQuestions({
          studentId,
          questionnaireCode: code,
        })
        questions.data = data
        questions.error = false
      } catch (error) {
        questions.error = true
      } finally {
        questions.loading = false
      }
    },
    async fetchRecoveryResults() {
      this.recoveryResults.loading = true
      try {
        const { data } = await logosApi.getStudentRecoveryDetails(
          this.recompositionChapterId,
          this.student.id,
        )
        this.recoveryResults.data = data
        this.recoveryResults.error = false
      } catch (error) {
        this.recoveryResults.error = true
      } finally {
        this.recoveryResults.loading = false
      }
    },
    pressEsc(event) {
      const hasActiveQuestion = this.$route.query?.activeQuestion
      if (this.isEscKeyPressed(event) && !hasActiveQuestion) this.close()
    },
    selectQuestion(event) {
      const { question } = event
      const { activeQuestion } = this.$route.query
      if (question.id !== activeQuestion) {
        this.activeQuestionInnerIndex = event.index
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }
      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex - 1],
            index: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previousQuestion()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.filteredQuestions.length - 1) {
          this.selectQuestion({
            data: this.filteredQuestions[this.activeQuestionIndex + 1],
            index: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.nextQuestion()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-drawer {
  &__body {
    padding: $size-m;
    padding-bottom: $end-space;
  }

  &__charts-container {
    margin-bottom: $size-l;
  }

  &__charts-header {
    padding: $size-m;
    &-title {
      color: $color-ink-light;
      font-size: $font-size-m;
    }

    &-subtitle {
      color: $color-ink;
      font-size: $font-size-heading-5;
      margin-top: $size-xxs;
    }
  }

  ::v-deep .recomposition-progression-path__card-title {
    text-align: center;
  }

  ::v-deep .recomposition-progression-path__card-description {
    align-self: center;
  }

  &__charts-content {
    display: flex;
    gap: $size-m;
  }

  ::v-deep .recomposition-progression-path__card-title {
    text-align: center;
  }

  ::v-deep .recomposition-progression-path__card-description {
    align-self: center;
  }

  &__details-header {
    padding: 0 0 $size-l;
    border-bottom: 0.5px solid $color-ink-lightest;
    margin-bottom: $size-l;
  }

  &__details-title {
    color: $color-ink;
    font-family: Metropolis;
    font-size: $font-size-heading-3;
    font-weight: $font-weight-medium;
    line-height: $line-height-heading;
    margin-bottom: $size-xs;
  }

  &__details-subtitle {
    color: $color-ink-light;
    font-family: Metropolis;
    font-size: $font-size-heading-6;
    font-weight: $font-weight-medium;
    line-height: $line-height-heading;
  }

  &__recovery-selector {
    min-width: 330px;
    margin-bottom: $size-s;
  }

  &__learning-title {
    font-family: 'Metropolis';
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    line-height: $line-height-input;
    color: #707780;
    margin-bottom: $size-s;
  }

  &__detail-tabs {
    &.-minimal-margin {
      margin-bottom: $size-s;
    }

    ::v-deep .tabs__item::after {
      width: 0;
    }

    ::v-deep .sas-button {
      border-radius: 10px;

      &.--active {
        background: rgba($color-primary-lightest, 0.5);

        .sas-button__text {
          color:  $color-primary-dark;
        }
      }

      &.--disabled {
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }

        .sas-button__text {
          color: $color-ink-lightest;
        }
      }
    }
  }
}

</style>
