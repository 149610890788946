/* eslint-disable no-param-reassign */
import Vue from 'vue'

Vue.use({
  install($Vue) {
    $Vue.appDefaultTitle = document.title

    $Vue.prototype.$setTitle = (newTitle) => {
      $Vue.appDefaultTitle = newTitle
      document.title = newTitle
    }

    $Vue.prototype.$resetTitle = () => {
      document.title = $Vue.appDefaultTitle
    }

    $Vue.prototype.$prependTitle = (titlePrefix) => {
      document.title = `${titlePrefix}${$Vue.appDefaultTitle}`
    }

    $Vue.prototype.$appendTitle = (titleSufix) => {
      document.title = `${$Vue.appDefaultTitle}${titleSufix}`
    }
  },
})
