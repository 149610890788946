import apiBff from '@/service/apiBff'
import * as types from './types'

export default {
  getShareUrl: ({ commit, state }, { questionnaireCode, gradeId }) => {
    if (!state.shareUrlCache.data[questionnaireCode]) {
      commit(types.SET_SHARE_URL, {
        loading: true,
      })

      return apiBff.get(`questionnaires/${questionnaireCode}/share`, { params: { gradeId } })
        .then(({ data }) => {
          commit(types.SET_SHARE_URL, {
            data: {
              ...(state.shareUrlCache.data),
              [questionnaireCode]: data,
            },
            loading: false,
          })
        })
        .catch((error) => {
          commit(types.SET_SHARE_URL, {
            error: error.response || true,
            loading: false,
          })

          return Promise.reject()
        })
    }

    return Promise.resolve()
  },
}
