var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasError)?[_c('div',{class:[
        'form-error__content',
        'form-error__warning',
        _vm.className,
      ]},[_vm._t("default")],2),_c('div',{staticClass:"form-error__text"},[_c('Icon',{staticClass:"form-error__icon",attrs:{"type":"alert-triangle","size":"16"}}),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")],1)]:_c('div',{class:[
      'form-error__content',
      _vm.className,
    ]},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }