import { urls } from 'shared/utils/urls'

export default (url) => {
  const encodedUrl = encodeURIComponent(url || window.location.href)
  const appBasename = window.eureka_report_basename || ''
  const currentUrl = `${appBasename}/${encodedUrl}`
  const portalUri = urls.CROSS_PORTAL_URL

  window.location.assign(
    `${portalUri}/entrar?redirectTo=${currentUrl}`
  )
}
