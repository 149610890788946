<template>
  <div class="students-view-table">
    <s-table
      ref="studentsTable"
      :content="students"
      :selected-row="activeStudent"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="isPaginate"
      @click-row="clickRow"
    >
      <template
        slot="name"
        slot-scope="{ row }"
      >
        <div class="students-view-table__profile-name">
          <ProfilePicture
            class="students-view-table__profile"
            :user="{ profilePicture: row.profilePicture, name: row.name }"
          />
          <h6>
            {{ row.name }}
          </h6>
        </div>
      </template>
      <template
        slot="amountReinforcement"
        slot-scope="{ row }"
      >
        <p class="students-view-table__amount-reinforcement">
          {{ $tc(
            'report.students.amountReinforcement',
            row.finalizedProposedAssignmentsCount,
            { amount: row.finalizedProposedAssignmentsCount },
          ) }}
        </p>
      </template>
      <template slot="details">
        <s-button variation="secondary">
          {{ $t('commons.historic') }}
        </s-button>
      </template>
      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.pagination',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
  </div>
</template>

<script>
import STable from 'App/components/STable'
import ProfilePicture from 'App/components/ProfilePicture'

export default {
  name: 'StudentsPerformanceViewTable',
  components: {
    STable,
    ProfilePicture,
  },
  props: {
    isPaginate: {
      type: Boolean,
      default: true,
    },
    students: {
      type: Array,
      required: true,
    },
    activeStudent: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          value: 'name',
          text: this.$t('report.students.student'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'amountReinforcement',
          text: this.$t('report.students.amountReinforcementLabel'),
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ],
      defaultSort: {
        field: 'name',
        direction: 'asc',
      },
    }
  },
  methods: {
    clickRow({ data, dataIndex }) {
      this.$emit('selectStudent', { data, index: dataIndex })
    },
    previousStudent() {
      this.$refs.studentsTable.previous()
    },
    nextStudent() {
      this.$refs.studentsTable.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.students-view-table {
  &__profile-name {
    @include flex-center-start;
  }

  &__profile {
    margin-right: 12px;
  }

  ::v-deep .sas-table__body tr {
      cursor: pointer;
  }
}
</style>
