<template>
  <div class="filter-control">
    <template v-if="mq_m">
      <s-button
        class="filter-control__button"
        variation="tertiary"
        icon-left="filter"
        @click="isDrawerVisible = true"
      >
        Filtrar
      </s-button>
      <FilterDrawer
        v-if="isDrawerVisible"
        :level-options="levelOptions"
        :level-filter-object="levelFilterObject"
        :sort-options="sortOptions"
        :sort-option-object="sortOptionObject"
        @onSortSelect="$emit('onSortSelect', $event.value)"
        @onLevelSelect="$emit('onLevelSelect', $event.value)"
        @close="isDrawerVisible = false"
      />
    </template>
    <template v-else>
      <span class="filter-control__label">
        Filtrar por
      </span>
      <s-select
        size="medium"
        variation="tertiary"
        track-by="label"
        :options="levelOptions"
        :value="levelFilterObject"
        @select="$emit('onLevelSelect', $event.value)"
      />
    </template>
  </div>
</template>

<script>
import mediaQueries from 'App/mixins/mediaQueries'
import FilterDrawer from './FilterDrawer'

export default {
  name: 'FilterControl',
  components: { FilterDrawer },
  mixins: [ mediaQueries ],
  props: {
    levelFilter: {
      type: String,
      required: true,
    },
    sortOption: {
      type: Object,
      required: true,
      validator: (value) => value.field && value.direction,
    },
    questionnaire: {
      type: Object,
      default: () => ({}),
    },
    isFilteredByOrder: Boolean,
  },
  data() {
    return {
      isDrawerVisible: false,
    }
  },
  computed: {
    isChallengeActivity() {
      if (!this.questionnaire.data) return false

      return this.questionnaire.data.isChallengeActivity
    },
    isExtraActivity() {
      if (!this.questionnaire.data) return false

      const { isExtraActivity, isEnemByDifficulty } = this.questionnaire.data

      return isExtraActivity || isEnemByDifficulty
    },
    levelOptions() {
      return [
        { value: 'all', label: 'Todas as dificuldades' },
        { value: 'easy', label: 'Fácil' },
        { value: 'medium', label: 'Médio' },
        { value: 'hard', label: 'Difícil' },
      ].map(({ value, label }) => ({ value, label }))
    },
    levelFilterObject() {
      return this.levelOptions.find((level) => level.value === this.levelFilter)
    },
    sortOptions() {
      const sortOptions = [
        {
          field: 'hitRate',
          direction: 'desc',
        },
        {
          field: 'hitRate',
          direction: 'asc',
        },
      ]

      if (this.isFilteredByOrder) {
        sortOptions.unshift({ field: 'number', direction: 'asc' })
      }

      return sortOptions.map((sortOption) => ({
        value: sortOption,
        label: this.getSortOptionLabel(sortOption),
        id: `${sortOption.field}${sortOption.direction}`,
      }))
    },
    sortOptionObject() {
      return this.sortOptions.find(
        (option) => (
          option.value.field === this.sortOption.field
          && option.value.direction === this.sortOption.direction
        )
      )
    },
  },
  methods: {
    getSortOptionLabel({ field, direction }) {
      const optionsLabel = {
        number: {
          asc: this.isChallengeActivity || this.isExtraActivity
            ? 'Ordem original da atividade'
            : 'Ordem da questão no livro',
        },
        hitRate: {
          asc: 'Menor índice de acerto',
          desc: 'Maior índice de acerto',
        },
      }

      return optionsLabel[field][direction]
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-control {
  &__label {
    white-space: nowrap;
    margin-right: $size-s;
  }

  @include mq-m--mf {
    @include flex-center;
  }

  ::v-deep .sas-dropdown-menu {
    left: auto;
    right: 0;
    min-width: 120px;
  }
}
</style>
