export default function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }
  if (to.name !== from.name) {
    return { x: 0, y: 0 }
  }

  return null
}
