import { cookieDomain } from 'App/utils/domain'

export default {
  setItem: (value) => {
    document.cookie = `token=${value};\
    path=/;\
    domain=${cookieDomain};\
    max-age=86400;\
    ${process.env.VUE_APP_MODE === ('production' || 'staging') ? 'secure' : ''}`

    return ''
  },
  getItem: (cookieName) => {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookies = decodedCookie.split(';')
    for (let i = 0; i < cookies.length; i += 1) {
      let cookie = cookies[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }

    return ''
  },
  removeItem: (cookieName) => {
    const date = new Date()
    date.setTime(date.getTime() - (1000 * 60 * 60 * 24))
    const expires = `expires=${date.toGMTString()}`
    window.document.cookie = `${cookieName}=; ${expires}`
  },
}
