import { ready } from 'App/store/utils'

export default {
  classrooms: (state) => ({
    ...state.classrooms,
    ready: ready(state.classrooms),
  }),
  activeClassroom: (state) => ({
    ...state.activeClassroom,
    ready: ready(state.activeClassroom),
  }),
}
