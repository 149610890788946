<template>
  <div class="home-header">
    <Wrapper>
      <div class="home-header__content">
        <div class="home-header__content__left">
          <h1 class="home-header__content__left__title">
            {{ $t('assignments.title') }}
          </h1>
          <p class="home-header__content__left__subtitle">
            {{ $t('assignments.subtitle') }}
          </p>
        </div>
        <div class="home-header__content__right">
          <slot />
        </div>
      </div>
      <div class="home-header__activities">
        <Tabs
          :tabs="tabRoutes"
          theme="dark"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { pickBy, identity } from 'lodash'
import { Wrapper } from '@sas-te/alfabeto-vue'

import Tabs from 'App/components/Tabs'

const MATH_SUBJECT_ID = 5

export default {
  name: 'HomeHeader',
  components: { Tabs, Wrapper },
  props: {
    subjectIds: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    ...mapState({
      isShowAncestralArenaTab: ({ featureToggles }) => featureToggles
        .toggles.isShowAncestralArenaTab,
      isShowRecompositionAvailableReport: ({ featureToggles }) => featureToggles
        .toggles.isShowRecompositionAvailableReport,
    }),
    classroomId() {
      return parseInt(this.$route.query.classroomId, 10) || null
    },
    lectureId() {
      return parseInt(this.$route.query.lectureId, 10) || null
    },
    tabRoutes() {
      const queryParams = {
        ...this.$route.query,
        lectureId: this.lectureId || null,
        classroomId: this.classroomId || null,
      }

      const filteredQueryParams = pickBy(queryParams, identity)

      const { isTeacher, isMiddleSchoolTeacher } = this.user

      const tabs = [
        {
          name: 'Atividades do Livro',
          route: {
            name: 'teacher-sas-activities',
            query: filteredQueryParams,
          },
          isShow: true,
        },
        {
          name: 'Arena Ancestral',
          route: {
            name: 'teacher-arena-ancestral',
            query: filteredQueryParams,
          },
          isShow: this.isShowAncestralArenaTab && isMiddleSchoolTeacher,
        },
        {
          name: 'Atividades da escola',
          route: {
            name: 'teacher-school-activities',
            query: filteredQueryParams,
          },
          isShow: !isTeacher,
        },
        {
          name: 'Lógos',
          route: {
            name: 'teacher-logos-report',
            query: filteredQueryParams,
          },
          isShow: this.canAccessLogosTab,
        },
      ]

      return tabs.filter(({ isShow }) => isShow)
    },
    hasMathSubjectId() {
      return this.subjectIds.includes(MATH_SUBJECT_ID)
    },
    canAccessLogosTab() {
      return this.hasMathSubjectId
      && this.isShowRecompositionAvailableReport
    },
  },
}
</script>

<style lang="sass" scoped>
.home-header
  background: linear-gradient(108deg, #2B4A99 0, #2B5999 100%)
  padding-top: $size-l
  margin-bottom: $size-m

  &__content
    padding-bottom: $size-l

    +mq-l__mf
      +flex-space-between

    &__left
      margin-bottom: $size-m

      +mq-l__mf
        padding: 0

      &__title
        color: $color-white

      &__subtitle
        color: transparentize($color-white, 0.3)

        +mq-m--mf
          max-width: 450px

        +mq-xl--mf
          max-width: 560px

</style>
