import { ready } from 'App/store/utils'

export default {
  lectures: (state) => ({
    ...state.lectures,
    ready: ready(state.lectures),
  }),
  lecture: (state) => ({
    ...state.lecture,
    ready: ready(state.lecture),
  }),
}
