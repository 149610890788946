import Vue from 'vue'
import Router from 'vue-router'
import cookies from '@/service/cookies'
import loginAndReturn from 'App/utils/loginAndReturn'
import store from 'App/store'
import scrollBehavior from 'shared/router/scrollBehavior'
import routes from './routes'

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  const token = cookies.getItem('token')

  if (to.name === 'error' && from.name !== 'error') {
    next()
  } else if (!token || token.length === 0) {
    loginAndReturn(`${window.location.origin}${to.fullPath}`)
  } else if (to.path === '/') {
    let unwatch = null

    unwatch = store.watch(
      (state, getters) => getters.user,
      () => {
        if (unwatch) {
          unwatch()
        }
        next({
          name: 'teacher-sas-activities',
          query: { ...to.query },
          replace: true,
        })
      },
      { immediate: true },
    )
  } else {
    next()
  }
})

export default router
