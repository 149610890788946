<template>
  <div
    :class="[
      'boxCardsContainer',
      {'--scroll': isScrollEnabled } ]"
  >
    <div
      v-if="isScrollEnabled"
      ref="scrollableDiv"
      class="boxCardsContainerScroll"
    >
      <slot />
    </div>
    <Carousel
      v-else
      :max-visible="maxVisible"
    >
      <slot />
    </Carousel>
  </div>
</template>

<script>
import Carousel from 'App/components/Carousel/Carousel'
import mediaQueries from 'App/mixins/mediaQueries'

export default {
  name: 'BoxCardsContainer',
  components: {
    Carousel,
  },
  mixins: [ mediaQueries ],
  props: {
    maxVisible: {
      type: Number,
      default: 6,
    },
    isCarouselDisabled: Boolean,
  },
  computed: {
    isScrollEnabled() {
      return this.mq_l || this.isCarouselDisabled
    },
  },
}
</script>

<style lang="scss" scoped>
.boxCardsContainer {
  margin: 0 auto;
  width: 1032px;
  max-width: 100%;
  overflow: visible;

  @include mq-xl {
    width: auto;
  }
}

.--scroll {
  padding-bottom: 9px;
  overflow: auto;
}

.boxCardsContainerScroll {
  width: max-content;
  display: flex;

  & >:not(:last-child) {
    margin-right: $size-s;
  }

}
</style>
